import React, { useState } from 'react';
import {faArrowDown, faArrowRight, faSkull} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./ExpandableSection.css";

const ExpandableSection = (props) => {
    const { title, isOpen, children } = props;
    const [viewState, setViewState] = useState(isOpen || 0);

    const icon = viewState === 0 ? faArrowRight : faArrowDown;
    
    const toggleView = (e) => { 
        const newState = viewState === 0 ? 1 : 0;
        setViewState(newState);
    }
    
    const cn = viewState === 0 ? " hidden" : "";
    const body = viewState === 0 ? null : children;
    
    return (<div className={("expandable-section" + cn).trim()}>
        <h3 onClick={toggleView}>
            <span>{ title }</span>
            <span><a onClick={toggleView}><FontAwesomeIcon icon={icon} /></a></span>
        </h3>
        <div className={"expandable-section"}>{ body }</div>
    </div>);
};

export default ExpandableSection;

