import ProductModel from "./ProductModel";
import MerchantLocationModel from "../../merchants/models/MerchantLocationModel";

class ProductCatalogModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.merchantId = json.merchant_id;
        this.userId = json.user_id;
        this.name = json.name;
        this.itemCount = json.item_count;
        this.isActive = json.is_active;
        this.products = ProductModel.fromJsonArray(json.products) || [];
        this.locations = MerchantLocationModel.fromJsonArray(json.locations);
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            id: this.id,
            merchant_id: this.merchantId,
            name: this.name,
            is_active: this.isActive,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ProductCatalogModel(json));
    }
}

export default ProductCatalogModel;
