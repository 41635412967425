import React, {useState, useEffect, useRef} from 'react';
import LocationMasterPage from "./LocationMasterPage";
import { useParams} from "react-router-dom";
import ProductService from "../../../components/products/services/ProductService";
import MerchantService from "../../../components/merchants/services/MerchantService";
import BackButton from "@paylani/paylani-react-packages/dist/common/ui/BackButton";
import FileUploader from "@paylani/paylani-react-packages/dist/common/ui/file-uploader/FileUploader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import ProductForm from "../../../components/products/ui/ProductForm";
import ErrorModel from "@paylani/paylani-react-packages/dist/common/models/ErrorModel";

const LocationProductEditScreen = (props) => {
    const { merchantId, productId, locationId } = useParams();
    
    let _;
    const [product, setProduct] = useState(null);
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId || ""] || null);
    const [location, setLocation] = useState(null);
    const [checkboxes, setCheckboxes] = useState({ is_tax_override: false, is_tracking_inventory: false});
    const [errors, setErrors] = useState({});
    
    const inventoryCountRef = useRef();
    
    const onMerchant = (m) => {
        if (!!m) {
            setMerchant(m);
        }
    };

    const onLocation = (loc) => {
        console.warn("Location Good: " + loc?.id);
        
        if (!!loc) {
            setLocation(loc);
        }
    };
    
    const onImageUploadComplete = (file) => {
        
    };

    const validate = (data) => {
        let er = {};
        
        if (!data.name || data.name.trim().length === 0)
            er.name = "Name is required";
        
        if (!data.price || data.price <= 0)
            er.price = "Price is required";

        setErrors(er);
        
        return (Object.keys(er).length === 0);
    };
    
    const updateProductAsync = async (data) => {
        
        await ProductService.instance.updateProductAsync(data, productId).then((product) => {
            if (!!product?.id) setProduct(product);
        }).catch((ex) => {
            setErrors(ErrorModel.createFormError(ex));
        });
    };
    
    const getProductAsync = async (force) => {
        if (!force && !!product?.id) {
            console.warn("Already have product: " + product.name);
            return product;
        }
        
        if (productId.length < 30) {
            console.error("No ProductId: " + productId);
            return null;
        }
        
        console.warn("Getting product: " + productId);
        return await ProductService.instance.getProductAsync(productId).then((p) => {
            if (!!p?.id) setProduct(p);
            else console.error("No product: " + p);
        });
    };

    useEffect(() => {
        _ = getProductAsync();
    }, [location]);

    useEffect(() => {
        //
    }, [product]);

    useEffect(() => {
        _ = getProductAsync();
    }, []);
    
    const defaultTax = checkboxes.is_tax_override ? "0" : null;
    const backPath = "/";
    const subtitle = productId.length > 30 ? (<><BackButton /> {"Product Details: " + product?.name}</>) : (<><BackButton /> Create New Product</>);
    
    const fileUploader = (<FileUploader url={"/api/product/" + productId + "/image"} imageUrl={product?.imageUrl} onComplete={onImageUploadComplete} />);
    const productImageElement = product?.imageUrl ? (<img src={product.imageUrl} alt={product.name} />) : (<div className={"blank-image-icon"}><FontAwesomeIcon icon={faImage} /></div>);
    
    const productForm = !!product?.id ? (<ProductForm product={product} />) : null;
    
    return (
        <LocationMasterPage onMerchant={onMerchant} onLocation={onLocation} title={"Edit Product"} selection={"catalogs"}>
            <h3>{ subtitle }</h3>
            
            <div className={"product-list form-large"}>
                { productForm }
            </div>
        </LocationMasterPage>
    );
};

export default LocationProductEditScreen;

