import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantModel from "../../components/merchants/models/MerchantModel";
import MerchantMasterPage from "./MerchantMasterPage";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import ProductService from "../../components/products/services/ProductService";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import DialogBoxController from "../../components/common/ui/dialog-box/DialogBoxController";
import DialogBox from "../../components/common/ui/dialog-box/DialogBox";
import CatalogLocationList from "../../components/products/ui/CatalogLocationList";
import LoadingBox from "../../components/common/ui/LoadingBox";
import EmptyBox from "../../components/common/ui/EmptyBox";
import RefreshButton from "../../components/common/ui/RefreshButton";

const MerchantCatalogsScreen = (props) => {
    const {merchantId} = useParams();
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    const [catalogs, setCatalogs] = useState(null);
    const [dialogController, setDialogController] = useState(new DialogBoxController());
    const [errors, setErrors] = useState({});
    const errorDivId = "catalog-name-error";
    let _;
    
    const onMerchant = (m) => { 
        if (!!m) setMerchant(m);
    }
    
    const getCatalogsAsync = async (force) => {
        return await ProductService.instance.getProductCatalogsAsync(merchantId).then((cs) => {
            if (cs) setCatalogs(cs);
            return cs;
        });
    }

    const refreshCatalogsAsync = async (e) => {
        e.preventDefault();
        setCatalogs(null);
        
        const cs = await getCatalogsAsync(true);
    };
    
    const clearErrors = () => {
        if (Object.keys(errors).length < 1) return;
        
        const errorDiv = document.getElementById(errorDivId);
        if (!!errorDiv) errorDiv.innerHTML = "";
        
        setErrors({});
    };
    
    const openCatalogDialog = (c, e) => {
        e.preventDefault();
        
        console.log(c?.id);
        clearErrors();
        
        const body = createCatalogForm(c);
        
        dialogController.payload = c?.toJson() || { merchant_id: merchantId, name: "", is_active: true, id: null };
        const deleteButton = !!dialogController.payload?.id ? (<a className={"activity-link delete"} onClick={(e) => deleteCatalogAsync()}>Delete</a>) : null;

        setTimeout(() => {
            dialogController.objects.titleActions = deleteButton;
            dialogController.open(c?.name || "New Catalog", body);
            dialogController.onOkay = updateCatalogAsync;
        }, 100);
    };
    
    const updateCatalogAsync = async (c) => {
        const data = dialogController.payload;
        
        if (!data.name || data.name.length < 1) {
            const errorDiv = document.getElementById(errorDivId);
            
            if (!!errorDiv) errorDiv.innerHTML = "Enter a name for the catalog.";
            else setErrors({ name: "Enter a name for the catalog."});
            
            return false;
        }
        
        await ProductService.instance.updateProductCatalogAsync(data, data.id).then((c) => {
            console.log("Good: " + c?.name);
        });

        await getCatalogsAsync(true);
        
        return 750; // 0.75 second delay before close
    };
    
    const deleteCatalogAsync = async (e) => {
        const id = dialogController.payload?.id;
        if (!id) return;
        
        await ProductService.instance.deleteProductCatalogAsync(id).then((c) => {
            //
        });

        await getCatalogsAsync(true);

        dialogController.close();
        return 750; // 0.75 second delay before close
    };
    
    const onCatalogNameUpdate = (e, c) => { 
        clearErrors();
        dialogController.payload.name = e.target?.value || "";
    }
    
    const createCatalogForm = (c) => {
        const desc = !!c?.id ? "Catalogs simply have names. Edit the name here." : "Once created, this catalog will be available to all locations that use this merchant.";
        
        return (<div className={"form large catalog-form"}>
            <div className={"form-note"}>{ desc }</div>
            <div className={"form-group"}>
                <label>Name</label>
                <input type={"text"} defaultValue={c?.name || ""} onChange={(e) => onCatalogNameUpdate(e, c)} />
                <div className={"form-error"} id={errorDivId}>{errors?.name}</div>
                <div className={"form-note-loader"}>
                    <CatalogLocationList catalogId={c?.id} />
                </div>
            </div>
        </div>);
    };
    
    useEffect(() => {
        _ = getCatalogsAsync();
    }, []);
    
    const subtitle = "Catalogs";
    
    // Move this to dialog controller
    const addNew = [
        (<a key={"add-new-0"} className={"add"} onClick={(e) => openCatalogDialog(null, e)}>+ Add New</a>),
        (<RefreshButton onClick={refreshCatalogsAsync} key={"refresh-key-001"}>Refresh</RefreshButton>)
    ];
    
    const catalogElements = catalogs?.length > 0 ? catalogs.map((c, i) => {
        const s = c.itemCount === 1 ? "" : "s";
        
        return (<div key={"catalog-" + i} className={"catalog-item"}>
            <span><a title={c.id} onClick={(e) => openCatalogDialog(c, e)}>{ c.name || "(Untitled)" }</a></span>
            <span><Link to={"/merchants/" + merchantId + "/catalogs/" + c.id + "/products"}><NumberDisplay value={c.itemCount} decimalPlaces={0} /> Product{s}</Link></span>
            <span><DateTime value={c.created} /></span>
        </div>);
    }) : (catalogs === null ? (<LoadingBox>Getting Catalogs...</LoadingBox>) : (<EmptyBox>No Catalogs for {merchant?.name || "this merchant"}. <a onClick={(e) => openCatalogDialog(null, e)}>Create one</a></EmptyBox>) );
    
    return (
        <MerchantMasterPage title={subtitle} selection={"catalogs"} actions={addNew}>
            <p>All catalogs for { merchant?.name }. Any location can assign any of these catalogs for their own use.</p>
            
            <div className={"catalogs"}>
                { catalogElements }
            </div>
            
            <DialogBox controller={dialogController} onOkay={updateCatalogAsync} caption={"Save"}>
                <div>Edit Product</div>
            </DialogBox>
        </MerchantMasterPage>
    );
};

export default MerchantCatalogsScreen;
