import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantModel from "../../components/merchants/models/MerchantModel";
import MasterScreen from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import MerchantSubMenu from "../../components/merchants/ui/MerchantSubMenu";
import MerchantEffect from "../../components/merchants/services/MerchantEffect";

/**
 * Not used yet - Settings are currently on the Locations level* 
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MerchantSettingsScreen = (props) => {
    const {merchantId} = useParams();

    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));

    useEffect(() => {
        MerchantEffect.create(setMerchant, merchantId);

    }, []);

    return (
        <MasterScreen selection="merchants">
            <div className="pad" id="partner-merchant-details">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    <span className={"subtitle"}>Merchant:</span>
                    {merchant.name}
                </h1>

                <div className="details-body">
                    <MerchantSubMenu partner={merchant} selection="settings" />

                    <div className="pad">
                        <h2>
                            {merchant.name}:
                            <span className="subtitle">Settings</span>
                        </h2>

                        <p>
                            This merchant don't have settings yet. Check the <Link to={"/merchants/" + merchantId + "/locations"}>Locations</Link> if you're interested in the settings.
                        </p>

                    </div>
                </div>
            </div>
        </MasterScreen>
    );
};

export default MerchantSettingsScreen;

