import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import ReportItemModel from "../models/ReportItemModel";
import SaleModel from "../../sales/models/SaleModel";
import TransactionReportResponseModel from "../models/TransactionReportResponseModel";
import ProductReportResponseModel from "../models/ProductReportResponseModel";

class SaleReportingService { 
    static instance = new SaleReportingService();
    
    constructor() {
        this.salesMonthly = [];
        this.salesDaily = [];
        this.transactionReport = null;
        this.productReport = null;
        this.isGetting = {};
    }
    
    async getSalesMonthlyAsync(startDate, endDate) {
        const me = this;
        const path = '/api/sales-report/monthly';
        
        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let items = ReportItemModel.fromJsonArray(rsp?.data);
            me.salesMonthly = items;
            
            return items;
        });
    }
    
    async getSalesDailyAsync(startDate, endDate) {
        const me = this;
        const path = '/api/sales-report';
        
        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let items = ReportItemModel.fromJsonArray(rsp?.data);
            me.salesDaily = items;
            
            return items;
        });
    }

    async getTransactionReportAsync(startDate, endDate, merchantId = "", saleStatuses = null) {
        const keyGet = "all-transactions";
        if (!!this.isGetting[keyGet]) {
            return null; //this.sales;
        }

        const stati = saleStatuses || ""; // "1,4,5,-1";
        const path = !!merchantId && merchantId.length > 30 ?
            "/api/merchant-report/" + merchantId + "/transaction-report?location-ids=all&sale-status=" + stati :
            "/api/report/transaction-report?location-ids=all&sale-status=" + stati;
        const me = this;

        this.isGetting[keyGet] = true;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((response) => {
            me.transactionReport = new TransactionReportResponseModel(response.data);
            delete me.isGetting[keyGet];
            return me.transactionReport;
        }).catch((ex) => {
            delete me.isGetting[keyGet];
            throw ex;
        });
    };

    async getProductReportAsync(startDate, endDate, merchantId = "", saleStatuses = null) {
        const keyGet = "all-product-report";
        if (!!this.isGetting[keyGet]) {
            return null; //this.sales;
        }

        const stati = saleStatuses || ""; //"1,4,5";
        const path = !!merchantId && merchantId.length > 30 ?
            "/api/merchant-report/" + merchantId + "/product-report?location-ids=all&sale-status=" + stati :
            "/api/report/product-report?location-ids=all&sale-status=" + stati;
        const me = this;

        this.isGetting[keyGet] = true;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((response) => {
            me.productReport = new ProductReportResponseModel(response.data);
            
            delete me.isGetting[keyGet];
            
            return me.productReport;
        }).catch((ex) => {
            delete me.isGetting[keyGet];
            throw ex;
        });
    };

}

export default SaleReportingService;
