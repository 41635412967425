class ProductReportItemModel { 
    constructor(json) {
        if (!json) json = {};
        this.id = json.id || null;
        this.name = json.name || "One-Off Item";
        this.qty = json.qty || 0;
        this.total = json.total || 0;
        this.inventoryCount = json.inventory_count || 0;
        this.firstSaleDate = json.first_sale_date ? new Date(json.first_sale_date) : null;
        this.lastSaleDate = json.last_sale_date ? new Date(json.last_sale_date) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ProductReportItemModel(json));
    }
}

export default ProductReportItemModel;
