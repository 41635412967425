import PermissionModel from './PermissionModel';

class RoleModel {
    // Master = -1,
    // Custom = 0,
    // AccountOwner = 1,
    // Admin = 2,
    // User = 3,
    // Analyst = 4

    static create = (json) => new RoleModel(json);
    
    static roles = [
        new RoleModel({ name: "Master Portal User", description: "Master", role_key: -1 }),
        //new RoleModel({ name: "Custom", description: "Custom", role_key: 0 }),
        new RoleModel({ name: "Account Owner", description: "Account Owner", role_key: 1 }),
        new RoleModel({ name: "Admin", description: "Admin", role_key: 2 }),
        new RoleModel({ name: "User", description: "User", role_key: 3 }),
        new RoleModel({ name: "Analyst", description: "Analyst", role_key: 4 }),
    ];
    
    constructor(json) {
        if (!json) json = {};
        
        this.name = json.name || "";
        this.description = json.description || "";
        this.imageUrl = json.image_url || "";
        this.roleKey = json.role_key || 0;
        this.id = json.id || "";
        this.permissions = PermissionModel.fromJsonArray(json.permissions || []);
        this.permissionKeys = this.permissions.map(p => p.key);
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    hasPermission(permissionKey) {
        if (this.roleKey === -1) return true;
        return this.permissionKeys?.includes(permissionKey) || false;
    }
}

export default RoleModel;
