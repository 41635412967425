import AddressModel from "@paylani/paylani-react-packages/dist/geo/models/AddressModel";
import CreditCardModel from "../../payments/models/CreditCardModel";

class CustomerModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.customerNumber = json.customer_number;
        this.firstName = json.first_name;
        this.lastName = json.last_name;
        this.name = this.getPrettyName();
        this.email = json.email;
        this.phone = json.phone;
        this.imageUrl = json.image_url;
        this.addressId = json.address_id;
        this.address = new AddressModel(json.address);
        this.customerVaultId = json.customer_vault_id || null;
        this.creditCard = new CreditCardModel(json.credit_card);
    }
    
    getPrettyName(defaultName = "No Name") {
        if (!this.firstName && !this.lastName) return defaultName;
        
        let fname = this.firstName;
        let lname = this.lastName;
        
        if (fname) fname = fname.substring(0, 1).toUpperCase() + fname.substring(1).toLowerCase();
        if (lname) lname = lname.substring(0, 1).toUpperCase() + lname.substring(1).toLowerCase();
        
        return (fname + " " + lname).trim();
    }

    toJson = () => {
        return {
            "id": this.id,
            "first_name": this.firstName || '',
            "last_name": this.lastName || '',
            "email": this.email || '',
            "phone": this.phone || '',
            "image_url": this.imageUrl || '',
            "user_name": this.username || '',
            "user_type": this.userType || 0,
            "address": this.address.toJson()
        };
    };    
    
    static searchFor(customer, term) {
        if (!term || term.length === 0) return true;
        if (!customer) return false;
        
        const name = (customer.firstName + " " + customer.lastName).trim().toLowerCase();
        if (name.indexOf(term) >= 0) return true;

        if (customer.email && customer.email.toLowerCase().indexOf(term) >= 0) return true;
        
        return (customer.phone && customer.phone.toLowerCase().indexOf(term) >= 0);
    }
}



export default CustomerModel;
