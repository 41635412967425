
class StatementHistoryItemModel {

    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || '';
        this.partnerId = json.card_sync_merchant_id || '';
        this.activeMerchants = json.active_merchants;
        this.newEnrollments = json.new_enrollments;
        this.updateCount = json.update_count;

        this.subTotal = json.subTotal;
        this.message = json.message;
        this.total = json.total;

        this.status = json.status;
        this.serviceDate = json.service_date;
        this.created = json.created;
        this.modified = json.modified;
    }

    static fromJsonArray(jsonArray) { 
        console.warn(JSON.stringify(jsonArray));
        return jsonArray.map(json => new StatementHistoryItemModel(json)).filter((item) => item.id.length > 10);
    }
}

export default StatementHistoryItemModel;
