class MerchantTipModel { 
    static json = {
        "merchant_id": "4e4eb93f-b29e-4fdd-81d1-36826fad6bad",
        "merchant_location_id": "ce482b70-9e36-468e-907e-a04c8c61a98d",
        "percentage": 10.0,
        "id": "28e34e76-ddf1-4995-aef5-1567538589e1",
        "created": "2018-10-17T20:34:02.41",
        "modified": null
    };
    
    constructor(json) {
        if (!json) json = {};

        this.id = json.id;
        this.merchantId = json.merchant_id;
        this.merchantLocationId = json.merchant_location_id;
        this.percentage = json.percentage || 0.0;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new MerchantTipModel(json));
    }
}

export default  MerchantTipModel;
