import React, {useEffect, useRef, useState} from 'react';
import UserService from "../../components/people/services/UserService";
import {Link, useParams} from "react-router-dom";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPersonWalking,
    faArrowRightToBracket,
    faUserPlus,
    faFeatherPointed, faAddressCard
} from "@fortawesome/free-solid-svg-icons";
import '../../components/people/ui/users.css';
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import PersonForm from "../../components/people/ui/PersonForm";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantSubMenu from "../../components/merchants/ui/MerchantSubMenu";
import MerchantEffect from "../../components/merchants/services/MerchantEffect";
import UserMasterPage from "./UserMasterPage";

const UserDetailsScreen = (props) => {
    const { userId, merchantId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [userJson, setUserJson] = useState(user?.toJson());
    
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId || ''] || null);
    const [userMerchant, setUserMerchant] = useState(MerchantService.instance.merchantMap[user?.merchantId || ""] || null);
    
    let _;
    
    const getUserAsync = async (force, e) => {
        await UserService.instance.getUserAsync(userId).then((user) => {
            console.warn("User Got from server");
            setUser(user);
        });
    };

    const updateUserAsync = async (userJson) => {
        userJson.id = userId;
        const u = await UserService.instance.updateUserAsync(userJson);
        
        if (!!u?.id) setUser(u);
        
        return u;
    };

    const getUserMerchantAsync = async (force, e) => {
        if (!user?.merchantId) return userMerchant;
        if (!!userMerchant && !force) return userMerchant;

        await MerchantService.instance.getMerchantAsync(user?.merchantId || "").then((m) => {
            setUserMerchant(m);
            return m;
        });
    };
    
    const onUser = (user) => {
        setUser(user);
    };
    
    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };
    
    useEffect(() => {
        if (merchantId) MerchantEffect.create(setMerchant, merchantId);
        _ = getUserAsync();
        
    }, []);

    useEffect(() => {
        if (!!user) {
            _ = getUserMerchantAsync();
            setUserJson({...{}, ...user.toJson()});
        }
        
    }, [user]);
    
    let backPath = "/people";
    let masterMenuSelection = "users";
    
    let h1 = (<h1><FontAwesomeIcon icon={faPersonWalking} /> Users</h1>);
    let submenu = (<UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selection={"details"} />)
    
    if (merchantId && merchantId.length > 10) { 
        masterMenuSelection = "merchants";
        backPath = "/merchants/" + merchantId + "/users";
        h1 = (<h1><FontAwesomeIcon icon={faAddressCard} />
                <span className={"subtitle"}>Merchant:</span>
                {merchant?.name}
            </h1>);
        
        submenu = (
            <>
                <div className={"sub-sub-menu-container"}><MerchantSubMenu hasTooltip={true} merchant={merchant} selection={"users"} /></div>
                <UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selection={"details"} />
            </>
        );
    } else { 
        //
    }
    
    let userForm = !!userJson ? (<PersonForm value={userJson} useButton={true} buttonCaption={"Update " + userJson.first_name} onSubmit={updateUserAsync} />) : null;
    let userMerchantInfo = userMerchant ? (<><Link to={"/merchants/" + user?.merchantId}>{userMerchant?.name}</Link></>) : null;

    return (
        <UserMasterPage onUser={onUser} onMerchant={onMerchant} title={"User Details"}>
            <div className={"date-activity"}>
                <span id={"user-last-login-date"}>
                    <FontAwesomeIcon icon={faArrowRightToBracket} />
                    <DateTime showRelative={true} value={user?.lastLogin} defaultValue={"Hasn't logged in yet"} prefix={"Last Login: "} />
                </span>
                <span id={"user-created-date"}>
                    <FontAwesomeIcon icon={faUserPlus} />
                    <DateTime showRelative={true} value={user?.created} defaultValue={"Record creation date was not logged"} prefix={"Created: "} />
                </span>
                <span id={"user-modified-date"}>
                    <FontAwesomeIcon icon={faFeatherPointed} />
                    <DateTime showRelative={true} value={user?.modified} defaultValue={"User has never been modified"} prefix={"Last Modified: "} />
                </span>
            </div>

            <p>
                { user?.role?.name } user, associated with merchant: {userMerchantInfo}
            </p>

            <div id="user-details-body">
                {userForm}
            </div>            
        </UserMasterPage>
    );
    
};

export default UserDetailsScreen;