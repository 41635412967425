import React, {useEffect, useState} from 'react';
import MerchantMasterPage from "../MerchantMasterPage";
import MerchantUserSubMenu from "../../../components/merchants/ui/MerchantUserSubMenu";
import MerchantService from "../../../components/merchants/services/MerchantService";
import UserService from "../../../components/people/services/UserService";

import {Link, useParams} from "react-router-dom";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";

const MerchantUserMasterPage = (props) => {
    const {
        title,
        onUser,
        onMerchant,
        onRefreshUser,
        onRefreshMerchant,
        defaultUser,
        defaultUserId,
        defaultMerchant,
        defaultMerchantId,
        defaultLocationId,
        subTitle,
        onClick,
        children,
        force,
        icon,
        actions,
        selection,
        selector
    } = props;

    let _;
    const { userId, merchantId, locationId } = useParams();
    const initialMerchant = defaultMerchant || (MerchantService.instance.merchantMap[defaultMerchantId || merchantId] || null);
    const initialUser = defaultUser || (UserService.instance.userMap[defaultUserId || userId] || null);
    
    const [merchant, setMerchant] = useState(initialMerchant);
    const [user, setUser] = useState(initialUser);
    
    const onMasterUser = (user) => {
        setUser(user);
    };
    
    const onMasterMerchant = (merchant) => {
        console.log("OnMasterMerchant Good.");
        setMerchant(merchant);
    };

    const getUserAsync = async (force) => {
        if (!userId || userId.length < 30) {
            console.warn("Aborting getting user: " + userId);
            
            if (userId === "000-000") { 
                setUser(new UserModel());
            }
            
            return
        }
        
        await UserService.instance.getUserAsync(userId).then((u) => {
            setUser(u);
        });
    };

    const getActionElements = () => {
        if (!actions || !Array.isArray(actions)) return null;

        return actions.map((a, i) => {
            return typeof a === 'object' ? a : (<span key={"action-" + i}>{a}</span>);
        });
    };
    
    useEffect(() => { 
        if (!!user && typeof onUser === 'function') onUser(user);
    }, [user]);

    useEffect(() => {
        if (!!merchant && typeof onMerchant === 'function') onMerchant(merchant);
    }, [merchant]);

    useEffect(() => {
        console.log("Getting user in MerchantUserMasterPage async.");
        _ = getUserAsync();
    }, []);
    
    const userTreeElement = user?.id?.length > 30 ? (<><Link to={"/merchants/" + merchantId + "/users/" + user?.id}><span className={"subtitle"}>{user?.name || "User"}</span></Link>
            <span className={"bullet"}>&#10095;&#10095;</span>
            <span className="subtitle">{title || "Overview"}</span> { getActionElements() }</>) :
        (<span className={"subtitle"}>New User</span>);
    
    const subtitle = !!user ? ((typeof (title || subTitle) === 'object') ? subTitle : 
        (<><Link to={"/merchants/" + merchantId + "/users"} className={"subtitle"}>{ merchant?.name }</Link>
        <span className={"bullet"}>&#10095;&#10095;</span>
            { userTreeElement }
        </>)) : 
    null;

    const subMenu = user?.id?.length > 30 ? (<MerchantUserSubMenu user={user} merchant={merchant} selection={selection || "main"} />)  : null;
    
    return (<MerchantMasterPage selection={"users"} onMerchant={onMasterMerchant} title={subtitle} onClick={onClick}>
        { subMenu }
        { children }
    </MerchantMasterPage>);
};

export default MerchantUserMasterPage;

