import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket, faFeatherPointed, faMoon, faUserPlus, faUserTie } from "@fortawesome/free-solid-svg-icons";
import {Link, Navigate, useParams} from "react-router-dom";
import UserService from "../../../components/people/services/UserService";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantEffect from "../../../components/merchants/services/MerchantEffect";
import PersonForm from "../../../components/people/ui/PersonForm";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import MerchantUserMasterPage from "./MerchantUserMasterPage";
import '../../../components/merchants/ui/Merchant.css';
import RoleModel from "../../../components/people/models/RoleModel";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

import EmptyBox from "../../../components/common/ui/EmptyBox";
import ErrorModel from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import Controller from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import BackButton from "@paylani/paylani-react-packages/dist/common/ui/BackButton";

const MerchantUserDetailsScreen = (props) => {
    const { userId, merchantId } = useParams();
    let [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [newData, setNewData] = useState({ roleKey: null, username: null});
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId || ''] || null);
    const [userMerchant, setUserMerchant] = useState(MerchantService.instance.merchantMap[user?.merchantId || ""] || null);
    const [controller] = useState(new Controller());
    const [errors, setErrors] = useState({});
    
    let _;
    const isNewUser = !!userId && userId.length > 1 && userId.length < 30;

    const validateUsername = (json) => {
        let err = {};
        
        if (isNewUser) { 
            if (!newData?.username) err.username = "Username is required";
        }

        setErrors(err);
        
        return err;
    };
    
    const handleError = (ex) => {
        setErrors(ErrorModel.createFormError(ex));
    }
    
    const getUserAsync = async (force, e) => {
        if (isNewUser) return;
        
        await UserService.instance.getUserAsync(userId).then((user) => {
            setUser(user);
        });
    };

    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };
    
    const onUser = (user) => {
        setUser(user);
    };
    
    const handleNewChange = (newDataFieldId, e) => {
        let value = e.target.value;
        console.warn(newDataFieldId + ": " + value);
        
        let nd = { 
            roleKey: newData.roleKey,
            username: newData.username
        }
        
        nd[newDataFieldId] = value;
        
        setNewData(nd);
    };
    
    const updateUserAsync = async (user) => {
        user.id = userId;
        await UserService.instance.updateUserAsync(user).then((user) => {
            if (!!user) setUser(user);
        });
    };

    const createUserAsync = async (user) => {
        user.username = newData.username;
        user.roleKey = newData.roleKey;
        
        if (!validateUsername(user)) {
            return;
        }
        
        await UserService.instance.createUserAsync(merchantId, user).then((user) => {
            setRedirectUrl("/merchants/" + merchantId + "/users/" + user.id + "/access");
        });
    };

    const getUserMerchantAsync = async (force, e) => {
        if (!!userMerchant && force !== true) return userMerchant;
        
        await MerchantService.instance.getMerchantAsync(merchantId || "").then((m) => {
            setUserMerchant(m);
            return m;
        });
    };
    
    useEffect(() => { 
        //
    }, [newData]);
    
    useEffect(() => {
        if (merchantId) MerchantEffect.create(setMerchant, merchantId);
        _ = getUserAsync();
    }, []);

    useEffect(() => {
        if (!!user) _ = getUserMerchantAsync();
    }, [user]);

    if (!!redirectUrl) return (<Navigate to={redirectUrl} />);
    
    let backPath = "/people";

    const buttonCaption = isNewUser ? "Create User" : "Update " + user?.firstName;
    const me = AuthenticationService.instance.session?.user;
    const hasPermission = me?.isMaster || user?.id === me?.id;
    const userForm = (hasPermission && !!user) || isNewUser ? 
        (<PersonForm onValidate={validateUsername} onError={handleError} value={user?.toJson} useButton={true} controller={controller} buttonCaption={buttonCaption} onSubmit={isNewUser ? createUserAsync : updateUserAsync} />) :
        (hasPermission ? null : (<EmptyBox>You cannot edit this user</EmptyBox>));
    
    const errorClassName = Object.keys(errors).length > 0 ? " form-error" : "";

    const newUserForm = isNewUser ? (<div className={"form" + errorClassName}><div className="form-group double">
        <div>
            <label htmlFor="customer-user-name">Username</label>
            <input type="text" id="user-name" defaultValue={newData.username} onChange={handleNewChange.bind(this, "username")} />
            <div className={"form-note"}>{ errors.username }</div>
        </div>
        <div>
            <label htmlFor="customer-role-id">Role</label>
            <select id="user-role-id" defaultValue={newData.roleKey} onChange={handleNewChange.bind(this, "roleKey")}>
                { RoleModel.roles.map((role, idx) => (<option key={"role-" + idx} value={role.roleKey}>{role.name}</option>)) }
            </select>
            <div className={"form-note"}>{ errors.role }</div>
        </div>
    </div></div>) : null;

    const activeLoginText = user?.statusKey === 0 ? "Not Activated" : "Hasn't logged in";
    const loginIcon = user?.statusKey === 0 ? faMoon : faArrowRightToBracket;
    
    const dateElements = isNewUser ? (<><span id={"user-last-login-date"}>
                <FontAwesomeIcon icon={faUserPlus} />
                Creating a new user for <strong>{merchant?.name}</strong>
            </span></>) : 
        (<><span id={"user-last-login-date"} className={"status-" + user?.status?.toString()}>
                <FontAwesomeIcon icon={loginIcon} />
                <DateTime showRelative={true} value={user?.lastLogin} defaultValue={activeLoginText} prefix={"Last Login: "} />
            </span>
            <span id={"user-created-date"}>
                <FontAwesomeIcon icon={faUserPlus} />
                <DateTime showRelative={true} value={user?.created} defaultValue={"Record creation date was not logged"} prefix={"Created: "} />
            </span>
            <span id={"user-modified-date"}>
                <FontAwesomeIcon icon={faFeatherPointed} />
                <DateTime showRelative={true} value={user?.modified} defaultValue={"Never modified"} prefix={"Modified: "} />
            </span>
        </>);
    
    const usernameElement = !isNewUser ? (<label className={"username"}>Username: <strong>{user?.username}</strong></label>) : null;
    
    const profileImage = user?.imageUrl ? (<img src={user?.imageUrl} alt={user?.firsName + " " + user?.lastName} />) :
        (<span><FontAwesomeIcon icon={faUserTie} /></span>);
    const userMerchantInfo = userMerchant ? (<><Link to={"/merchants/" + user?.merchantId}>{userMerchant?.name}</Link></>) : null;
    const subtitle = (<BackButton />);

    return (
        <MerchantUserMasterPage subTitle={subtitle} selection={"main"} onMerchant={onMerchant} onUser={onUser} title={"Overview"}>
            <div className={"merchant-user-details"}>
                <div className={"profile-image"}>
                    {profileImage}
                </div>
                <div>
                    <div className={"date-activity"}>
                        { dateElements }
                    </div>

                    { usernameElement }

                    <p>
                        { user?.role?.name } user, associated with merchant: {userMerchantInfo}
                    </p>
                </div>
            </div>

            <div id="user-details-body">
                {newUserForm}
                {userForm}
            </div>
        </MerchantUserMasterPage>
    );
};

export default MerchantUserDetailsScreen;

