import React from 'react';
import MasterScreenMain from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import ReportingSubMenu from "../../components/reporting/ui/ReportingSubMenu";
import ReportingMasterPage from "./ReportingMasterPage";

const CustomReportsScreen = (props) => {
    const {} = props;

    return (
        <ReportingMasterPage selection="custom" title={"My Saved Reports"}>
            <p>
                This is where you can create your own reports and save them for later.
            </p>
            
            <div>
                <h3>You have no saved reports</h3>
            </div>
            
        </ReportingMasterPage>
    );
    
};

export default CustomReportsScreen;
