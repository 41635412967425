class MerchantFieldModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.merchantId = json.merchant_id;
        this.merchantLocationId = json.merchant_location_id;
        this.fieldTypeKey = json.field_type_key;
        this.fieldTypeName = json.field_type_name || "";
        
        this.label = json.label;
        this.defaultValue = json.default_value;
        this.placeHolder = json.place_holder;
        this.gatewayField = json.gateway_field;
        this.errorLabel = json.error_label;
        this.sorting = json.sorting;
        this.isRequired = json.is_required;
        this.isActive = json.is_active;
        this.status = json.status;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            field_type_key: this.fieldTypeKey,
            label: this.label,
            default_value: this.defaultValue,
            place_holder: this.placeHolder,
            gateway_field: this.gatewayField,
            error_label: this.errorLabel,
            sorting: this.sorting,
            is_required: this.isRequired,
            is_active: this.isActive
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new MerchantFieldModel(json));
    }
}

export default MerchantFieldModel;
