import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import UserSessionModel from "../models/UserSessionModel";
//import UserSessionModel from "@paylani/paylani-react-packages/dist/authentication/models/UserSessionModel";

class UserService {
    static instance = new UserService();
    
    constructor() {
        this.users = [];
        this.userMap = {};
    }

    async getUsersAsync() {
        const path = '/api/user?role=master';
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.users = UserModel.fromJsonArray(response.data);
            me.users.map((user) => me.userMap[user.id] = user);

            return me.users;
        });
    }
    
    async getUserAsync(userId) {
        if (!userId || userId.length < 30) return null;
        
        const path = '/api/user/' + userId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            let user = UserModel.create(response.data);

            if (!!user.id) me.userMap[user.id] = user;

            return user;
        });
    }

    async getUserSessionsAsync(userId, startDate, endDate) {
        const path = '/api/user/' + userId + '/session';
        const me = this;

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((response) => {
            return UserSessionModel.fromJsonArray(response?.data);
        });
    }
    
    async getActivityAsync(userId) {
        const path = '/api/user/' + userId + '/activity';
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            return response.data;
        });
    };
    
    async createUserAsync(merchantId, userJson, locationIds = null) {
        if (!merchantId || merchantId.length < 30) throw new Error("Invalid merchant id");
        
        const path = "/api/merchant/" + merchantId + "/user/";
        const me = this;

        const data = {
            first_name: userJson.first_name || (userJson.firstName || ""),
            last_name: userJson.last_name || (userJson.lastName || ""),
            email: userJson.email || "",
            phone: userJson.phone || "",
            role_key: userJson.role_key || userJson.roleKey,
            username: userJson.username,
            location_ids: locationIds || []
        };
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            return UserModel.create(response.data);
        });
    }
    
    async updateUserAsync(userJson, id = null) {
        if (typeof id !== "string" || id.length < 30) {
            id = userJson.id;
            delete userJson.id;
        }
        if (typeof id !== "string" || id.length < 30) throw new Error("Invalid User Id to update in UserService");
        
        const path = '/api/user/' + id;
        const me = this;

        const data = {
            first_name: userJson.first_name || (userJson.firstName || ""),
            last_name: userJson.last_name || (userJson.lastName || ""),
            email: userJson.email || "",
            phone: userJson.phone || "",
        };
        
        //console.warn(JSON.stringify(data, null, 4));

        return await HttpService.instance.postAsync(path, data).then((response) => {
            return new UserModel(response.data);
        });
    }
    
    async updateMerchantAccessAsync(userId, accessibleMerchants) {
        if (!Array.isArray(accessibleMerchants)) return;

        const path = '/api/user/' + userId + '/merchant-access';
        const me = this;

        let merchantIds = accessibleMerchants.map((m) => m.id);
        
        return await HttpService.instance.postAsync(path, merchantIds).then((response) => {
            return response.data;
        });
    }

    async updateLocationAccessAsync(userId, accessibleLocations) {
        if (!Array.isArray(accessibleLocations) || accessibleLocations.length === 0) return [];

        const merchantId = accessibleLocations[0].merchantId;
        const path = '/api/user/' + userId + '/merchant/' + merchantId + '/location-access';
        const me = this;

        let locationIds = accessibleLocations.map((m) => m.id);

        return await HttpService.instance.postAsync(path, locationIds).then((response) => {
            return response.data;
        });
    }
    
    async uploadImageAsync(userId, file) {
        const path = "/api/user/" + userId + "/image";
        const me = this;

        return await HttpService.instance.uploadAsync(path, file).then((response) => {
            return response?.data;
        });
    };
    
    async deleteImageAsync(userId) {
        const path = "/api/user/" + userId + "/image";
        const me = this;

        return await HttpService.instance.deleteAsync(path).then((response) => {
            return response?.data;
        });
    }
    
    async sendPasswordResetLinkAsync(userId, isMobile = false) { 
        const path = '/api/user/' + userId + '/reset';
        const me = this;
        const mobileExt = (isMobile === true) ? "?mobile=true" : "";
        
        return await HttpService.instance.postAsync(path + mobileExt).then((response) => {
            return response;
        });
    }

    async deleteUserAsync(userId) {
        if (!userId) throw new Error("No user id provided");
        
        const path = '/api/user/' + userId + '';
        const me = this;

        console.log("Deactivating user: " + userId);
        
        return await HttpService.instance.deleteAsync(path).then((response) => {
            return response;
        });
    }
    
    async deleteSessionsAsync(userId) {
        const path = '/api/user/' + userId + '/session';
        const me = this;

        return await HttpService.instance.deleteAsync(path).then((response) => {
            return response;
        });
    }
}

export default UserService;
