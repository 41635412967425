import React, { useState, useRef, useEffect } from 'react';
import CreditCardForm from "../../../payments/ui/CreditCardForm";
import AddressForm from "@paylani/paylani-react-packages/dist/geo/ui/AddressForm";
import PersonForm from "../../../people/ui/PersonForm";
import ExpandableSection from "../../../common/ui/exapandable-section/ExpandableSection";
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuildingColumns, faCreditCard, faImage, faShare} from "@fortawesome/free-solid-svg-icons";
import "./VirtualTerminal.css";
import AchForm from "../../../payments/ui/AchForm";
import Controller from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import CustomerService from "../../../people/services/CustomerService";
import LoadingBox from "../../../common/ui/LoadingBox";
import SaleService from "../../services/SaleService";
import SaleModel from "../../models/SaleModel";
import Dialog from "@mui/material/Dialog/Dialog";
import {DialogTitle} from "@mui/material";
import {Navigate} from "react-router-dom";
import ErrorModel from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import PaymentModel from "../../../payments/models/PaymentModel";

const VirtualTerminal = (props) => {
    let { customerId, customer } = props;
    
    const initViews = {};
    const [paymentMethodType, setPaymentMethodType] = useState("1");    // 5= ach, 1 = credit card, 10=invoice
    const [successMessage, setSuccessMessage] = useState({ message: "", path: ""});
    const [controller, setController] = useState(new Controller());
    const [saleCustomer, setSaleCustomer] = useState(customer || null);
    const [error, setError] = useState({});
    
    const amountRef = useRef();
    const cc = { name: "Credit Card", icon: faCreditCard, form: (<CreditCardForm controller={controller} />), key: "credit-card" };

    const onOtherFormError = (err) => {
        //
    };
    
    const paymentMethods = {
        "1": cc,
        "5": { name: "ACH", icon: faBuildingColumns, form: (<AchForm onError={onOtherFormError} controller={controller} />), key: "ach" },
        "10": { name: "Invoice", icon: faShare, form: (<PersonForm prefix={"customer-"} onError={onOtherFormError} controller={controller} requiredFields={{email_or_phone: true, email: "Email is required", phone: "Phone is required" }} />), key: "invoice", caption: "Send Invoice" }
    };
    
    const getCustomerAsync = async (force) => { 
        if (!force && !!saleCustomer?.id && saleCustomer.id.length > 30) return saleCustomer;
        
        const c = await CustomerService.instance.getCustomerAsync(customerId);
        if (!!c) setSaleCustomer(c);
        
        return c;
    };
    
    const selectPaymentType = (key) => {
        setPaymentMethodType(key);
    };
    
    const createPaymentPayload = (amount, customer, creditCard, ach) => PaymentModel.createPaymentJson(creditCard, ach, amount, paymentMethodType, customerId, customer);

    const createSalePayload = (amount, customer) => { 
        const payload = SaleModel.createSaleJson(amount, customer);
        
        if (!payload.customer_id || payload.customer_id.length < 30) 
            payload.customer_id = customerId || null;
        
        return payload;
    }
    
    const onAmountFocus = (e) => {
        setError({});
    }
    
    useEffect(() => {
        let _ = getCustomerAsync();
    }, []);
    
    if (!!successMessage?.path) { 
        return (<Navigate to={successMessage.path} />);
    }
    
    const processSaleAsync = async (e) => {
        const ach = paymentMethodType === "5" && typeof controller.getAchData === 'function' ? controller.getAchData() : null;
        const cc = paymentMethodType === "1" && typeof controller.getCreditCardData === 'function' ? controller.getCreditCardData() : null;
        const customer = typeof controller.getPersonData === 'function' ? controller.getPersonData() : null;
        const address = typeof controller.getAddressData === 'function' ? controller.getAddressData() : null;
        const amount = parseFloat(amountRef.current?.value);
        const tax = parseFloat("0.0");

        if (!amount || amount <= 0) { 
            const amt = document.getElementById("amount");
            if (!!amt) amt.focus();
            
            setError({ amount: "Amount must be greater than zero (" + amount + ")" });
            return;
        }

        if (!!cc && !cc.billing_zip) cc.billing_zip = address?.zip;
        
        if (!!customer) {
            if (!customer.billing_address?.address1)
                customer.billing_address = address;

            if (!!cc?.number) customer.credit_card = cc;
            else if (ach?.account_number) customer.ach = ach;
        }
        
        const salePayload = createSalePayload(amount, customer, cc);
        const paymentPayload = createPaymentPayload(amount, customer, cc, ach);

        salePayload.payments.push(paymentPayload);

        // Existing customer
        salePayload.customer_id = null;
        salePayload.invoice_number = null;
        salePayload.order_number = null;
        salePayload.tax = tax || 0;
        
        //console.warn(JSON.stringify(salePayload, null, 4));

        if (paymentMethodType === "1" && !cc) return;
        if (paymentMethodType === "5" && !ach) return;
        
        if (paymentMethodType === "10") {
            if (!customer) return;
            salePayload.payments = [];
        }
        
        const saleModel = await SaleService.instance.createSaleAsync(salePayload, paymentMethodType).catch((err) => {
            const formError = new ErrorModel(err);
            
            console.error("IsFormError: " + formError.isFormError);
            
            return formError;
        });

        if (saleModel.status === 1 || paymentMethodType === "10") {
            console.warn(JSON.stringify(saleModel, null, 4));
            const message = (paymentMethodType === "10") ? "Draft Sale Created Successfully" : "Sale Completed Successfully";
            setSuccessMessage({ message: message, path: "" });
            
            setTimeout(() => {
                setSuccessMessage({ message: message, path: "/merchants/" + saleModel.merchantId + "/sales/" + saleModel.id });
            }, 1500);
        } else {
            console.warn("------- Error -------");
            console.error(saleModel.message || JSON.stringify(saleModel, null, 4));
            const message = saleModel.message || ((saleModel.payments?.length > 0 ? saleModel.payments[0].message : null) || "");
            if (!!message) {
                console.error(message);
                setError({ general: message });
            }
        }
        
        return saleModel;
    }
    
    const selectorClass = {};
    selectorClass[paymentMethodType] = "selected";

    const createCustomerPanel = () => {
        if (!saleCustomer?.id) return (<LoadingBox />);

        //saleCustomer.imageUrl = "https://s3-us-west-2.amazonaws.com/dev-paylani-images/userb8804d7e-9b80-459b-b8d6-963fa2852228-637640453654637166.png";
        
        const vaultElement = (<li>{ !!saleCustomer.customerVaultId ? "Vault Id: " + saleCustomer.vaultId : "No Card on File"}</li>);
        const emailElement = !!saleCustomer.email ? (<li><a href={"mailto:" + saleCustomer.email}>{ saleCustomer.email }</a></li>) : null;
        const imageElement = saleCustomer?.imageUrl ?
            (<img src={saleCustomer.imageUrl} alt={"Customer Image"} />) :
            (<FontAwesomeIcon icon={faImage} />);
        
        return (<div className={"customer-sale-info"}>
            <div className={"customer-image"}><span className={"virtual-image"}>{ imageElement }</span></div>
            <ul>
                <li>{ saleCustomer.firstName } { saleCustomer.lastName }</li>
                { vaultElement }
                { emailElement }
            </ul>
        </div>);
    };
    
    const hasCustomer = !!customerId && customerId.length > 30;
    const hasVault = hasCustomer && !!saleCustomer?.customerVaultId;
    const customerPanel = hasCustomer ?
        createCustomerPanel() :
        null;

    const pm = paymentMethods[paymentMethodType] || cc;
    const paymentTypeTitle = pm.name + " Information";
    const paymentForm = hasVault ? (<ExpandableSection title={paymentTypeTitle}>{ pm.form }</ExpandableSection>) : pm.form;
    const customerForm = paymentMethodType !== "10" ? (<ExpandableSection customer={saleCustomer} title={"Customer Information"} isOpen={false}>
        <PersonForm requiredFields={{first_name: true}} controller={controller} onError={onOtherFormError} />
    </ExpandableSection>) : null;
    
    const addressForm = (<AddressForm controller={controller} onError={onOtherFormError} />);
    
    const billingAddressForm = (<ExpandableSection title={"Billing Address"} isOpen={false}>
        { addressForm }
    </ExpandableSection>);
    
    return (<div className={"virtual-terminal"}>
        <h3>Virtual Terminal</h3>
        
        <div className={"form form-error"}>
            <div className={"virtual-terminal-panel"}>
                <div className={"form-group"}>
                    <label>Amount</label>
                    <div className={"amount-container"}>
                        <input ref={amountRef} type={"number"} id={"amount"} placeholder={"0.00"} onFocus={onAmountFocus} />
                        <div className={"symbol"}>$</div>
                    </div>
                    <div className={"form-error"}>{ error?.amount }</div>
                </div>
                <div className={"default-customer"}>
                    { customerPanel }
                </div>
            </div>
            
            <h3 className={"payment-selector"}>
                <span>{ paymentTypeTitle }</span>

                <a className={selectorClass["1"] || ""} onClick={(e) => selectPaymentType("1")}><FontAwesomeIcon icon={faCreditCard} /></a>
                <a className={selectorClass["5"]} onClick={(e) => selectPaymentType("5")}><FontAwesomeIcon icon={faBuildingColumns} /></a>
                <a className={selectorClass["10"]} onClick={(e) => selectPaymentType("10")}><FontAwesomeIcon icon={faShare} /></a>
            </h3>

            { paymentForm }
            { customerForm }
            { billingAddressForm }
            
            <div className={"button fixed"}>
                <div className={"form-note"}>{error?.general}</div>
                <FormButton onClick={processSaleAsync}>{ pm.caption || "Process Sale"}</FormButton>
            </div>
        </div>

        <Dialog open={!!successMessage?.message}>
            <DialogTitle>{ successMessage.message }</DialogTitle>
        </Dialog>
        
    </div>);
};

export default VirtualTerminal;

