import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantModel from "../../../components/merchants/models/MerchantModel";
import LocationMasterPage from "./LocationMasterPage";

const LocationContentScreen = (props) => {
    const {merchantId, locationId} = useParams();

    let _;
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));

    const onMerchant = (m) => {
        setMerchant(m);
    };

    const onLocation = (l) => {

    };

    useEffect(() => {

    }, []);

    let location = merchant.locations[0];
    return (
        <LocationMasterPage onMerchant={onMerchant} onLocation={onLocation} title={"Content"} selection={"content"}>
            <p>
                This merchant location appears as "{location?.name}" on reporting, and is for display purposes. The parent merchant is <Link to={"/merchants/" + merchantId}>{merchant?.name}</Link>.
            </p>
        </LocationMasterPage>
    );


};

export default LocationContentScreen;
