import React, {useEffect, useState} from 'react';
import MerchantService from "../../components/merchants/services/MerchantService";
import AddressForm from "@paylani/paylani-react-packages/dist/geo/ui/AddressForm";
import ConfigModel from "@paylani/paylani-react-packages/dist/common/models/ConfigModel";
import SettingsMasterScreen from "@paylani/paylani-react-packages/dist/settings/ui/screens/SettingsMasterScreen";
import CompanyForm from "@paylani/paylani-react-packages/dist/companies/ui/CompanyForm";
import Controller from "@paylani/paylani-react-packages/dist/common/controllers/Controller";
import ErrorModel from "@paylani/paylani-react-packages/dist/common/models/ErrorModel";

const CompanyInfoScreen = (props) => {
    const [company, setCompany] = useState(ConfigModel.company || null);
    const [errors, setErrors] = useState({});
    const controller = useState(new Controller())[0];
    
    const waitForCompany = async () => {
        let i = 0;
        
        while (!ConfigModel.company && i < 1000) {
            await new Promise(r => setTimeout(r, 100));
            i ++;
        }
        
        console.warn("Waiting Done: " + i);
        console.log("Company: " + ConfigModel.company?.name);
        
        if (i > 0 && !!ConfigModel.company?.id) {
            setCompany(ConfigModel.company);
        }
    };
    
    useEffect(() => {
        waitForCompany().catch((ex) => { 
            console.error("Failed waiting for company: " + ex);
        });
    });
    
    const onFormChange = (e) => {
        
    };

    const updateCompanyInfoAsync = async () => {
        console.log("Updating Merchant...");

        const companyJson = controller.getFormData("company");
        companyJson.address = controller.getFormData("address");
        companyJson.id = ConfigModel.companyId;

        console.log(JSON.stringify(companyJson, null, 4));
        
        const m = await MerchantService.instance.updateMerchantAsync(companyJson).catch((ex) => {
            setErrors(ErrorModel.createFormError(ex));
            return null;
        });

        if (!!m?.id) ConfigModel.init(m);
    };
    
    const companyForm = !!company ? (<CompanyForm controller={controller} value={company?.toJson()} onChange={onFormChange} useButton={false} />) : null;
    const addressForm = !!company ? (<AddressForm controller={controller} value={company?.address?.toJson()} onSubmit={() => updateCompanyInfoAsync()} useButton={true} />) : null;
    
    return (
        <SettingsMasterScreen title={"Settings Main"} selection={"company"} subTitle={"Company Information"}>
            <p>
                This information is displayed on outgoing correspondence originating from this platform.
            </p>

            <div className="form">
                { companyForm }
                <h3>Address</h3>
                { addressForm }
            </div>

        </SettingsMasterScreen>            
    );
    
};

export default CompanyInfoScreen;
