import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPenNib
} from "@fortawesome/free-solid-svg-icons";
import '../../components/merchants/ui/Merchant.css';
import {useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import CompanyForm from "@paylani/paylani-react-packages/dist/companies/ui/CompanyForm";
import MerchantMasterPage from "./MerchantMasterPage";
import ErrorModel from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import AddressForm from "@paylani/paylani-react-packages/dist/geo/ui/AddressForm";
import Controller from "@paylani/paylani-react-packages/dist/common/controllers/Controller";

const MerchantEditScreen = () => {
    const {merchantId} = useParams();
    const [errors, setErrors] = useState({});
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null);
    const [merchantJson, setMerchantJson] = useState(merchant?.toJson() || null);
    const controller = useState(new Controller())[0];
    
    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };
    
    const updateMerchantAsync = async (addressJson, e) => {
        console.log("Update Merchant");
        
        const companyJson = controller.getFormData("company-form");
        companyJson.id = merchantId;
        companyJson.address = addressJson;
        
        const m = await MerchantService.instance.updateMerchantAsync(companyJson).catch((ex) => { 
            setErrors(ErrorModel.createFormError(ex));
            return null;
        });

        if (!!m) {
            console.log("Update Completed.");
            setMerchant(m);
        }
    };

    useEffect(() => {
        if (!!merchant) setMerchantJson(merchant.toJson());
    }, [merchant]);

    const companyForm = !!merchantJson ? (<CompanyForm controller={controller} value={merchantJson} useButton={false} />) : null;
    const addressForm = !!merchantJson ? (<AddressForm controller={controller} value={merchantJson?.address} useButton={true} onSubmit={updateMerchantAsync} />) : null;
    
    return (
        <MerchantMasterPage title={"Edit Merchant"} selection={"edit"} onMerchant={onMerchant}>
            <p>
                This merchant appears as "{merchant?.name}" on reports and recipets.
            </p>

            <h3 style={{marginTop: '64px', marginBottom: '32px'}}>
                <FontAwesomeIcon icon={faPenNib} />
                Edit Merchant Details on File
            </h3>

            {companyForm}
            
            <h3>Address</h3>
            {addressForm}
        </MerchantMasterPage>
    );
    

};

export default MerchantEditScreen;
