import React, {useEffect, useRef, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantLocationService from "../../../components/merchants/services/MerchantLocationService";
import LocationMasterPage from "./LocationMasterPage";

import AddressForm from "@paylani/paylani-react-packages/dist/geo/ui/AddressForm";
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import ErrorModel from "@paylani/paylani-react-packages/dist/common/models/ErrorModel";
import Controller from "@paylani/paylani-react-packages/dist/common/controllers/Controller";

const LocationEditScreen = (props) => {
    const {merchantId, locationId} = useParams();
    let _;
    
    const getLocation = () => merchant?.locations && merchant?.locations.length > 0 ? merchant?.locations.find((x) => x.id === locationId) : null;

    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    const [address, setAddress] = useState(getLocation()?.address || null);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [controller] = useState(new Controller());
    const [errors, setErrors] = useState({});

    const location = getLocation();

    const nameRef = useRef();
    const descriptionRef = useRef();
    
    let locationName = location?.name;
    let locationDescription = location?.description;
    
    const isNewLocation = !locationId || locationId.length < 30;
    
    const onMerchant = (m) => {
        setMerchant(m);
    };

    const onLocation = (l) => {

    };
    
    const onAddressError = (errorModel) => { 
        if (errorModel?.hasFormErrors() === true) {
            setErrors({...errorModel.focus("location-")});
        }
    }
    
    const validateForm = (model, existingErrors = {}) => { 
        //
        const err = {};
        if (!model.name || model.name.length < 1)
            err.name = "Name is required";
        
        if (typeof existingErrors !== "object")
            existingErrors = {};
        
        return new ErrorModel({...err, ...existingErrors});
    };
    
    const onLocationSubmit = async (e) => {
        const locationJson = {
            name: nameRef.current?.value || null,
            description: descriptionRef.current?.value || null,
            merchant_id: merchantId,
            address: controller.getFormData("address"),
            payment_provider_type: null,
        };
        
        const id = (locationId && locationId.length > 30) ? locationId : null;
        const err = validateForm(locationJson, controller.errors);
        
        if (err.hasFormErrors()) {
            setErrors(err.focus("location-"));
            return;
        }
        
        const m = await MerchantLocationService.instance.updateLocationAsync(locationJson, id);
        
        if (isNewLocation && !!m?.id) {
            setRedirectUrl("/merchants/" + merchantId + "/locations/" + m.id + "/configurations");
            return
        }
        
        setRedirectUrl("/merchants/" + merchantId + "/locations/" + m.id + "");
        // TODO: Action Completed Action
    };
    
    useEffect(() => {

    }, []);

    useEffect(() => {
        let l = getLocation();
        if (l) setAddress(l?.address);

    }, [merchant]);

    if (!!redirectUrl && redirectUrl.length > 0) {
        return (<Navigate to={redirectUrl} />);
    }

    const addressForm = address || isNewLocation ? (<AddressForm controller={controller} prefix={"location-"} value={address?.toJson()} />) : null;
    const title = isNewLocation ? "New Location" : "Edit Details";
    const existingText = isNewLocation ? "Creating a new Location. " : "This merchant location appears as " + location?.name + " on reporting, and is for display purposes.";
    
    return (
        <LocationMasterPage onMerchant={onMerchant} onLocation={onLocation} title={title} selection={"edit"}>
            <p>
                { existingText } The parent merchant is <Link to={"/merchants/" + merchantId}>{merchant?.name}</Link>.
            </p>

            <div className={"form"}>
                <div className={"form-group"}>
                    <label>Location Name:</label>
                    <input type={"text"} ref={nameRef} id={"location-name"} defaultValue={location?.name} />
                    <div className={"form-error"}>{errors?.name}</div>
                </div>
                <div className={"form-group"}>
                    <label>Optional Description:</label>
                    <textarea ref={descriptionRef} id={"location-description"} defaultValue={location?.description} />
                </div>

                {addressForm}

                <div className={"buttons"}>
                    <FormButton onClick={onLocationSubmit} label="Update Details" />
                </div>

            </div>
        </LocationMasterPage>
    );

};

export default LocationEditScreen;


