import React, { useState } from 'react';
import VirtualTerminal from "../../components/sales/ui/virtual-terminal/VirtualTerminal";
import MerchantMasterPage from "./MerchantMasterPage";
import MerchantService from "../../components/merchants/services/MerchantService";
import {useParams} from "react-router-dom";

const MerchantVirtualTerminal = () => {
    const { merchantId, customerId } = useParams();
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    
    const onMerchant = (m) => { 
        if (!!m) setMerchant(m);
    }
    
    const subtitle = "New Sale";
    
    return (
        <MerchantMasterPage title={subtitle} selection={"sales"} bodyClassName={"large"} onMerchant={onMerchant}>
            

            <VirtualTerminal />
        </MerchantMasterPage>
    );
};

export default MerchantVirtualTerminal;

