import MerchantTipModel from "./MerchantTipModel";
import MerchantConfigurationModel from "./MerchantConfigurationModel";

class MerchantTipConfigurationModel { 
    constructor(json) {
        if (!json) json = {};
        this.tips = MerchantTipModel.fromJsonArray(json.tips);
        this.config = new MerchantConfigurationModel(json.config);
    }
}

export default MerchantTipConfigurationModel;
