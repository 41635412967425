import ReportResponseModel from "./ReportResponseModel";
import ProductReportItemModel from "./ProductReportItemModel";

class ProductReportResponseModel extends ReportResponseModel { 
    constructor(json) {
        super(json);
        this.items = ProductReportItemModel.fromJsonArray(json?.items);
    }
}

export default ProductReportResponseModel;
