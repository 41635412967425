import React, {useEffect, useState} from 'react';
import MasterScreen from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import MerchantSubMenu from "../../components/merchants/ui/MerchantSubMenu";
import MerchantService from "../../components/merchants/services/MerchantService";
import {useParams} from "react-router-dom";
import MerchantEffect from "../../components/merchants/services/MerchantEffect";

const MerchantMasterPage = (props) => {
    const {
        title,
        onMerchant,
        defaultMerchant,
        defaultMerchantId,
        defaultLocationId,
        defaultMerchantLocationId,
        children,
        onClick,
        force,
        icon,
        actions,
        selection,
        selector,
        onSession,
        bodyClassName
    } = props;

    let _;
    const { merchantLocationId, userId } = useParams();
    const merchantId = useParams().merchantId || props.merchantId;
    const initialMerchant = defaultMerchant || (MerchantService.instance.merchantMap[merchantId] || null);
    
    let [merchant, setMerchant] = useState(initialMerchant); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));

    const onSessionUpdate = (s) => {
        //console.warn("onSessionUpdate: " + s?.id);
        
        if (!!s?.id && typeof onSession === "function") {
            onSession(s);
        }
    };
    
    const notifyMerchant = () => {
        if (!!merchant?.id && typeof onMerchant === 'function')
            onMerchant(merchant);
    };

    const getActionElements = () => {
        if (!actions || !Array.isArray(actions)) return null;
        
        return actions.map((a, i) => {
            return typeof a === 'object' ? a : (<span key={"action-" + i}>{a}</span>);
        });
    };
    
    useEffect(() => {
        notifyMerchant();
    }, [merchant]);

    useEffect(() => {
        if (!!merchantId) MerchantEffect.create(setMerchant, merchantId);
        notifyMerchant();
    }, []);
    
    const subtitle = typeof title === 'object' ? title : (<>{merchant?.name}: <span className="subtitle">{title || "Overview"}</span> { getActionElements() }</>);
    
    const body = children || (<p>No body for MerchantMasterPage</p>);
    const merchantMenu = !!merchant ? (<MerchantSubMenu merchant={merchant} selection={selection} />) : null;
    const bc = !!merchantMenu ? " with-menu" : "";
    const bodyClass = !!bodyClassName ? " " + bodyClassName : "";
    
    return (
        <MasterScreen onSessionChange={onSessionUpdate} selection={selector || "merchants"} icon={faAddressCard} title={"Merchants"} onClick={onClick}>
            <div className={"pad"}>
                <div className="details-body">
                    {merchantMenu}
                    
                    <div className={"pad" + bc + bodyClass}>
                        <h2>{subtitle}</h2>

                        { body }
                    </div>
                </div>
            </div>
        </MasterScreen>
    );
};

export default MerchantMasterPage;

