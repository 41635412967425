class SaleLineItemModel { 
    static json = {
        "sale_id": "564bae81-56e1-4a3b-8099-5ab3f96b0cca",
        "product_id": "5748c60d-4093-41bd-8e3e-d7615e2a7585",
        "price": 6.66,
        "qty": 1,
        "is_tax_override": false,
        "tax": 0.0,
        "name": "One-Off Charge",
        "backoffice_provider_type": 0,
        "g_l_code": "",
        "sku": "",
        "item_code": "",
        "remote_id": null,
        "image_url": "",
        "is_active": true,
        "id": "41845313-ed1a-41e9-b590-139c668728f8",
        "created": "2022-09-01T00:14:44.64",
        "modified": null
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || "";
        this.saleId = json.sale_id || "";
        this.productId = json.product_id || null;
        this.price = json.price || 0.00;
        this.qty = json.qty || 1;
        this.isTaxOverride = json.is_tax_override || false;
        this.tax = json.tax;
        this.name = json.name;
        this.backofficeProviderType = json.backoffice_provider_type;
        this.gLCode = json.g_l_code || "";
        this.sku = json.sku || "";
        this.itemCode = json.item_code || "";
        this.remoteId = json.remote_id || null;
        this.imageUrl = json.image_url || "";
        this.isActive = json.is_active || false;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => new SaleLineItemModel(json));
    }
}

export default SaleLineItemModel;

