class MerchantConfigurationModel { 
    
    constructor(json) {
        if (!json) json = {};
        this.json = json;

        this.id = json.id;
        this.merchantId = json.merchant_id;
        this.merchantLocationId = json.merchant_location_id;
        this.name = json.name;
        this.value = json.value;
        this.isActive = json.is_active;
        this.isPublic = json.is_public;
        this.status = json.status;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static createMap(configurations) {
        let map = {};
        configurations.forEach((configuration) => {
            map[configuration.name] = configuration;
        });
        
        return map;
    };
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map((json) => {
            return new MerchantConfigurationModel(json);
        });
    }
}

export default MerchantConfigurationModel;
