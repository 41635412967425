import React, {useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket, faCircleCheck, faCode, faCopy, faFeatherPointed, faLocationDot, faShare, faTachographDigital, faUserTag } from "@fortawesome/free-solid-svg-icons";
import {Link, Navigate, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import SaleService from "../../../components/sales/services/SaleService";
import {Tooltip} from "@mui/material";
import PaymentDetailsLineItem from "../../../components/payments/ui/PaymentDetailsLineItem";
import BackButton from "@paylani/paylani-react-packages/dist/common/ui/BackButton";
import MerchantMasterPage from "../MerchantMasterPage";
import RefreshButton from "../../../components/common/ui/RefreshButton";
import SaleModel from "../../../components/sales/models/SaleModel";
import DialogBox from "../../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import CopyText from "@paylani/paylani-react-packages/dist/common/ui/AddNewButton";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import '../../../components/sales/ui/Sales.css';

const SaleDetailsScreen = ({ slug }) => {
    const {saleId, merchantId, locationId} = useParams();

    let _;
    const [saleJson, setSaleJson] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState("");
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null);
    const [dialogController, setDialogController] = useState(new DialogBoxController("Raw Sale Data"));
    const [resendDialogController, setResendDialogController] = useState(new DialogBoxController("Resend Sale"));
    
    const resendDestinationRef = useRef();
    const sale = !!saleJson ? new SaleModel(saleJson) : SaleService.instance.saleMap[saleId] || null;
    
    const isLocationNav = !!locationId && locationId.length > 30;
    
    const getSaleJsonAsync = async () => {
        await SaleService.instance.getSaleJsonAsync(saleId).then((json) => {
            setSaleJson(json);
        });
    };

    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };
    
    const resendAsync = async () => {
        const destination = resendDestinationRef.current?.value || null;
        if (!destination) return false;
        
        await SaleService.instance.resendSaleAsync(saleId, destination).then((rsp) => {
            //
        });
        
        console.log(resendDestinationRef.current?.value || "No Value");
    };
    
    useEffect(() => {
        _ = getSaleJsonAsync();
    }, []);
    
    if (!!redirectUrl && redirectUrl.length > 3) {
        return (<Navigate to={redirectUrl} />);
    }
    
    const hasCustomer = sale?.customerId && sale?.customer;
    const customerEmail = sale?.customer?.email;
    const sendLabel = (!!customerEmail) ? "Re-Send" : "Send";

    const receiptTypeName = [8, 10].includes(sale?.status || 0) ? "Invoice" : "Receipt";
    const topTipText = (!!customerEmail) ? "Re-Send to " + customerEmail : "Send " + receiptTypeName + " Customer";

    const customerNameElement = hasCustomer ? 
        (<Tooltip arrow={true} placement={"top"} title={(sale.customer.firstName + " " + sale.customer.lastName + "").trim()}>
            <span className={"subtitle"}>{ sale.customer.name || "No Customer Name"}</span>
            </Tooltip>) :
        null;
    
    let ses = { payments: "", lineItems: ""};
    if (sale?.payments.length > 1) ses.payments = "s";
    
    let paymentElements = null;
    
    if (sale?.payments) { 
        if (sale.payments.length === 1) { 
            //
        } else {
            //
        }
        
        paymentElements = sale.payments.map((p, i) => (<PaymentDetailsLineItem key={"payment-element-" + i} payment={p} />));
    }
    
    const paymentElement = paymentElements?.length > 0 ? (<div className={"sale-section"}>
        <h3>Payment{ses.payments}!</h3>
        { paymentElements }
    </div>) : (<strong>No payments made {sale?.status === 8 ? "yet" : ""}</strong>);
    
    let cartItemElements = sale?.lineItems?.map((item) => {
        return (
            <tr key={item.id || "x"}>
                <td>{item.name}</td>
                <td>{item.qty.formatNumber(0)}</td>
                <td>{item.price.formatCurrency(2)}</td>
                <td>{(item.qty * item.price).formatCurrency(2)}</td>
            </tr>
        );
    })
    
    const showSaleJsonAsync = async () => {
        dialogController.open();
    };
    
    const showResendAsync = async () => {
        resendDialogController.open();
    };
    
    let cartBodyElements = (<table width={"100%"} className={"table-x-large sales-cart-items"}>
        <thead>
        <tr>
            <th>Item</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Amount</th>
        </tr>
        </thead>
        <tbody>{cartItemElements}</tbody>
    </table>);
    
    const reSendButton = (<RefreshButton onClick={showResendAsync} persist={true} icon={faShare}>
        <Tooltip arrow={true} title={topTipText}><span>{sendLabel}</span></Tooltip>
    </RefreshButton>);
    
    const virtualTerminalElement = hasCustomer ?
        (<RefreshButton persist={true} onClick={() => setRedirectUrl("/merchants/" + merchantId + "/locations/" + sale?.merchantLocationId + "/virtual-terminal/" + sale?.customerId)} icon={faTachographDigital}>Virtual Terminal</RefreshButton>) :
        null;

    const subtitle = (<span className={"panel"}>
            <BackButton />
            <span className={"sale-number"}>Sale #{sale?.saleNumber}</span>
            {customerNameElement}
            <span className={"sale-status status-" + sale?.status}>
                <label>Stauts: {sale?.statusName}</label>
            </span>
            {virtualTerminalElement}
            {reSendButton}
            <RefreshButton persist={true} onClick={showSaleJsonAsync} icon={faCode}>JSON</RefreshButton>
    </span>);
    
    const dateFormat = "MMM DAY, yyyy TIME"
    return (
        <MerchantMasterPage onMerchant={onMerchant} selection={"sales"} title={subtitle}>
            <div className={"date-activity sale"}>
                <Tooltip title={sale?.created.formatDate(dateFormat) || "..."}>
                    <span id={"user-last-login-date"}>
                        <FontAwesomeIcon icon={faArrowRightToBracket} />
                        <DateTime showRelative={true} value={sale?.created} defaultValue={"No creation date!"} prefix={"Submitted: "} />
                    </span>
                </Tooltip>
                <Tooltip title={sale?.modified.formatDate(dateFormat) || "..."}>
                    <span id={"user-modified-date"}>
                        <FontAwesomeIcon icon={faFeatherPointed} />
                        <DateTime showRelative={true} value={sale?.modified} defaultValue={"Has not been modified"} prefix={"Last Modified: "} />
                    </span>
                </Tooltip>
                <Tooltip title={"Location of Sale"}>
                    <span id={"user-sale-details-location"}>
                        <FontAwesomeIcon icon={faLocationDot} />
                        <Link to={"/merchants/" + sale?.merchantId + "/locations/" + sale?.merchantLocationId}>{sale?.location?.name || "No Cashier"}</Link>
                    </span>
                </Tooltip>
                <Tooltip title={"Cashier for this sale"}>
                    <span id={"user-sale-cashier"}>
                        <FontAwesomeIcon icon={faUserTag} />
                        <Link to={"/merchants/" + sale?.merchantId + "/users/" + sale?.userId}>{sale?.user?.name || "No Cashier"}</Link>
                    </span>
                </Tooltip>
            </div>
            
            <div className={"sale-section"}>
                <h3>Line Item{ses.lineItems}</h3>
                { cartBodyElements }
            </div>

            { paymentElement }
            
            <DialogBox controller={dialogController} hasOkayButton={false} cancelCaption={"Close"}>
                <Tooltip title={"Copy to Clipboard"}>
                <div style={{display: "inline-block", minWidth: "700px"}}>
                    <CopyText text={JSON.stringify(saleJson, null, 2)} className={"has-icon small-text"}>
                        
                        <span className={"not-copied"}>
                            Copy to Clipboard <FontAwesomeIcon icon={faCopy} />
                        </span>
                        <span className={"is-copied"}>
                            Copied to Clipboard :)
                            <FontAwesomeIcon icon={faCircleCheck} />
                        </span>
                    </CopyText>
                </div>
                </Tooltip>
                
                <pre style={{maxHeight: "400px"}} className={"code flex"}>{JSON.stringify(saleJson, null, 2)}</pre>
            </DialogBox>
            
            <DialogBox controller={resendDialogController} onOkay={resendAsync} cancelCaption={"Cancel"} okCaption={"Send"}>
                <div className={"form"} style={{minWidth: "440px"}}>
                    <label>Send to (Email or Mobile Number):</label>
                    <input ref={resendDestinationRef} type={"text"} defaultValue={sale?.customer?.email} />
                </div>
            </DialogBox>
            
        </MerchantMasterPage>        
    );
};

export default SaleDetailsScreen;
