import React, {useEffect, useState, useRef} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import CardBrandLineGraph from "../../components/reporting/ui/CardBrandLineGraph";
import ReportItemModel from "../../components/reporting/models/ReportItemModel";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS, Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import MerchantMasterPage from "./MerchantMasterPage";
import SaleReportingService from "../../components/reporting/services/SaleReportingService";
import UserBadge from "../../components/people/ui/UserBadge";

import AddNewButton from "@paylani/paylani-react-packages/dist/common/ui/AddNewButton";
import {faCreditCardAlt} from "@fortawesome/free-solid-svg-icons";

const MerchantDetailsScreen = (props) => {
    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );
    
    const merchantId = useParams()?.merchantId || props?.merchantId;
    const defaultLineGraphTitle = 'Total Transactions';

    let _;
    const stateTitles = [ defaultLineGraphTitle, 'Total Sales Volume' ];

    const initData = {
        dashboardData: MerchantService.instance.dashboardMap[merchantId] || null,
        saleReportItems: SaleReportingService.instance.salesDaily || null
    };

    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null);
    const [chartState, setChartState] = useState({ title: defaultLineGraphTitle, state: 0 });
    const [pageData, setPageData] = useState(initData);

    let dateRangeRef = useRef();

    const getStartDate = () => {
        const days = parseInt(dateRangeRef.current?.value || "-1");
        if (days <= 0) return null;

        return (new Date()).addDays(-days);
    };
    
    const getDashboardDataAsync = async (force) => {
        if (!merchantId) return null;
        const dashboardData = pageData?.dashboardData;
        
        if (!!dashboardData && !force) {
            //console.log('Got Cached Dashboard Data')
            return dashboardData;
        }

        //console.warn('Getting dashboard data');
        return await MerchantService.instance.getDashboardDataAsync(merchantId, getStartDate()).then((model) => {
            console.log('Got dashboard');
            return model;
        });
    };
    
    const onMerchant = (m) => { 
        if (!!m) {
            setMerchant(m);
        }
    }
    
    const getSalesLineGraphItemsAsync = async (force) => {
        if (!merchantId) return null;
        const saleReportItems = pageData?.saleReportItems;
        if (!!saleReportItems && saleReportItems.length > 0 && !force) return saleReportItems;

        return await MerchantService.instance.getMerchantSalesDailyAsync(merchantId, getStartDate()).then((items) => {
            if (items === null) return saleReportItems;
            
            return items;
        });
    };

    const refreshDataAsync = async (force) => {
        await Promise.all([
            getDashboardDataAsync(force),
            getSalesLineGraphItemsAsync(force)
        ]).then((values) => { 
            const data = {
                dashboardData: values[0],
                saleReportItems: values[1] 
            };
            
            setPageData(data);
        });
    };
    
    const onChangeDateRange = (e) => {
        _ = refreshDataAsync(true);
    };
    
    useEffect(() => {
        _ = refreshDataAsync(false);
    }, []);
    
    const setLineGraphState = (state) => {
        if (state === chartState.state) return;

        let newState = { title: stateTitles[state], state: state };
        setChartState(newState);
    };

    const dashboardData = pageData?.dashboardData;
    const saleReportItems = pageData?.saleReportItems;

    let controlSelections = ['', ''];
    controlSelections[chartState.state] = 'selected';

    let data = [];
    let countData = [];
    let d = new Date().addDays(-14);

    let lineData = ReportItemModel.createLineChartSeries(saleReportItems, stateTitles[0]);
    let labels = saleReportItems?.map((item) => item.label);

    let salesDataSets = [lineData];
    
    const currentChart = 0; //chartState.state
    
    let countsDataSets = [{
        label: stateTitles[currentChart],
        data: countData,
        borderColor: "green",
        borderWidth: 1,
        pointRadius: 1.5
    }];
    
    let salesLineData = {
        labels: labels, // ['VISA', 'MasterCard', 'Discover', 'Amex'],
        datasets: chartState.state === 1 ? countsDataSets : salesDataSets
    };

    let controls = (<span id="home-line-controls">
        <a className={controlSelections[0]} onClick={(e) => setLineGraphState(0)}>{ stateTitles[0] }</a>
        <a className={controlSelections[1]} onClick={(e) => setLineGraphState(1)}>{ stateTitles[1] }</a>
    </span>)
    
    const accountOwnerBadge = !!merchant?.accountOwner?.id ? (<UserBadge user={merchant.accountOwner} />) : null;
    const actions = [(<AddNewButton key={"action-0"} icon={faCreditCardAlt} to={"/merchants/" + merchantId + "/virtual-terminal"}>New Sale</AddNewButton>)];
    
    return (
        <MerchantMasterPage merchant={merchant} onMerchant={onMerchant} merchantId={merchantId} dashboardData={dashboardData} controls={controls} title={""} actions={actions}>
            
            <div className={"merchant-ownership"}>
                {accountOwnerBadge}
                <p>
                    This merchant appears as "<Link to={"/merchants/" + merchantId}>{merchant?.name}</Link>" on reporting, and is for display purposes. 
                </p>
            </div>

            <div className="dashboard-snapshot">
                <Link to={"/reporting/batches"} className="panel-box round8 rows" onClick={(e) => setLineGraphState(0)}>
                    <ul>
                        <li>Week's Sales Volume</li>
                        <li>Last Sale Was</li>
                        <li><DateTime value={dashboardData?.lastSaleDate} showRelativeDate={true} time={true} defaultValue={"Never"} /></li>
                    </ul>
                    <span className="large"><NumberDisplay value={(dashboardData?.salesVolume || 0)} type={"currency"} isComponent={true} /></span>
                </Link>

                <a className="panel-box round8 rows" onClick={(e) => setLineGraphState(1)}>
                    <ul>
                        <li>Week's Transaction Count</li>
                        <li>Last Transaction</li>
                        <li><DateTime value={dashboardData?.lastSaleDate} showRelativeDate={true} defaultValue={"Never"} /></li>
                    </ul>
                    <span className="large">{ (dashboardData?.saleCount || 0).formatNumber(0) }</span>
                </a>

                <span className={"form details-dropdown"}>
                    <div>Show Data From:</div>
                    <select defaultValue={"14"} onChange={onChangeDateRange} ref={dateRangeRef}>
                        <option value={"1"}>Today</option>
                        <option value={"7"}>Past Week</option>
                        <option value={"14"}>Past Two Weeks</option>
                        <option value={"30"}>Past Month</option>
                    </select>
                </span>

            </div>

            <CardBrandLineGraph title={chartState.title} data={salesLineData} chartControls={controls} />
        </MerchantMasterPage>
    );
    
};

export default MerchantDetailsScreen;

