import React, {useState, useEffect} from 'react';
import '../../components/reporting/ui/Reporting.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend, 
    ArcElement
} from 'chart.js';

import ReportingService from "../../components/reporting/services/ReportingService";
import {Link} from "react-router-dom";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import ReportingMasterPage from "./ReportingMasterPage";
import PieChart from "../../components/reporting/ui/PieChart";

const ReportingScreen = (props) => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );

    const defaultLineGraphTitle = 'Submissions by Card Brand';
    
    let stateTitles = [
        defaultLineGraphTitle,
        'Updates by Card Brand'
    ];

    const submissionsReportKey = 'master-submissions';
    const updatesReportKey = 'master-updates';
    const cardBrandDataReportKey = 'cardbrand-data';

    const [snapShotData, setSnapShotData] = useState(ReportingService.instance.dashboard || null);
    const [snapshotDuration, setSnapshotDuration] = useState(14);

    const startDate = new Date().addDays(-snapshotDuration);
    
    let _;
    const getSnapShotData = (force) => {
        //
    };

    const getSnapShotDataAsync = async (force) => {
        return await ReportingService.instance.getMasterDashboardDataAsync(startDate, new Date()).then((data) => {
            //console.warn(JSON.stringify(data));
            setSnapShotData(data);
        });
    };
    
    let controlSelections = ['', ''];
    //controlSelections[chartState.state] = 'selected';

    useEffect(() => {
        _ = getSnapShotDataAsync(false);
    }, [snapshotDuration]);
    
    const getTotal = () => {
        let cx = 0;
        let dx = 0;
        let sx = 0;
        const items = snapShotData?.paymentStatusCounts || [];
        const c = items.length;
        
        for(let i = 0; i < c; i++) {
            cx += items[i].item_count || 0;
            dx += items[i].amount || 0;
            if (items[i].status === 1) sx += items[i].amount || 0;
        }
        
        return { count: cx, amount: dx, success: sx };
    };
    
    const t = getTotal();
    const total = t.amount;
    const totalCount = t.count;
    const successAmount = t.success;
    const successRate = total !== 0 ? successAmount / total : 0.0;
    
    const getCardBrandRow = (index, item) => {
        const name = item.status_name;
        const percent = (total <= 0) ? "--" : ((item / total) * 100).formatNumber(1) + "%";
        
        return (<tr key={"table-" + index}>
            <td>{name}</td>
            <td>{(item.item_count || 0).formatNumber(0)}</td>
            <td>{(item.amount || 0).formatCurrency(2)}</td>
        </tr>);
    };
    
    const onLineHover = (event) => {
        let e = false;
        let c = event.target.attributes;

        return event;
    };

    const onSnapShotDateChange = (event) => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
            console.warn("No Change");
            return;
        }

        console.warn("Changing: " + value);
        setSnapshotDuration(value);
    };
    
    const paymentTypeColors = [
        "#FF000055",
        "#0000AA55",
        "#DDD00055",
        "#00820055"
    ];
    
    const getSnapshotItems = () => {
        const defaultValue = snapShotData?.paymentStatusCounts.filter((item) => item.item_count > 0)?.length > 0 ? 0 : 1;
        
        return snapShotData?.paymentStatusCounts?.map((item, i) => {
            console.log("Name: " + item.status_name);
            console.log("Value: " + item.item_count);
            
            return {
                key: "payment-type-" + item.status,
                value: item.item_count || defaultValue,
                color: paymentTypeColors[i % paymentTypeColors.length],
                name: item.status_name
            };
        }) || [];
    }

    const cardBrandTable = Array.isArray(snapShotData?.paymentStatusCounts) ? snapShotData.paymentStatusCounts.map((item, i) => getCardBrandRow(i, item)) : null;
    const pieKey = "submissions";
    const updateRate = 0.0; // (typeof snapShotData.totals?.submissions === 'number') ? (snapShotData.totals.updates / snapShotData.totals.submissions) : 0.0;

    const s = snapshotDuration === 1 ? "" : "s";
    const pastDisplay = (<>past {snapshotDuration} day{s}</>);
    
    return (
        <ReportingMasterPage title={"Snapshot"}>
            <div className="right form horizontal">
                <span className="label-small medium">
                    Show Data for:
                </span>
                <select value={snapshotDuration} onChange={onSnapShotDateChange.bind(this)} className="shrinkwrap trend">
                    <option value="3">3 Days</option>
                    <option value="7">1 Week</option>
                    <option value="14">2 Weeks</option>
                    <option value="30">Month</option>
                </select>
            </div>

            <p>
                Reporting Snapshot displays information based on the <strong>{pastDisplay}</strong>.
                See more robust transaction history in the <Link to="/reporting/card-brands">Transaction Reports</Link>.
            </p>

            <div id="reporting-snapshot">
                <div id="snapshot-list">
                    <table className="table-small" id="card-brand-list">
                        <thead>
                        <tr>
                            <th>Card Brand</th>
                            <th>Transactions</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cardBrandTable}
                        </tbody>
                    </table>
                </div>

                <div className="pies">
                    <PieChart title={"Title Here"} items={getSnapshotItems()} width={240} height={240} />
                    <div className="dark round24">
                        <div className="panel" style={{width: '240px', height: '240px'}} id="snap-shot-percent">
                            <div>Success Rate</div>
                            <h1><NumberDisplay value={successRate} isComponent={true} type={"percent"} decimalPlaces={1} /></h1>
                            <div className="small">Percentage of successful transactions { pastDisplay }</div>
                        </div>
                    </div>
                </div>

            </div>

        </ReportingMasterPage>
    );
};

export default ReportingScreen;
