import React, {useRef} from 'react';
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";

const ConfigInput = (props) => {
    const { type, location, config, value, onClick, onComplete } = props;
    const [mode, setMode] = React.useState(0);

    const inputRef = useRef();
    
    const onInputChange = (e) => {
        //
    };
    
    const onSubmitAsync = async (e) => {
        if (typeof onClick !== 'function') return null;
        
        const val = inputRef.current?.value;
        const rsp = onClick(val);
        
        if (typeof rsp.then === 'function') { 
            return await rsp.then((rsp) => { 
                return rsp;
            });
        } else return rsp;
    };
    
    const elementType = type || 'text';
    
    return (<span className={"form config-input"}>
        <span><input defaultValue={value} type={elementType} onChange={onInputChange} ref={inputRef} /></span>
        <span><FormButton onClick={onSubmitAsync}>Update</FormButton></span>
    </span>);
};

export default ConfigInput;
