import React, {useEffect, useRef, useState} from 'react';
import ReportingMasterPage from "./ReportingMasterPage";
import DateRangeSelector from "@paylani/paylani-react-packages/dist/common/ui/date-range-selector/DateRangeSelector";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import SaleReportingService from "../../components/reporting/services/SaleReportingService";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";

import LoadingBox from "../../components/common/ui/LoadingBox";
import {Link} from "react-router-dom";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import SaleService from "../../components/sales/services/SaleService";
import EmptyBox from "../../components/common/ui/EmptyBox";

const SalesReportScreen = (props) => {
    const [dateRange, setDateRange] = useState({startDate: new Date().addMonths(-1).toFirstOfTheMonth(), endDate: new Date()});

    let [saleReportItems, setSaleReportItems] = useState(SaleReportingService.instance.salesDaily || null);
    let [filterText, setFilterText] = useState('');

    let [currentPage, setCurrentPage] = useState(0);
    let [user, setUser] = useState(AuthenticationService.instance.session?.user || null);

    let pageController = useRef(new PagerController(setCurrentPage, 16)).current;
    let _;

    const getSalesAsync = async (force) => {
        if (!!saleReportItems && saleReportItems.length > 0 && !force) return saleReportItems;

        await SaleService.instance.getSalesAsync(dateRange?.startDate, dateRange?.endDate).then((ss) => {
            if (ss === null) return;
            setSaleReportItems(ss);
        });
    };
    
    const refreshAsync = async (sd, ed) => {
        setDateRange({startDate: sd, endDate: ed});
    };
    
    useEffect(() => {
        if (saleReportItems === null) { 
            _ = getSalesAsync(true);
        }
    }, [saleReportItems]);

    useEffect(() => {
        setSaleReportItems(null);
    }, [dateRange]);

    useEffect(() => {
        _ = getSalesAsync();
    }, []);
    
    const cardColumnStyle = {width: '120px'};
    const salesList = saleReportItems; //filterResults(recentSales);
    const salesElements = saleReportItems === null ? (<LoadingBox />) : pageController.mapLineItems(salesList, (item, ii) => {

        return (
            <tr key={"recent-sale-" + ii}>
                <td style={cardColumnStyle}>
                    <Link to={"/merchants/" + item.merchantId + "/sales/" + item.id}>#{item.saleNumber}</Link>
                </td>
                <td>{ item.total?.formatCurrency(2) }</td>
                <td>
                    <Link title={"Merchant: " + item.merchant?.name} to={"/merchants/" + item.merchantId + "/locations/" + item.merchantLocationId + "/sales"}>
                        { item.location?.name || "No Name"}
                    </Link>
                </td>
                <td><DateTime value={item.created} time={true} on /></td>
            </tr>);
    });
    
    const reportElement = saleReportItems !== null ? (<table className="table-x-large" width="100%">
        <thead>
        <tr>
            <th style={cardColumnStyle}>Sale #</th>
            <th>Amount</th>
            <th>Origination</th>
            <th>Date</th>
        </tr>
        </thead>

        <tbody>
        {salesElements}
        </tbody>
    </table>) : salesElements;
    
    const emptyBox = saleReportItems?.length === 0 ? (<EmptyBox>No sales to report</EmptyBox>) : null;
    
    return (
        <ReportingMasterPage selection="sales" title={"Sales Reports"}>
            <p>
                Sales for all merchants and their respective locations.
            </p>

            <div className={"form"}>
                <DateRangeSelector startDate={dateRange.startDate} endDate={dateRange.endDate} onClick={refreshAsync} />

                { reportElement }
                {emptyBox}
                <Pager controller={pageController} items={salesList} />

            </div>
        </ReportingMasterPage>
    );
};

export default SalesReportScreen;


