import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import DashboardDataModel from "../models/DashboardDataModel";
import ReportItemModel from "../models/ReportItemModel";

class ReportingService { 
    static instance = new ReportingService();
    
    constructor() {
        this.reportMap = {};
        this.partnerCardBrandMap = {};
        this.dashboard = null;
        this.dashboardDate = null;
        
        this.isGetting = {};
    }
    
    async getMasterDashboardDataAsync(startDate, endDate) {
        const keyGet = "master-dashboard-sales";
        if (!!this.isGetting[keyGet]) return null;
        
        const me = this;
        const path = '/api/report';

        this.isGetting[keyGet] = true;
        
        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            delete me.isGetting[keyGet];
            
            let model = new DashboardDataModel(rsp?.data);
            me.dashboard = model;

            return model;
        }).catch((err) => {
            delete me.isGetting[keyGet];
            throw err;
        });
    };

    
}

export default ReportingService;
