import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBridgeCircleCheck, faCartShopping, faChartArea, faChartLine,
    faCircle, faCreditCard,
    faDiagramProject, faEye, faFloppyDisk,
    faGears,
    faKey, faMagnifyingGlassChart, faRightLeft,
    faRobot,
    faSuitcase
} from "@fortawesome/free-solid-svg-icons";

const ReportingSubMenu = (props) => {
    let selections = { products: "" };
    let selector = props.selection || 'overview';

    selections[selector] = 'selected';

    const w = 24;
    const h = 16;
    
    const trends = null; // (<li className={selections['trends']}><Link to={ '/reporting/trends'}><FontAwesomeIcon icon={faChartLine} /> Trends</Link></li>)
    
    return (
        <div className="sub-column" id="reporting-sub-menu">
            <ul>
                <li className="sub-menu-header">
                    More
                </li>
                <li className={selections['overview']}><Link to={ '/reporting'}><FontAwesomeIcon icon={faEye} /> Snapshot</Link></li>
                <li className={selections['sales']}><Link to={ '/reporting/sales'}><FontAwesomeIcon icon={faCreditCard} /> Sales</Link></li>
                <li className={selections['transactions']}><Link to={ '/reporting/transactions'}><FontAwesomeIcon icon={faRightLeft} /> Transactions</Link></li>
                <li className={selections['products']}><Link to={ '/reporting/products'}><FontAwesomeIcon icon={faCartShopping} /> Products</Link></li>
                { trends }
            </ul>
        </div>
    );

};

export default ReportingSubMenu;
