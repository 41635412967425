import React, { useState } from 'react';
import '../../sales/ui/Sales.css';
import {Tooltip} from "@mui/material";
import MasterCard from "./CreditCards/MasterCard";
import AmericanExpressCard from "./CreditCards/AmericanExpressCard";
import VisaCard from "./CreditCards/VisaCard";
import DiscoverCard from "./CreditCards/DiscoverCard";

const PaymentDetailsLineItem = (props) => {
    const { payment } = props;
    let [logClass, setLogClass] = useState(" hidden");
    
    if (!payment) return (<></>);
    
    let posData = payment.posTransactionData;
    let last4 = payment.last4 || posData?.BogusAccountNum;
    let statusElement =  (<span className="error">Failed: <strong>{payment.message}</strong></span>);
    let authCode = payment.authCode || (posData?.AuthCode || "N/A");
    
    let messageElement = null;
    let creditCardElement = null;
    
    let paymentProvider = payment.paymentProviderType;
    let paymentProviderName = null;
    let signatureUrl = payment.signatureImageUrl;
    
    //console.log(JSON.stringify(payment.posTransactionData));
    let authorizationElements = null;
    let transactionId = payment.posTransactionId;
    let transIdLabel = "POS TransactionId:";
    
    if (!transactionId) { 
        transactionId = ((payment.transactionId || payment.posTransactionId) || (posData?.PaymentTransInfo?.GlobalUid || posData?.GatewayTransactionID) || "").toString();
        transIdLabel = transactionId.length > 16 ? "TID: " : "TransactionId: "
    }

    const getCardElement = (cardType) => {
        switch(cardType) {
            case 4:
                return (<VisaCard />);
            case 3:
                return (<AmericanExpressCard />);
            case 5:
                return (<MasterCard />);
            case 6:
                return (<DiscoverCard textColor={"#444"} />);
            default:
                return null;
            
        }
    };
    
    if (payment.cardType > 1) {
        let stars = payment.cardType === 3 ? "**** ****** *" : "**** **** **** ";
        
        creditCardElement = (
            <span className="credit-card">
                {getCardElement(payment.cardType)}
                <div>{stars + last4}</div>
            </span>
        );
    }
    
    if (payment.success) {
        statusElement = (<>Status: { payment.paymentStatusName }</>)
        authorizationElements = (<li>AuthCode: {authCode}</li>);
        messageElement = ((posData?.Message || "") + " " + (payment.message || "")).trim();
    }
    
    let logElement = null;
    let logToggle = null;
    
    const toggleLog = () => {
        let cn = logClass;
        if (!cn) cn = " hidden";
        else cn = "";
        
        setLogClass(cn);
    };
    
    if (!!posData) { 
        logElement = (<div className={"payment-log" + logClass}><pre>{JSON.stringify(posData, null, 2)}</pre></div>);
        if (!logClass) logElement = (<Tooltip title={"Data returned from the PAX Device"}>{logElement}</Tooltip>);
        
        logToggle = (<li><a href="#" onClick={(e) => toggleLog()}>{ !logClass ? "Hide Logged Data" : "View Logged Data" }</a></li>);
    }
    
    return (
        <div className={"payment-line-item"}>
            <div className={"payment-item payment-item-" + payment.cardType + " success-" + payment.success.toString()}>
            <div>
                <div className={"payment-type"}>
                    <div>
                        {creditCardElement}
                    </div>
                </div>
            </div>
            <div className={"info"}>
                <ul>
                    <li className={"payment-amount"}>{ payment.amount.formatCurrency(2) }</li>
                    <li>{statusElement}</li>
                    <li className={"message"}>{messageElement}</li>
                </ul>
            </div>
            <div className={"auth-info"}>
                <ul>
                    <li><strong>AuthCode:</strong> {authCode}</li>
                    <li><strong>{transIdLabel}</strong> {transactionId}</li>
                    {logToggle}
                </ul>
            </div>
        </div>
        {logElement}
    </div>);
};

export default PaymentDetailsLineItem;
