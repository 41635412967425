class ConfigurationValueOptionModel { 
    constructor(value, text) { 
        if (!value) value = null;
        if (!text) text = value;
    }
    
    static getTypeOf(valueOptions) {
        if (typeof valueOptions === 'undefined' || valueOptions === null)
            return 'boolean';
        
        if (valueOptions === "0.00") return "number";
        if (typeof valueOptions === 'string' && valueOptions.indexOf("|") >= 0) return "list";
        
        return "boolean";
    }
}

export default ConfigurationValueOptionModel;
