import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";

import LocationMasterPage from "./LocationMasterPage";
import DialogBox from "../../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../../components/common/ui/dialog-box/DialogBoxController";
import SalesFieldForm from "../../../components/merchants/ui/SalesFieldForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import AddNewButton from "@paylani/paylani-react-packages/dist/common/ui/AddNewButton";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import ActivityLink from "@paylani/paylani-react-packages/dist/common/ui/ActivityLink";

const LocationCustomFieldsScreen = (props) => {
    const {merchantId, locationId} = useParams();

    let _;
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    let [fields, setFields] = useState(null);
    const [selectedFieldId, setSelectedFieldId] = useState("");
    
    const dialogController = useRef(new DialogBoxController("Edit Sales Field")).current;
    const jsonValueRef = useRef({}).current;
    
    const onMerchant = (m) => {
        setMerchant(m);
    };

    const onFieldChange = (json) => {
        jsonValueRef.value = json;
        //console.log(JSON.stringify(json));
    }

    const createSalesFieldForm = (field) => {
        return (<SalesFieldForm onChange={onFieldChange} locationId={locationId} field={field} />);
    };

    const onLocation = (l) => {
        //
    };

    const getFieldsAsync = async (force) => {
        if (!force && !!fields && fields.length > 0) return;
        
        await MerchantService.instance.getMerchantFieldsByLocationAsync(locationId).then((fs) => {
            setFields(fs);
        });
    };

    const onFieldClick = (field) => {
        dialogController.setBody(createSalesFieldForm(field)).then((b) => {
            setSelectedFieldId(field?.id);
            dialogController.open();
        });
    };

    const updateFieldAsync = async () => {
        const json = jsonValueRef.value;
        const id = json?.id;
        
        delete json.id;
        
        if (!!id && id.length > 30) await MerchantService.instance.updateSalesFieldAsync(id, json);
        else await MerchantService.instance.createSalesFieldAsync(locationId, json);
        console.log(JSON.stringify(jsonValueRef.value));
        
        await getFieldsAsync(true);
        
        return 1500;
    };
    
    useEffect(() => {
        //
    }, [selectedFieldId]);
    
    useEffect(() => {
        _ = getFieldsAsync();
    }, []);

    const elements = fields?.map((f, i) => {
        const yesElement = (<FontAwesomeIcon icon={faCheckCircle} className={"yes"} />);
        return (<tr className={"merchant-field-item"} key={"field-key-" + i}>
            <td><a onClick={(e) => onFieldClick(f)}>{f.label}</a></td>
            <td>{f.fieldTypeName}</td>
            <td>{f.defaultValue || "*none*"}</td>
            <td>{f.placeholder || "*none*"}</td>
            <td>{f.isRequired ? yesElement : " "}</td>
            <td>{f.isActive ? yesElement : " "}</td>
        </tr>);
    });
    
    const deleteFieldAsync = async () => {
        if (!selectedFieldId || selectedFieldId.length < 30) {
            console.error("Nothing to delete: " + selectedFieldId);
            return;
        }
        
        console.warn("Deleting: " + selectedFieldId);
        const rsp = await MerchantService.instance.deleteSalesFieldAsync(selectedFieldId);
        await getFieldsAsync(true);
        
        dialogController.close();
        
        return true;
    };
    
    const actions = !!selectedFieldId && selectedFieldId.length > 0 ? (<ActivityLink onClick={deleteFieldAsync} type={"delete"}>Delete</ActivityLink>) : null;
    
    return (
        <LocationMasterPage onMerchant={onMerchant} onLocation={onLocation} title={"Custom Sales Fields"} selection={"fields"}>
            <p>
                Sales fields are custom fields that can be added to the sales form. These fields can be used to collect additional information from your customers prior to checkout
                <DateTime value={new Date()} />
            </p>
            
            <div className={"sales-fields"}>
                <h3 className={"flex"}>
                    Sales Fields
                    <AddNewButton onClick={() => onFieldClick(null)} />
                </h3>
                
                <table className={"table-x-large merchant-fields"} width={"100%"}>
                    <thead>
                        <tr>
                            <th>Label</th>
                            <th>Type</th>
                            <th>Default Value</th>
                            <th>Placeholder</th>
                            <th>Required</th>
                            <th>Active</th>
                        </tr>
                    </thead>
                    <tbody>
                    { elements }
                    </tbody>
                </table>
            </div>
            
            <DialogBox title={"Add Sales Field"} controller={dialogController} onOkay={updateFieldAsync} actions={actions} onClose={() => setSelectedFieldId(null)}>
                <div>Toasting...</div>
            </DialogBox>
        </LocationMasterPage>
    );
    

};

export default LocationCustomFieldsScreen;
