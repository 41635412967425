import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAnchorCircleCheck,
    faAnchorCircleExclamation,
    faCheckCircle, faExclamation,
    faSyncAlt, faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";

const RefreshButton = (props) => {
    let { icon, persist, anchor, completedIcon, workingIcon, errorIcon, caption, workingCaption, onComplete, onClick, onError, transitionDelay } = props;
    let [isPosting, setIsPosting] = useState(0);

    let refreshIcon = (<FontAwesomeIcon icon={icon || faSyncAlt} />);
    let refreshCaption = caption || (props.children || "Refresh");

    if (!transitionDelay || typeof transitionDelay !== 'number') transitionDelay = 3500;
    if (typeof onClick !== 'function' || onClick === null) onClick = () => console.warn('Refresh Button has no onClick handler');
    if (typeof onError !== 'function' || onError === null) onError = (ex) => console.warn('Default Refresh Button Error: ' + ex.toString());
    
    const onRefreshCompleted = (response) => {
        if (typeof onComplete !== 'function') {
            setIsPosting(0);
            return;
        }

        onComplete(response);

        setIsPosting(2);
        setTimeout(() => {
            setIsPosting(0);
        }, transitionDelay);
    };
    
    const onRefreshError = (error) => {
        onError(error);
        setIsPosting(-1);

        setTimeout(() => {
            setIsPosting(0);
        }, transitionDelay);
    };
    
    const onRefresh = async (e) => {
        if (typeof onClick !== 'function') return;
        
        setIsPosting(1);
        
        const rsp = onClick(e);
        const result = (typeof rsp?.then === "function") ?
            await rsp.catch((ex) => onRefreshError(ex)) :
            rsp;
        
        if (result !== false) onRefreshCompleted(result);
    };
    
    if (!errorIcon) errorIcon = faTriangleExclamation;
    if (!completedIcon) completedIcon = faCheckCircle;
    
    let spanClassName = ' visible';
    
    switch(isPosting) {
        case -1:
            refreshIcon = (<FontAwesomeIcon icon={errorIcon} className="failed" />);
            refreshCaption = 'Error';
            break;
        case 1:
            refreshIcon = (<span style={{marginRight: '8px', marginTop: '3px'}}><label className="spinner spinning"></label></span>);
            refreshCaption = workingCaption || 'Working';
            break;
        case 2:
            refreshIcon = (<FontAwesomeIcon icon={completedIcon} className="success" />);
            refreshCaption = 'Success';
            break;
        default:
            spanClassName = '';
            break;
    }
    
    const persistClassName = (persist === true || anchor === true) ? " anchor" : "";
    
    return (<span className={"refresh-button" + persistClassName + spanClassName}>
            <a onClick={onRefresh}>
                {refreshIcon}
                {refreshCaption}
            </a>
        </span>);
};

export default RefreshButton;
