import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faSitemap} from "@fortawesome/free-solid-svg-icons";
import MerchantService from "../services/MerchantService";
import UserService from "../../people/services/UserService";
import MerchantEffect from "../services/MerchantEffect";

const MultiMerchantSelector = (props) => {
    const { user, onClose, isOpen, controller } = props;
    let _;
    let [merchants, setMerchants] = useState(MerchantService.instance.merchants || null);
    let [merchantSelections, setMerchantSelections] = useState(user?.accessibleMerchants || []);
    let [merchantListVisible, setMerchantListVisible] = useState(isOpen);
    let [userMerchant, setUserMerchant] = useState(MerchantService.instance.merchantMap[user?.merchantId || ""] || null);
    let [searchTerm, setSearchTerm] = useState('');

    if (!!controller) controller.close = (options, e) => {
        if (!merchantListVisible) {
            return;
        }

        if (e.target.id === "search-merchants") return;
        if (e.target.className === "no-close") return;

        if (e.target.tagName === 'path' || (e.target.id && e.target.id.indexOf('notification') >= 0)) {
            return;
        }

        UserService.instance.updateMerchantAccessAsync(user.id, merchantSelections).then((accessList) => {
            setMerchantSelections(accessList);
        }).catch((ex) => {
            //
        });
        
        setMerchantListVisible(false);
    };

    const filterLocations = (e) => {
        const term = e.target?.value || "";
        if (term.length > 0 && term.length < 3) return;

        setSearchTerm(term);
    };
   
    const onRemoveMerchantAccess = (merchant, e) => {
        //
    };

    const onInputFocus = (isFocused, e) => {
        if (isFocused === false) {
            setMerchantListVisible(false);
            return;
        }

        setMerchantListVisible(isFocused);
    }

    const selectMerchantAccess = (m, e) => {
        let index = -1;

        merchantSelections.filter((mm, idx) => {
            if (mm.id === m.id) index = idx;
            return true;
        });

        if (index > -1) {
            let x = merchantSelections.filter((_, idx) => idx !== index);
            setMerchantSelections(x);
            return;
        }

        setMerchantSelections(merchantSelections.concat([m]));
    };

    const getMerchantsAsync = async (force, e) => {
        if (!!merchants && merchants.length > 0 && !force) return [];
        await MerchantService.instance.getMerchantsAsync(force).then((ms) => {
            setMerchants(ms);
        });
    };

    const getUserMerchantAsync = async (force, e) => {
        if (!user?.merchantId) return userMerchant;
        if (!!userMerchant && !force) return userMerchant;

        await MerchantService.instance.getMerchantAsync(user?.merchantId || "").then((m) => {
            setUserMerchant(m);
        });
    };

    useEffect(() => {
        if (user) {
            _ = getUserMerchantAsync();
            setMerchantSelections(user.accessibleMerchants || []);
        }
    }, [user]);

    useEffect(() => {
        _ = getMerchantsAsync();
    }, []);

    
    let merchantSelectionMenuElements = null;

    if (merchantListVisible) {
        const matchesSearchTerm = (m) => {
            const term = (searchTerm || "").toLowerCase();
            if (term.length < 3) return true;

            const name = m.name.toLowerCase();
            return (name.indexOf(term) >= 0);
        };
        
        const merchantMap = merchantSelections?.reduce((map, obj) => {
            map[obj.id] = obj;
            return map;
        }, {});

        const searchMerchants = (merchants ?? []).sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        merchantSelectionMenuElements = searchMerchants?.filter((x) => matchesSearchTerm(x))?.map((m, index) => {
            let cn = !!merchantMap[m.id] ? "selected" : "";

            return (<li key={"merchant-selection-" + m.id} className={"no-close " + cn}>
                <a className={"no-close"} onClick={selectMerchantAccess.bind(this, m)}>
                    <span className={"no-close"}>{m.name}</span>
                    <span className={"no-close"}><FontAwesomeIcon className={"no-close"} icon={faCheckCircle} /></span>
                </a>
            </li>);
        }) || null;

        merchantSelectionMenuElements = (<div id={"merchant-search-results-container"}>
            <div id={"merchant-search-results"}>
                <ul id={"merchant-search-results-list"}>
                    { merchantSelectionMenuElements }
                </ul>
            </div>
        </div>);
    }
    
    const merchantItems = merchantSelections?.map((m, index) => {
        return (<a className={'merchant-item'} key={m.id} id={"merchant-item-" + m.id} onClick={onRemoveMerchantAccess.bind(this, m)}>
            <FontAwesomeIcon icon={faCheckCircle} />
            {m.name}
        </a>);
    });

    return (
        <div>
            <h4><FontAwesomeIcon icon={faSitemap} /> Multi-Merchant Access</h4>
            <p>Grant access to other merchant's reporting data. In the future, the level of access may expand to allow general management</p>
            <div className="buttons multi">
                {merchantItems}
            </div>
        
            <div id={"merchant-search-container"} className={"form"}>
                <input
                    type={"text"}
                    id={"search-merchants"}
                    placeholder={"Search Merchants to Add..."}
                    style={{marginBottom: "0"}}
                    onChange={filterLocations}
                    autoComplete={"off"}
                    onFocus={onInputFocus.bind(this, true)}
                />
        
                { merchantSelectionMenuElements}
            </div>
        </div>
    );
};

export default MultiMerchantSelector;

