import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import ProductModel from "../models/ProductModel";
import ProductCatalogModel from "../models/ProductCatalogModel";

class ProductService { 
    static instance = new ProductService();

    constructor() {
        this.productsMap = {};
        this.products = [];
        this.productMap = {};
        
        this.catalogMap = {};
        this.catalogsMap = {};
    }

    async getProductAsync(productId) {
        const path = '/api/product/' + productId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.productMap[productId] = new ProductModel(response.data);
            return me.productMap[productId];
        });
    }

    async getProductCatalogsAsync(merchantId) {
        const path = "/api/merchant/" + merchantId + "/product-catalog";
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => {
            return ProductCatalogModel.fromJsonArray(response.data);
        });
    }
    
    async getProductCatalogAsync(catalogId) {
        const path = "/api/product-catalog/" + catalogId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const pc = new ProductCatalogModel(response.data);
            me.catalogMap[catalogId] = pc;
            return pc;
        });
    }
    
    async deleteProductAsync(productId) {
        const path = "/api/product/" + productId;
        return await HttpService.instance.deleteAsync(path).then((response) => response.data);
    }

    async removeImageAsync(productId) {
        const path = "/api/product/" + productId + "/image";
        return await HttpService.instance.deleteAsync(path).then((response) => new ProductModel(response.data));
    }
    
    async updateProductCatalogAsync(data, catalogId = null) {
        const path = "/api/product-catalog" + ((!!catalogId && catalogId.length > 30) ? "/" + catalogId : "");
        const me = this;

        delete data.id;

        return await HttpService.instance.postAsync(path, data).then((response) => {
            const c = new ProductCatalogModel(response.data);
            if (!!c?.id) {
                me.catalogMap[c.id] = c;
                return c;
            }
            
            return null;
        });
    }

    async deleteProductCatalogAsync(catalogId) {
        const path = "/api/product-catalog/" + catalogId;
        const me = this;

        return await HttpService.instance.deleteAsync(path).then((response) => {
            return response?.data;
        });
    }
    
    async updateProductAsync(data, productId = null) {
        const path = (!!productId && productId.length > 30) ? "/api/product/" + productId : "/api/product";
        const me = this;
        
        console.warn(path);
        console.warn(JSON.stringify(data, null, 4));
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            const p = new ProductModel(response.data);
            if (!!p?.id) {
                me.productMap[p.id] = p;
                return p;
            }
            
            return null;
        });
    }
    
    async uploadImageAsync(productId, file) {
        if (!productId) { 
            console.error("No product id for upload");
            return;
        }
        const path = "/api/product/" + productId + "/image";
        const me = this;
        
        return await HttpService.instance.uploadAsync(path, file).then((response) => {
            const p = new ProductModel(response.data);
            if (!!p?.id) {
                me.productMap[p.id] = p;
                return p;
            }
            
            return null;
        });
    }
}

export default ProductService;
