import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantModel from "../../components/merchants/models/MerchantModel";
import ProductService from "../../components/products/services/ProductService";
import DialogBoxController from "../../components/common/ui/dialog-box/DialogBoxController";
import MerchantMasterPage from "./MerchantMasterPage";
import ProductList from "../../components/products/ui/ProductList";
import RefreshButton from "../../components/common/ui/RefreshButton";
import BackButton from "@paylani/paylani-react-packages/dist/common/ui/BackButton";

const MerchantCatalogProducts = (props) => {
    const {merchantId, catalogId} = useParams();
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    const [catalog, setCatalog] = useState(ProductService.instance.catalogMap[catalogId]);
    const [dialogController, setDialogController] = useState(new DialogBoxController());
    let _;

    const onMerchant = (m) => {
        if (!!m) setMerchant(m);
    }

    const onCatalog = (c) => {
        if (!!c) setCatalog(c);
    }

    useEffect(() => {
        //
    }, []);

    const locationTreeElement = !!catalog?.id ? (<><Link to={"/merchants/" + merchantId + "/catalogs"}><span className={"subtitle"}>Product Catalogs</span></Link>
        <span className={"bullet"}>&#10095;&#10095;</span></>) : null;
    
    const subtitle = (<>
        <BackButton />
        <Link to={"/merchants/" + merchantId + "/locations"} className={"subtitle"}>{ merchant?.name }</Link>
            <span className={"bullet"}>&#10095;&#10095;</span>
            { locationTreeElement }
            <span className="subtitle">{catalog?.name} Producs</span>
        </>);
    
    return (
        <MerchantMasterPage title={subtitle} selection={"catalogs"} onMerchant={onMerchant}>
            <p>
                All products for { catalog?.name }.
            </p>

            <ProductList catalogId={catalogId} onCatalog={onCatalog} />

        </MerchantMasterPage>
    );
};

export default MerchantCatalogProducts;
