import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantModel from "../../../components/merchants/models/MerchantModel";
import LocationMasterPage from "./LocationMasterPage";
import MerchantLocationService from "../../../components/merchants/services/MerchantLocationService";
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";

const LocationTipsScreen = (props) => {
    const {merchantId, locationId} = useParams();

    let _;
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    const [config, setConfig] = useState(null);
    const [tipValues, setTipValues] = useState({ tips: [], tipType: null});
    
    const location = merchant?.locations[0];
    
    const onMerchant = (m) => {
        setMerchant(m);
    };

    const getTipsAsync = async (force) => {
        if (!force && config?.tips?.length > 0) return config;
        
        return await MerchantLocationService.instance.getTipConfigurationAsync(locationId).then((tipConfig) => {
            setConfig(tipConfig);
            return tipConfig;
        });
    };
    
    const onLocation = (l) => {
        //
    };
    
    const selectTipType = (tipType) => {
        const tv = { ...tipValues, tipType: tipType };
        setTipValues(tv);
    };

    const createTipValues = (tips, minCount = 4, maxCount = 8) => {
        if (!tips) tips = tipValues?.tips || [];
        
        while(tips.length + 1 < minCount) {
            tips.push(0);
        }
        
        if (tips.length >= maxCount) return tips;
        
        tips.push(0);
        
        return tips;
    };
    
    const removeAt = (val) => {
        if (!tipValues.tips || tipValues.tips.length <= 1) return;
        
        console.log("Remove: " + val);
        let newTips = tipValues.tips.filter((t) => t !== val);
        
        setTipValues({ ...tipValues, tips: newTips });
    }
    
    const onTipChange = (index, e) => {
        let newTips = tipValues.tips;
        newTips[index] = e.target.value;
        setTipValues({ ...tipValues, tips: newTips });
    }
    
    const updateTipsAsync = async () => {
        const tv = (tipValues.tipType === "percent") ? tipValues.tips.map((t) => (t >= 1.0 ? t / 100 : t)) : tipValues.tips;
        
        const data = {
            tips: tv.filter((x) => x > 0),
            tip_type: tipValues.tipType
        };
        
        return await MerchantLocationService.instance.updateTipConfigurationAsync(locationId, data).then((tipConfig) => {
            setConfig(tipConfig);
        }).catch((ex) => {
            //
        });
    };
    
    const onSetTip = (index, e) => {
        const amount = parseFloat(e.target?.value + "");
        
        if (isNaN(amount)) return;
        console.log(amount);
        
        let newTips = tipValues.tips;
        newTips[index] = amount;

        const data = {
            tips: createTipValues(newTips),
            tipType: tipValues.tipType
        };

        setTipValues(data);
    };
    
    useEffect(() => {
        if (!!config) { 
            const tps = createTipValues(config.tips?.map((t) => t.percentage) || []);
            
            const data = config?.config?.value ? {
                tips: tps,
                tipType: config.config.value
            } : { tips: tps, tipType: "" };
            
            setTipValues(data);
        } else {
            console.warn("No Tip Values");
        }
        
    }, [config]);

    useEffect(() => {
        _ = getTipsAsync(false);
    }, []);

    const tipType = tipValues.tipType;
    let selections = {};
    
    selections[tipType || "none"] = "selected";
    
    const symbolElement = tipType === "dollars" ?
        (<span className="symbol dollars">$</span>) :
        (<span className="symbol percent">%</span>);
    
    const tipElements = tipValues?.tips?.map((tip, i) => {
        const removeElement = tip > 0 ? (<a className={"remove"} onClick={() => removeAt(tip)}>Remove</a>) : null;
        
        return (<li key={"tip-" + i}>
            <span>
                <input type={"number"} value={tip || ""} onChange={onTipChange.bind(this, i)} onBlur={onSetTip.bind(this, i)} />
                { symbolElement }
            </span>
            <span>{ removeElement }</span>
        </li>)
    }) || null;
    
    return (
        <LocationMasterPage onMerchant={onMerchant} onLocation={onLocation} title={"Tipping Amounts"} selection={"tips"}>
            <p>
                This merchant location appears as "{location?.name}" on reporting, and is for display purposes. The parent merchant is <Link to={"/merchants/" + merchantId}>{merchant?.name}</Link>.
            </p>

            <div className={"form merchant-tips " + (tipType || "none")}>
                <div className={"tip-type-selector"}>
                    <a onClick={() => selectTipType("dollars")} className={selections["dollars"]}>Dollars</a>
                    <a onClick={() => selectTipType("percent")} className={selections["percent"]}>Percent</a>
                    <a onClick={() => selectTipType(null)} className={selections["none"]}>None</a>
                </div>
                
                <ul className={"tips"}>{ tipElements }</ul>
                
                <div className={"button"}>
                    <FormButton onClick={updateTipsAsync}>Update Tips</FormButton>
                </div>
            </div>
            
        </LocationMasterPage>        
    );
    
};

export default LocationTipsScreen;
