class PermissionModel {
    static transactionSaleKey = 1;
    static transactionAuthOnlyKey = 2;
    static transactionVoidKey = 3;
    static transactionRefundKey = 4;
    static transactionCreditKey = 5;
    static catalogEditItemKey = 6;
    static catalogDeleteItemKey = 7;
    static reportingTransactionsKey = 8;
    static customerViewKey = 9;
    static customerEditKey = 10;
    static customerCreateKey = 19;
    static customerDeleteKey = 11;
    static companyEditKey = 12;
    static companyDesignKey = 13;
    static companyMessagingKey = 14;
    static companyFeeEditKey = 15;
    static accountsEditKey = 16;
    static accountsDeleteKey = 17;
    static viewUserInformationKey = 18;
    static masterActionsKey = 20;
    static cashSaleKey = 21;
    static assignLocationsKey = 23;
    static editCategoriesKey = 24;
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.name = json.name;
        this.description = json.description;
        this.key = json.key;
        
        this.created = json.created;
        this.modified = json.modified;
    }
    
    toJson() {
        return {
            name: this.name,
        description: this.description,
        key: this.key,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => new PermissionModel(json));
    }
}

export default PermissionModel;
