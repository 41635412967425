import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard, 
    faCheckCircle, 
    faCommentDots,
    faEnvelope, faFaceDizzy,
    faPaperPlane,
    faPersonFallingBurst,
    faPersonWalking, faRightFromBracket, faSkull
    
} from "@fortawesome/free-solid-svg-icons";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import PhoneNumber from "@paylani/paylani-react-packages/dist/common/formatting/PhoneNumber";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantSubMenu from "../../components/merchants/ui/MerchantSubMenu";
import MerchantEffect from "../../components/merchants/services/MerchantEffect";
import UserMasterPage from "./UserMasterPage";
import MultiLocationSelector from "../../components/merchants/ui/MultiLocationSelector";
import Controller from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

const UserAccessScreen = (props) => {
    const { userId, merchantId } = useParams();
    
    let [user, setUser] = useState(UserService.instance.userMap[userId] || new UserModel());
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId || ''] || null);
    let [userMerchant, setUserMerchant] = useState(MerchantService.instance.merchantMap[user?.merchantId || ""] || null);
    let [selectorController, setSelectorController] = useState(new Controller());

    let [locationSelections, setLocationSelections] = useState(user?.accessibleMerchants || []);
    let [locationListVisible, setLocationListVisible] = useState(false);
    
    let [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    let [dialogData, setDialogData] = useState({title: (<></>), text: (<></>)});
    
    let _;
    
    const getWorkingButton = (caption, isSpinning) => {
        let buttonCaption = caption || 'Updating';
        let cn = 'spinning'; // !!isSpinning ? 'spinning' : 'done';
        let icon = isSpinning ? (<label></label>) : (<FontAwesomeIcon icon={faCheckCircle} />);
        
        return (
            <Button className={"spinner"}>
                <div>{buttonCaption} <span className={cn}>{ icon }</span></div>
            </Button>
        );
    };

    const onUser = (user) => {
        setUser(user);
    };

    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };

    const defaultDialogAction = () => {
        console.log('Nada');
    }
    
    let [confirmButton, setConfirmButton] = useState((<></>));
    
    const refreshUser = () => {
        UserService.instance.getUserAsync(userId).then((user) => {
            setUser(user);
        });
    };

    const confirmOpen = (e) => {
        setIsConfirmationOpen(true);
    };

    const confirmClose = (e) => {
        setIsConfirmationOpen(false);
    };
    
    const confirmCancel = (e) => {
        console.log("Confirmation canceled");
        setIsConfirmationOpen(false);
    };

    const setDoneButton = (caption, delay) => {
        if (!caption) caption = 'Done';

        setConfirmButton(getWorkingButton(caption, false));
        
        setTimeout(() => {
            setIsConfirmationOpen(false);
        }, delay || 1000);
    }

    const getUserMerchantAsync = async (force, e) => {
        if (!user?.merchantId) return userMerchant;
        if (!!userMerchant && !force) return userMerchant;
        
        await MerchantService.instance.getMerchantAsync(user?.merchantId || "").then((m) => {
            setUserMerchant(m);
        });
    };
    
    useEffect(() => {  
        if (user) {
            _ = getUserMerchantAsync(true);
            if (user?.accessibleMerchants)
                setLocationSelections(user.accessibleMerchants || []);
        }
    }, [user]);
    
    useEffect(() => {
        if (merchantId) MerchantEffect.create(setMerchant, merchantId);
        
        if (!user.id) {
            refreshUser()
        }
    }, []);
    
    const onClickMe = (key, e) => {
        console.log('Clicked: ' + key);
        confirmOpen(e);
    };

    // Send reset
    const sendResetLink = (key, e) => {
        const isMobile = key === "reset-sms";
        console.warn("IsMobile: " + isMobile);
        
        UserService.instance.sendPasswordResetLinkAsync(user.id, isMobile).then(() => {
            setDoneButton();
        }).catch((error) => {
            console.error('Error: ' + error);
        });
        
    };
    
    const confirmSendResetLink = (key, e) => {
        const btn = (<Button autoFocus color="inherit" onClick={sendResetLink.bind(this, key)}>
           Send Now
        </Button>);

        const isMobile = (key !== 'reset-email');
        const dest = !isMobile ? (<>{user.email}</>) : (<PhoneNumber value={user.phone} />);
        const icon = !isMobile ? (<FontAwesomeIcon icon={faEnvelope} />) : (<FontAwesomeIcon icon={faCommentDots} />);
        const sendVia = !isMobile ? 'Email' : 'Text Message';
        
        let dd = {
            title: (<>{icon} Send Password Link via {sendVia}</>),
            text: (<>Are you sure you want to send a reset password link to <a>{dest}</a>...?<br/>They might be confused if they aren't ready for the message.</>)
        }
        
        setDialogData(dd);
        setConfirmButton(btn);
        
        confirmOpen(e);
    };

    const killSessionsAsync = async (key, e) => {
        console.log('Kill Sessions: ' + key);

        setConfirmButton(getWorkingButton('Killing', true));
        
        _ = await UserService.instance.deleteSessionsAsync(userId);
        
        setDoneButton();
    };

    const confirmKillSessions = (key, e) => {
        let btn = (<Button autoFocus onClick={killSessionsAsync.bind(this, key)}>
            Yes, Kill Sessions
        </Button>);

        let dd = {
            title: (<><FontAwesomeIcon icon={faPersonFallingBurst} /> Boot <a>{user.name}</a>...?</>),
            text: (<>All sessions will end and this user will be redirected to the nearest login screen. Continue?!</>)
        }

        setDialogData(dd);
        setConfirmButton(btn);

        confirmOpen(e);
    };

    const deactivateUser = async () => {
        console.log("Deactivating...");
        await UserService.instance.deleteUserAsync(userId).then((rsp) => {
            setDoneButton();
        }).catch((ex) => {
            const dd = {
                title: "Failed to Deactivate User",
                text: null,
                error: (<><span className={"error"}>Failed to deactivate user: { ex?.response?.data?.message || "Server or other ambiguous system error" }</span></>),
            };

            setDialogData(dd);
            return null;
        });
    };
    
    const onRemoveMerchantAccess = (merchant, e) => {
        //
    };
    
    const promptForMerchantAccessAdd = (e) => {
        console.log("Merchant");
        console.log(MerchantService.instance.merchants?.length || "No Merchants");
    };

    const selectMerchantAccess = (m, e) => {
        let index = -1;
        
        locationSelections.filter((mm, idx) => { 
            if (mm.id === m.id) index = idx;
            return true;
        });
        
        if (index > -1) {
            let x = locationSelections.filter((_, idx) => idx !== index);
            setLocationSelections(x);
            return;
        }
        
        setLocationSelections(locationSelections.concat([m]));
    };
    
    const onInputFocus = (isFocused, e) => {
        if (isFocused === false) {
            setLocationListVisible(false);
            return;
        }

        setLocationListVisible(isFocused);
    }

    /**
     * Merchant selector event handler
     * @param e
     */
    const forceCloseMerchantAccess = (e) => {
        selectorController?.close({}, e);
        // if (!locationListVisible) return;
        //
        // if (e.target.id === "search-merchants") return;
        // if (e.target.className === "no-close") return;
        //
        // if (e.target.tagName === 'path' || (e.target.id && e.target.id.indexOf('notification') >= 0)) {
        //     return;
        // }
        //
        // UserService.instance.updateLocationAccessAsync(user.id, locationSelections).then((accessList) => {});
        // setLocationListVisible(false);
    };

    const confirmDeactivate = (key, e) => {
        let btn = (<Button autoFocus color="inherit" onClick={() => deactivateUser()}>
           Yes, Deactivate
        </Button>);

        let dd = {
            title: (<><FontAwesomeIcon icon={faSkull} /> Deactivate <a>{user.name}</a>...?</>),
            text: (<>This user will no longer have access to this system, and all notifications will stop. Continue?</>)
        }

        setDialogData(dd);
        setConfirmButton(btn);

        confirmOpen(e);
    };

    let backPath = "/people";
    let masterMenuSelection = "users";

    let h1 = (<h1><FontAwesomeIcon icon={faPersonWalking} /> Users</h1>);
    let submenu = (<UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selector={"access"} />);

    if (merchantId && merchantId.length > 10) {
        masterMenuSelection = "merchants";
        backPath = "/merchants/" + merchantId + "/users";
        h1 = (<h1><FontAwesomeIcon icon={faAddressCard} />
            <span className={"subtitle"}>Merchant:</span>
            {merchant?.name}
        </h1>);

        submenu = (
            <>
                <div className={"sub-sub-menu-container"}><MerchantSubMenu hasTooltip={true} merchant={merchant} selector={"users"} /></div>
                <UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selector={"access"} />
            </>
        );
    } else {
        //
    }

    let sendViaTextElement = !!user.phone ? (<> via email, or <strong><PhoneNumber value={user.phone} /></strong> via text message</>) : (<></>);
    let sendViaElement = (<><strong>{user.email}</strong> {sendViaTextElement}</>);

    
    let merchantItems = locationSelections?.map((m, index) => {
        return (<a className={'merchant-item'} key={m.id} id={"merchant-item-" + m.id} onClick={onRemoveMerchantAccess.bind(this, m)}>
            <FontAwesomeIcon icon={faCheckCircle} />
            {m.name}
        </a>);
    });
    
    let merchantSelectionMenuElements = null;
    
    const userMerchantInfo = userMerchant ? (<><Link to={"/merchants/" + user?.merchantId}>{userMerchant?.name}</Link> is the associated merchant.</>) : null;
    const locationAccess = userMerchant ? (<MultiLocationSelector controller={selectorController} user={user} merchant={userMerchant} isOpen={locationListVisible}  />) : null;
    //const merchantSelector = !!user ? (<MultiMerchantSelector controller={selectorController} user={user} onClose={} isOpen={merchantListVisible} />) : null;

    const cancelButtonElement = !dialogData.error ? (<Button autoFocus color="inherit" onClick={confirmCancel}>Cancel</Button>) : null;
    const confirmButtonElement = !dialogData.error ? confirmButton : (<Button autoFocus color="inherit" onClick={confirmCancel}>Okay</Button>);
    
    const dialogContent = (<Dialog open={isConfirmationOpen}>
        <DialogTitle id={"confirm-dialog-title"}>{dialogData.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="confirm-dialog-description">
                {dialogData?.error || dialogData.text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {cancelButtonElement}
            {confirmButtonElement}
        </DialogActions>
    </Dialog>);

    return (
        <UserMasterPage onUser={onUser} onMerchant={onMerchant} title={"Account Access"} onClick={forceCloseMerchantAccess} selection={"access"}>
            <p>
                Manage access to {user?.name}'s account below. {userMerchantInfo}
            </p>

            <div id="user-access-body">
                <ul id="user-access-options" className={"action-options"}>
                    <li>
                        { locationAccess }
                    </li>
                    <li>
                        <div>
                            <h4><FontAwesomeIcon icon={faPaperPlane} /> Re-Send Password Reset Link</h4>
                            <p>A link will be sent to {sendViaElement} which will take them to a password reset screen</p>
                            <div className="buttons">
                                <a onClick={confirmSendResetLink.bind(this, 'reset-email')}><FontAwesomeIcon icon={faEnvelope} /> Send via Email</a>
                                <a onClick={confirmSendResetLink.bind(this, 'reset-sms')}><FontAwesomeIcon icon={faCommentDots} /> Send via Text Message</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h4><FontAwesomeIcon icon={faPersonFallingBurst} /> End Sessions (Force Logout)</h4>
                            <p>{user.name} will be forceably logged out and all sessions will be revoked</p>
                            <div className="buttons">
                                <a onClick={confirmKillSessions.bind(this, 'kill-sessions')}><FontAwesomeIcon icon={faRightFromBracket} /> Force Logout Now</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h4><FontAwesomeIcon icon={faSkull} /> Deactivate Account</h4>
                            <p>Account will be rendered unusable and {user.name} will be forceably logged, to never return again</p>
                            <div className="buttons">
                                <a onClick={confirmDeactivate.bind(this, 'delete-account')}><FontAwesomeIcon icon={faFaceDizzy} /> Deactivate {user.firstName}</a>
                            </div>
                        </div>
                    </li>
                </ul>

                { dialogContent }

            </div>

            
        </UserMasterPage>        
    );
    
};

export default UserAccessScreen;
