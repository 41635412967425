import React, {useState, useRef, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faHandsClapping, faHouseUser} from "@fortawesome/free-solid-svg-icons";
import CardBrandLineGraph from "../components/reporting/ui/CardBrandLineGraph";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {Link} from "react-router-dom";
import ReportingService from "../components/reporting/services/ReportingService";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import RefreshButton from "../components/common/ui/RefreshButton";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import ReportItemModel from "../components/reporting/models/ReportItemModel";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import SaleService from "../components/sales/services/SaleService";
import LoadingBox from "../components/common/ui/LoadingBox";
import SaleReportingService from "../components/reporting/services/SaleReportingService";
import CommonMasterPage from "@paylani/paylani-react-packages/dist/common/ui/screens/CommonMasterScreen";
import SearchFilter from "@paylani/paylani-react-packages/dist/common/ui/search-filter/SearchFilter";
import CustomerModel from "../components/people/models/CustomerModel";
import MerchantLocationModel from "../components/merchants/models/MerchantLocationModel";
import MerchantModel from "../components/merchants/models/MerchantModel";

function Home(props) {
    /* TODO: Add to CardBrandLineGraph */

    ChartJS.register(
        CategoryScale,
        BarElement,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        ArcElement
    );

    const defaultLineGraphTitle = "Total Transactions";
    let _;
    
    const stateTitles = [
        defaultLineGraphTitle,
        'Total Sales Volume'
    ];

    const submissionsReportKey = 'master-submissions';
    const updatesReportKey = 'master-updates';

    const [chartState, setChartState] = useState({title: defaultLineGraphTitle, state: 0});
    const [dashboardData, setDashboardData] = useState(ReportingService.instance.dashboard || null);
    const [recentSales, setRecentSales] = useState(null);
    const [saleReportItems, setSaleReportItems] = useState(SaleReportingService.instance.salesDaily || null);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [user, setUser] = useState(AuthenticationService.instance.session?.user || null);

    let pageController = useRef(new PagerController(setCurrentPage, 16)).current;
    let startDate = new Date().addDays(-14);

    const getDashboardDataAsync = async (force) => {
        if (!!dashboardData && !force) {
            return dashboardData;
        }

        return await ReportingService.instance.getMasterDashboardDataAsync().then((model) => {
            if (!!model) setDashboardData(model);
            return model || dashboardData;
        });
    };

    AuthenticationService.instance.onSessionSet = (session) => {
        const u = session?.user;
        
        if (typeof props.onSession === 'function') props.onSession(session);
        setUser(u);

        return (session?.user === null);    // If we return 'true' here, this will continue to fire any time the session is set.
    };

    const getPortalData = (force) => {
        _ = getDashboardDataAsync(force);
    }

    const getPortalDataAsync = async (force) => {
        return Promise.all([
            getDashboardDataAsync(force),
        ]);
    }

    const getSalesReportItemsAsync = async (force) => {
        if (!!saleReportItems && saleReportItems.length > 0 && !force) return saleReportItems;

        await SaleReportingService.instance.getSalesDailyAsync().then((items) => {
            if (items === null) return;
            console.log("Got sales line report: " + items.length);
            setSaleReportItems(items);
        });
    };

    const getRecentSalesAsync = async (force) => {
        if (!!recentSales && recentSales.length > 0 && !force) return recentSales;

        await SaleService.instance.getSalesAsync().then((ss) => {
            if (ss === null) {
                console.error("Failed to get recent sales");
                return;
            }

            console.log("Got recent sales: " + ss.length);
            setRecentSales(ss);
        });
    };

    const onSessionChange = (session) => {
        getPortalData(true);
    };

    const getAll = (force) => {
        _ = getRecentSalesAsync(force);
        _ = getSalesReportItemsAsync(force);
        _ = getPortalDataAsync(force);
    };

    const refreshAsync = async (force) => {
        return Promise.all([
            getRecentSalesAsync(force),
            getSalesReportItemsAsync(force),
            getPortalDataAsync(force),
        ]);

    };

    useEffect(() => {
        getAll();
    }, []);

    const toggleState = (e) => {
        let newState = { title: defaultLineGraphTitle, state: 0 };
        if (chartState.state === 0) {
            newState.state = 1;
        }

        newState.title = stateTitles[newState.state];
        setChartState(newState);
    };

    const setLineGraphState = (state) => {
        if (state === chartState.state) return;

        let newState = { title: stateTitles[state], state: state };
        setChartState(newState);
    };

    let countData = [];
    let d = new Date().addDays(-14);

    const lineData = ReportItemModel.createLineChartSeries(saleReportItems, stateTitles[0]);
    const labels = saleReportItems.map((item) => item.label);

    const salesDataSets = [lineData];
    const currentState = 0; //chartState.state

    let countsDataSets = [{
        label: stateTitles[currentState],
        data: countData,
        borderColor: "green",
        borderWidth: 1,
        pointRadius: 1.5
    }];

    let salesLineData = {
        labels: labels, // ['VISA', 'MasterCard', 'Discover', 'Amex'],
        datasets: salesDataSets // chartState.state === 1 ? countsDataSets : salesDataSets
    };

    let controlSelections = ['', ''];
    controlSelections[chartState.state] = 'selected';

    let controls = (<span id="home-line-controls">
        <a className={controlSelections[0]} onClick={(e) => setLineGraphState(0)}>{ stateTitles[0] }</a>
        <a className={controlSelections[1]} onClick={(e) => setLineGraphState(1)}>{ stateTitles[1] }</a>
    </span>)

    const welcomeMessage = !!user ? (user?.gender === 'F' ? 'Welcome, ' + user?.firstName : 'Welcome, Mr. ' + user?.lastName) : 'Welcome, Fellow Traveller';

    const cardColumnStyle = {width: '120px'};

    const filterResults = (items) => {
        if (!items || items.length === 0) return items;

        return items.filter((saleItem) => {
            if (!filterText || filterText.length < 3) return true;
            const ft = filterText.toLowerCase();

            if (CustomerModel.searchFor(saleItem.customer, ft)) return true;
            if (MerchantLocationModel.searchFor(saleItem.location, ft)) return true;
            if (MerchantModel.searchFor(saleItem.merchant, ft)) return true;

            return saleItem.saleNumber.toString().indexOf(ft) > -1;
        });
    };

    const salesList = filterResults(recentSales);
    const salesElements = recentSales === null ? (<tr><td colSpan={4}><LoadingBox /></td></tr>) : pageController.mapLineItems(salesList, (item) => {

        return (
            <tr key={"recent-sale-" + item.id}>
                <td style={cardColumnStyle}>
                    <Link to={"/merchants/" + item.merchantId + "/sales/" + item.id}>#{item.saleNumber}</Link>
                </td>
                <td>{ item.total.formatCurrency(2) }</td>
                <td>
                    <Link title={"Merchant: " + item.merchant?.name} to={"/merchants/" + item.merchantId + "/locations/" + item.merchantLocationId + "/sales"}>
                        { item.location?.name || "No Name"}
                    </Link>
                </td>
                <td><DateTime value={item.created} time={true} /></td>
            </tr>);
    });

    const subtitle = (<h2><FontAwesomeIcon icon={faHandsClapping} />{welcomeMessage}
        <RefreshButton onClick={async () => refreshAsync(true)} /></h2>);

    const filterElement = recentSales?.length > 24 ? (<SearchFilter onFilter={setFilterText} label={"Search Recent Sales"} />) : null;

    return (
        <CommonMasterPage icon={faHouseUser} title={"Dashboard"} subTitle={subtitle} onSessionChange={onSessionChange}>
            <p>
                Sales activity is relatively low.
                Only a small percentage of active merchants have processed sales over the last week.
                Although no anomalies have been detected in the system.
            </p>

            <div className="dashboard-snapshot">
                <Link to={"/reporting/sales"} className="panel-box round8 rows" onClick={(e) => setLineGraphState(0)}>
                    <ul>
                        <li>Sales Volume Today</li>
                        <li>Since Date:</li>
                        <li><DateTime value={dashboardData?.startDate} showRelativeDate={true} time={true} defaultValue={"Never"} /></li>
                    </ul>
                    <span className="large"><NumberDisplay value={(dashboardData?.salesVolume || 0)} type={"currency"} isComponent={true} /></span>
                </Link>

                <a className="panel-box round8 rows" onClick={(e) => setLineGraphState(1)}>
                    <ul>
                        <li>Sale Count</li>
                        <li>Last Transaction on:</li>
                        <li><DateTime value={dashboardData?.lastSaleDate} showRelativeDate={true} defaultValue={"Never"} /></li>
                    </ul>
                    <span className="large">{ (dashboardData?.saleCount || 0).formatNumber(0) }</span>
                </a>

                <a className="panel-box round8 rows">
                    <ul>
                        <li>Active Merchants</li>
                        <li>Newest Onboarded on:</li>
                        <li><DateTime value={dashboardData?.lastRegisterDate} showRelativeDate={true} defaultValue={"Never"} /></li>
                    </ul>
                    <span className="large">{ (dashboardData?.activeCount || 0).formatNumber(0) }</span>
                </a>
            </div>

            <CardBrandLineGraph title={chartState.title} data={salesLineData} chartControls={controls} />

            <div id="recent-batches">
                <h2 className={"has-search"}>
                    Recent Sales
                    { filterElement }
                </h2>
                <table className="table-x-large" width="100%">
                    <thead>
                    <tr>
                        <th style={cardColumnStyle}>Sale #</th>
                        <th>Amount</th>
                        <th>Origination</th>
                        <th>Date</th>
                    </tr>
                    </thead>

                    <tbody>
                    {salesElements}
                    </tbody>
                </table>

                <Pager controller={pageController} items={salesList} />

            </div>
        </CommonMasterPage>
    );

}

export default Home;
