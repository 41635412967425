class ProductModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        
        this.userId = json.user_id;
        this.merchantId = json.merchant_id;
        this.merchantLocationId = json.merchant_location_id;
        this.productCatalogId = json.product_catalog_id;
        this.name = json.name;
        this.description = json.description;
        this.imageUrl = json.image_url;
        this.sku = json.sku;
        this.itemCode = json.item_code;
        this.gLCode = json.g_l_code;
        this.remoteId = json.remote_id;

        this.price = json.price || 0;
        this.tax = json.tax || 0;
        this.sortOrder = json.sort_order;
        this.status = json.status || 0;

        this.isTaxOverride = json.is_tax_override || false;
        
        this.totalPurchasedCount = json.total_purchased_count || 0;
        this.totalRevenue = json.total_revenue || 0;
        this.inventoryCount = json.inventory_count || 0;
        this.isTrackingInventory = json.is_tracking_inventory || false;
        this.costPer = json.cost_per || 0;

        this.lastOrderDate = json.last_order_date ? new Date(json.last_order_date) : null;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
        merchant_id: this.merchantId,
        merchant_location_id: this.merchantLocationId,
        product_catalog_id: this.productCatalogId,
        name: this.name,
        description: this.description,
        image_url: this.imageUrl,
        sku: this.sku,
        item_code: this.itemCode,
        g_l_code: this.gLCode,
        remote_id: this.remoteId,
        price: this.price,
        tax: this.tax,
        is_tax_override: this.isTaxOverride,
        sort_order: this.sortOrder,
        status: this.status,
        last_order_date: this.lastOrderDate,
        total_purchased_count: this.totalPurchasedCount,
        total_revenue: this.totalRevenue,
        inventory_count: this.inventoryCount,
        is_tracking_inventory: this.isTrackingInventory,
        cost_per: this.costPer,
        };
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map(json => new ProductModel(json));
    }
}

export default ProductModel;
