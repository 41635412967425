import AddressModel from "@paylani/paylani-react-packages/dist/geo/models/AddressModel";
import CustomerModel from "../../people/models/CustomerModel";
import MerchantLocationModel from "./MerchantLocationModel";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import CompanyModel from "@paylani/paylani-react-packages/dist/companies/models/CompanyModel";

class MerchantModel extends CompanyModel {
    static startDate = new Date();
    static create = (json) => new MerchantModel(json);
    
    constructor(json, hasDetails) {
        if (!json) json = {};
        super(json);
        
        this.id = json.id;

        this.planId = json.plan_id;
        this.isActive = json.is_active;
        this.userCount = json.user_count || 0;
        this.locationCount = json.location_count || 0;
        this.accountOwnerId = json.master_user_id || null;
        this.accountOwner = UserModel.create(json.master_user);
        this.address = AddressModel.create(json.address);

        this.created = new Date(Date.parse(json.created));
        this.modified = json.modified !== null ? new Date(Date.parse(json.modified)) : null;
        
        this.date = this.modified || this.created;
        
        let billingCustomer = json.customer || json.billing_customer;
        this.customer = !!billingCustomer ? new CustomerModel(billingCustomer) : new CustomerModel({ id: null, first_name: '', last_name: '' });
        
        this.locations = MerchantLocationModel.fromJsonArray(json.locations);
        this.locationMap = {};
        
        let me = this;
        
        this.locations.map((location) => {
            me.locationMap[location.id] = location;
            //console.warn("LocationMap: " + location.id + " -> " + location.name);
        });
        
        this.hasDetails = (hasDetails === true);
    }

    toJson = () => {
        return {
            name: this.name + '',
            email: this.email || '',
            phone: this.phone || '',
            website: this.website || '',
            address: this.address?.toJson(),
        };
    }

    static searchFor(merchant, term) {
        if (!term || term.length === 0) return true;
        if (!merchant) return false;

        if (merchant.name.trim().toLowerCase().indexOf(term) >= 0) return true;
        if ((merchant.email || "").trim().toLowerCase().indexOf(term) >= 0) return true;
        if ((merchant.website || "").trim().toLowerCase().indexOf(term) >= 0) return true;
        
        return ((merchant.phone || "").trim().toLowerCase().indexOf(term) >= 0);
    }
}

export default MerchantModel;
