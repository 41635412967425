import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantLocationService from "../../../components/merchants/services/MerchantLocationService";
import CheckBoxer from "@paylani/paylani-react-packages/dist/common/ui/checkboxer/CheckBoxer";
import LocationMasterPage from "./LocationMasterPage";
import ConfigInput from "../../../components/merchants/ui/ConfigInput";
import ConfigDropdown from "../../../components/merchants/ui/ConfigDropdown";

const LocationConfigsScreen = (props) => {
    const {merchantId, locationId} = useParams();
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    let [configurations, setConfigurations] = useState(MerchantService.instance.configurations || []);
    
    let _;

    const onMerchant = (m) => {
        setMerchant(m);
    };

    const onLocation = (l) => {

    };
    
    const getConfigsAsync = async (force) => {
        console.log('Getting Configs for ' + merchantId + ' and ' + locationId);
        if (!force && !!configurations && configurations.length > 5) return configurations;
        
        return await MerchantService.instance.getConfigurationsAsync().then((configs) => {
            console.log('Got Configurations: ' + configs.length);
            setConfigurations(configs);
        });
    };
    
    const updateConfigAsync = async (name, value) => {
        if (value?.toString() === "false" && typeof value === "boolean") value = "";
        console.warn("Updating: " + name + " = " + value + " (" + (typeof value) + ")");
        await MerchantLocationService.instance.updateConfigurationAsync(locationId, name, value);
        await getConfigsAsync(true);
    };
    
    useEffect(() => {
        _ = getConfigsAsync();
    }, []);

    let location = merchant?.locations[0];
    
    const onConfigChange = (config, e) => {
        console.log('Config Change[' + config.name + "] = " + e.target.value);
    };
    
    let configurationElements = !!location?.configurationMap ? configurations.map((config) => {
        let value = location.configurationMap[config.name] || "";
        let isChecked = !!value?.value;
        let valueElement = null;
        let cn = "";
        
        if (config.configurationType === 'number')
            valueElement = (<ConfigInput config={config} location={location} value={value?.value} onClick={(v) => updateConfigAsync(config.name, v)} />);
        
        if (config.configurationType === 'list') 
            valueElement = (<ConfigDropdown config={config} location={location} value={value?.value} onChange={(v) => updateConfigAsync(config.name, v)} />);
        
        if (valueElement === null) {
            valueElement = (<CheckBoxer isChecked={isChecked} onChange={(chk) => updateConfigAsync(config.name, chk)} />)
            if (isChecked) cn = " selected";
        }
        
        return (
            <tr key={config.id} className={"config-row" + cn} title={config.name}>
                <td>
                    <strong>{config.title}</strong>
                    <p>{config.description}</p>
                </td>
                <td className={"config-element"}>{ valueElement }</td>
            </tr>
        );
    }) : null;
    
    return (
        <LocationMasterPage onMerchant={onMerchant} onLocation={onLocation} title={"Configurations"} selection={"config"}>
            <p>
                This merchant location appears as "{location?.name}" on reporting, and is for display purposes. The parent merchant is <Link to={"/merchants/" + merchantId}>{merchant?.name}</Link>.
            </p>
        
            <div className={"configuration-list"}>
                <table className={"table-large large"} width={"100%"}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {configurationElements}
                    </tbody>
                </table>
            </div>    
        </LocationMasterPage>        
    );
    
    
};

export default LocationConfigsScreen;