// noinspection JSValidateTypes

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReportingScreen from "./routes/reporting/ReportingScreen";
import SettingsScreen from "./routes/settings/SettingsScreen";
import MessageDetailsScreen from "./routes/settings/MessageDetailsScreen";
import CompanyInfoScreen from "./routes/settings/CompanyInfoScreen";
import MessagingCredsScreen from "./routes/settings/MessagingCredsScreen";
import EnvironmentSettingsScreen from "./routes/settings/EnvironmentSettingsScreen";
import TrendsScreen from "./routes/reporting/TrendsScreen";
import CustomReportsScreen from "./routes/reporting/CustomReportsScreen";
import CustomerSupportScreen from "./routes/support/CustomerSupportScreen";
import MessagesScreen from "./routes/settings/MessagesScreen";
import TicketDetailsScreen from "./routes/support/TicketDetailsScreen";
import UsersScreen from "./routes/people/UsersScreen";
import UserDetailsScreen from "./routes/people/UserDetailsScreen";
import UserAccessScreen from "./routes/people/UserAccessScreen";
import UserActivityHistoryScreen from "./routes/people/UserActivityHistoryScreen";
import UserSessionHistoryScreen from "./routes/people/UserSessionHistoryScreen";
import PasswordResetScreen from "./routes/public/PasswordResetScreen";
import ComplianceScreen from "./routes/settings/ComplianceScreen";
import MerchantsScreen from "./routes/merchants/MerchantsScreen";
import MerchantEditScreen from "./routes/merchants/MerchantEditScreen";
import MerchantWebhooksScreen from "./routes/merchants/MerchantWebhooksScreen";
import MerchantWebhookLogsScreen from "./routes/merchants/MerchantWebhookLogsScreen";
import MerchantDetailsScreen from "./routes/merchants/MerchantDetailsScreen";
import MerchantUsersScreen from "./routes/merchants/users/MerchantUsersScreen";
import MerchantUserDetailsScreen from "./routes/merchants/users/MerchantUserDetailsScreen";
import MerchantReportsScreen from "./routes/merchants/MerchantReportsScreen";
import MerchantSettingsScreen from "./routes/merchants/MerchantSettingsScreen";
import LocationsScreen from "./routes/merchants/locations/LocationsScreen";
import LocationDetailsScreen from "./routes/merchants/locations/LocationDetailsScreen";
import LocationConfigsScreen from "./routes/merchants/locations/LocationConfigsScreen";
import LocationEditScreen from "./routes/merchants/locations/LocationEditScreen";
import LocationCustomFieldsScreen from "./routes/merchants/locations/LocationCustomFieldsScreen";
import LocationContentScreen from "./routes/merchants/locations/LocationContentScreen";
import LocationTipsScreen from "./routes/merchants/locations/LocationTipsScreen";
import CardReadersScreen from "./routes/merchants/CardReadersScreen";
import BillingScreen from "./routes/merchants/BillingScreen";
import SalesScreen from "./routes/merchants/sales/SalesScreen";
import SaleDetailsScreen from "./routes/merchants/sales/SaleDetailsScreen";
import Error404Screen from "./routes/common/Error404Screen";
import MerchantUserAccessScreen from "./routes/merchants/users/MerchantUserAccessScreen";
import MerchantUserActivityScreen from "./routes/merchants/users/MerchantUserActivityScreen";
import MerchantUserSessionHistoryScreen from "./routes/merchants/users/MerchantUserSessionScreen";
import ProductReportsScreen from "./routes/reporting/ProductReportsScreen";
import SalesReportScreen from "./routes/reporting/SalesReportScreen";
import LocationSalesScreen from "./routes/merchants/locations/LocationSalesScreen";
import TransactionReportScreen from "./routes/reporting/TransactionReportScreen";
import LocationProductsScreen from "./routes/merchants/locations/LocationProductsScreen";
import LocationProductEditScreen from "./routes/merchants/locations/LocationProductEditScreen";
import MerchantCatalogsScreen from "./routes/merchants/MerchantCatalogsScreen";
import MerchantCatalogProductsScreen from "./routes/merchants/MerchantCatalogProductsScreen";
import MerchantProductEditScreen from "./routes/merchants/MerchantProductEditScreen";
import LocationVirtualTerminal from "./routes/merchants/locations/LocationVirtualTerminal";
import MerchantVirtualTerminal from "./routes/merchants/MerchantVirtualTerminal";
import LogoutScreen from "./routes/authentication/LogoutScreen";
import PaylaniConfig from "./PaylaniConfig";

PaylaniConfig.init();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path={"/logout"} element={<LogoutScreen />} />
                
            <Route path="/accountreset/:resetId" element={<PasswordResetScreen />} />

            <Route path="/merchants" element={<MerchantsScreen />} />
            <Route path="/merchants/:merchantId" element={<MerchantDetailsScreen />} />
            <Route path="/merchants/:merchantId/edit" element={<MerchantEditScreen />} />
            <Route path="/merchants/:merchantId/sales" element={<SalesScreen />} />
            <Route path="/merchants/:merchantId/sales/:saleId" element={<SaleDetailsScreen />} />
            <Route path="/merchants/:merchantId/sales/:saleId/with-location/:locationId" element={<SaleDetailsScreen />} />
            <Route path="/merchants/:merchantId/virtual-terminal" element={<MerchantVirtualTerminal />} />
            <Route path="/merchants/:merchantId/virtual-terminal/:customerId" element={<MerchantVirtualTerminal />} />

            <Route path="/merchants/:merchantId/catalogs" element={<MerchantCatalogsScreen />} />
            <Route path="/merchants/:merchantId/catalogs/:catalogId/products" element={<MerchantCatalogProductsScreen />} />
            <Route path="/merchants/:merchantId/catalogs/:catalogId/products/:productId" element={<MerchantProductEditScreen />} />
            
            <Route path="/merchants/:merchantId/billing" element={<BillingScreen />} />
            <Route path="/merchants/:merchantId/configuration" element={<MerchantSettingsScreen />} />
            <Route path="/merchants/:merchantId/reports" element={<MerchantReportsScreen />} />
            <Route path="/merchants/:merchantId/card-readers" element={<CardReadersScreen />} />
            <Route path="/merchants/:merchantId/users" element={<MerchantUsersScreen />} />

            <Route path="/merchants/:merchantId/users/:userId" element={<MerchantUserDetailsScreen />} />
            <Route path="/merchants/:merchantId/users/:userId/access" element={<MerchantUserAccessScreen />} />
            <Route path="/merchants/:merchantId/users/:userId/activity" element={<MerchantUserActivityScreen />} />
            <Route path="/merchants/:merchantId/users/:userId/sessions" element={<MerchantUserSessionHistoryScreen />} />

            <Route path="/merchants/:merchantId/locations" element={<LocationsScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId" element={<LocationDetailsScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId/sales" element={<LocationSalesScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId/configurations" element={<LocationConfigsScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId/products" element={<LocationProductsScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId/products/:productId" element={<LocationProductEditScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId/edit" element={<LocationEditScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId/tips" element={<LocationTipsScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId/fields" element={<LocationCustomFieldsScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId/content" element={<LocationContentScreen />} />
            <Route path="/merchants/:merchantId/locations/:locationId/virtual-terminal" element={<LocationVirtualTerminal />} />
            <Route path="/merchants/:merchantId/locations/:locationId/virtual-terminal/:customerId" element={<LocationVirtualTerminal />} />
            
            <Route path="/merchants/:merchantId/webhooks" element={<MerchantWebhooksScreen />} />
            <Route path="/merchants/:merchantId/webhooks/logs" element={<MerchantWebhookLogsScreen />} />

            <Route path="/support" element={<CustomerSupportScreen />} />
            <Route path="/support/:threadId" element={<TicketDetailsScreen />} />

            <Route path="/reporting" element={<ReportingScreen />} />
            <Route path="/reporting/trends" element={<TrendsScreen />} />
            <Route path="/reporting/custom-reports" element={<CustomReportsScreen />} />
            <Route path="/reporting/products" element={<ProductReportsScreen />} />
            <Route path="/reporting/sales" element={<SalesReportScreen />} />
            <Route path="/reporting/transactions" element={<TransactionReportScreen />} />
            
            <Route path="/settings" element={<SettingsScreen />} />
            <Route path="/settings/company" element={<CompanyInfoScreen />} />
            <Route path="/settings/messaging" element={<MessagingCredsScreen />} />
            <Route path="/settings/system-messages" element={<MessagesScreen />} />
            <Route path="/settings/system-messages/:messageId" element={<MessageDetailsScreen />} />
            <Route path="/settings/system" element={<EnvironmentSettingsScreen />} />
            <Route path="/settings/pci" element={<ComplianceScreen />} />
            
            <Route path="/people" element={<UsersScreen />} />
            <Route path="/people/:userId" element={<UserDetailsScreen />} />
            <Route path="/people/:userId/access" element={<UserAccessScreen />} />
            <Route path="/people/:userId/activity" element={<UserActivityHistoryScreen />} />
            <Route path="/people/:userId/sessions" element={<UserSessionHistoryScreen />} />

            <Route path="*" element={<Error404Screen />} />

        </Routes>
    </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

