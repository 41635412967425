import React, {useEffect, useState, useRef} from 'react';
import {useParams} from "react-router-dom";
import MasterScreenMain from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard, faAnchor, faAnchorCircleCheck, faAnchorCircleExclamation,
    faCheckCircle, faFaceMeh,
    faPaperPlane,
    faSpinner, faSync,
    faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import WebhookService from "../../components/webhooks/services/WebhookService";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from "@mui/material";

import '../../components/common/ui/dialog-box/Dialog.css';
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import ClipBoard from "@paylani/paylani-react-packages/dist/common/ui/AddNewButton";
import EmptyBox from "../../components/common/ui/EmptyBox";
import MerchantModel from "../../components/merchants/models/MerchantModel";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantSubMenu from "../../components/merchants/ui/MerchantSubMenu";
import MerchantWebhooksSubMenu from "../../components/merchants/ui/MerchantWebhooksSubMenu";
import MerchantMasterPage from "./MerchantMasterPage";

const MerchantWebhookLogsScreen = (props) => {
    const {merchantId} = useParams();
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel({}));
    let [webhookLogs, setWebhookLogs] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    let [selectedWebhookLog, setSelectedWebhookLog] = useState(null);
    let [tabSelection, setTabSelection] = useState('response');
    let [isPosting, setIsPosting] = useState(0);
    let [currentPage, setCurrentPage] = useState(0);
    let pageController = useRef(new PagerController(setCurrentPage, 20)).current;

    const openDialog = (log, e) => {
        setIsPosting(0);
        setSelectedWebhookLog(log);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
        setSelectedWebhookLog(null);
    };

    const onMerchant = (m) => {
        setMerchant(m);
    };

    const getWebhookLogs = () => {
        MerchantService.instance.getWebhookLogsAsync(merchantId, '', '').then((logs) => {
            console.log('Got Webhooks: ' + logs.length);
            setWebhookLogs(logs);
        });
    }

    useEffect(() => {
        WebhookService.instance.getWebhooksAsync().then(() => setCurrentPage(currentPage));
        getWebhookLogs();
    }, []);

    const selectDialogScreen = (key, e) => {
        setTabSelection(key);
    };

    let website = merchant.website || '';
    let empty = (<i>Not Entered</i>);

    const getResponseText = (text, success) => {
        if (!text) return (success ? '200 OK' : '500 Internal Server Error');

        if ((text + '').length > 100) {
            return text.substring(0, 100) + '...';
        }
        return text;
    };

    const setPostingError = (error, delay) => {
        setIsPosting(-1);
        if (!delay || typeof delay !== 'number') delay = 2500;

        setTimeout(() => {
            setIsPosting(0);
        }, delay);
    };

    const setPostingSuccess = (delay) => {
        setIsPosting(2);

        if (!delay || typeof delay !== 'number') delay = 3500;

        console.log('setPostingSuccess: ' + delay);

        setTimeout(() => {
            setIsPosting(0);
        }, delay);
    };

    const repostWebhookAsync = async (log, e) => {
        if (isPosting !== 0) {
            console.log('Nope: ' + isPosting);
            return;
        }

        setIsPosting(1);

        await WebhookService.instance.repostWebhookLogAsync(log.id).then((response) => {
            setPostingSuccess();
            console.log('Reposted Webhook Log OK: ' + log.id);
        }).catch((error) => {
            setPostingError(error);
            console.log('Ex: ' + error);
        });
    };

    let tabSelections = {response: '', payload: ''};
    tabSelections[tabSelection] = ' selected';

    let webhookLogElements = pageController.mapLineItems(webhookLogs, (w) => {
        let webhook = WebhookService.instance.webhookMap[w.webhookId] || {name: 'Unknown webhook'};
        let cn = w.success ? 'small' : 'small failed';

        return (
            <tr key={w.id} className={cn}>
                <td>{webhook.name}</td>
                <td className="tiny"><a onClick={openDialog.bind(this, w)}>{getResponseText(w.response, w.success)}</a>
                </td>
                <td className="tiny dimmed">{w.url || empty}</td>
                <td><DateTime value={w.created} time={true}/></td>
            </tr>
        );
    });

    let emptyBox = webhookLogs.length === 0 ? (
        <EmptyBox className={"middle"}><span>Nothing found in {merchant.name} Webhook Post Logs</span><FontAwesomeIcon
            icon={faAnchor}/></EmptyBox>) : (<></>);

    let postingIcon = (<FontAwesomeIcon icon={faAnchor}/>);
    let repostCaption = 'Repost';
    switch (isPosting) {
        case -1:
            postingIcon = (<FontAwesomeIcon icon={faAnchorCircleExclamation} className="failed"/>);
            repostCaption = 'Error';
            break;
        case 1:
            postingIcon = (
                <span style={{marginRight: '8px', marginTop: '3px'}}><span className="spinner spinning"></span></span>);
            repostCaption = 'Reposting';
            break;
        case 2:
            postingIcon = (<FontAwesomeIcon icon={faAnchorCircleCheck} className="success"/>);
            repostCaption = 'Success';
            break;
        default:
            break;
    }

    let contentValue = tabSelection === 'payload' ? selectedWebhookLog?.payload : selectedWebhookLog?.response;

    return (
        <MerchantMasterPage title={"Webhook Logs"} onMerchant={onMerchant} selection={"webhooks"}>
            <MerchantWebhooksSubMenu partner={merchant} selection="logs"/>
            <p>

            </p>

            <table className="table-x-large" width="100%">
                <thead>
                <tr>
                    <th>Webhook</th>
                    <th>Response/Payload</th>
                    <th>URL</th>
                    <th>DateTime</th>
                </tr>
                </thead>
                <tbody>
                {webhookLogElements}
                </tbody>
            </table>
            <Pager items={webhookLogs} controller={pageController}/>
            {emptyBox}
        </MerchantMasterPage>
    );
}

export default MerchantWebhookLogsScreen;
