import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import ProductService from "../services/ProductService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";

const ProductList = (props) => {
    const { merchantId } = useParams();
    const { catalogId, productCatalogId, onCatalog, onProductCatalog, onError, locationId } = props;
    const cid = catalogId || productCatalogId;
    
    const [catalog, setCatalog] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const pagerController = useRef(new PagerController(0, 24, setCurrentPage)).current;

    let _;
    
    const deleteProductAsync = async (p) => {
        const element = document.getElementById("product-item-" + p.id);
        if (!!element) element.remove();
        
        await ProductService.instance.deleteProductAsync(p.id);
    };

    const getCatalogAsync = async (force) => {
        const callback = typeof onCatalog === 'function' ? onCatalog : onProductCatalog;
        if (!force && !!catalog?.id) {
            if (typeof callback === 'function') callback(catalog);
            return;
        }

        return await ProductService.instance.getProductCatalogAsync(cid).then((catalog) => {
            if (!!catalog?.id) {
                if (typeof callback === 'function') callback(catalog);
                setCatalog(catalog);
            }
        }).catch((err) => {
            if (typeof onError === 'function') onError(err);
            console.error(err);
        });
    };

    useEffect(() => {
        _ = getCatalogAsync();
    }, []);

    const productElements = catalog?.products ? pagerController.mapLineItems(catalog?.products, (product) => {
        const productImageElement = product?.imageUrl && product.imageUrl.startsWith("http") ? (<img src={product.imageUrl} alt={product.name} />) : (<div className={"blank-image-icon"}><FontAwesomeIcon icon={faImage} /></div>);
        const descElement = product.description ? (<p>{ product.description }</p>) : (<p className={"no-description"}>{ product.description }</p>);
        const path = !!locationId ? 
            "/merchants/" + merchantId + "/locations/" + locationId + "/products/" + product.id :
            "/merchants/" + merchantId + "/catalogs/" + cid + "/products/" + product.id;
        
        const taxElement = !!product.isTaxOverride ? (<span className={"tax"}>Tax Override: <strong><NumberDisplay type={"percent"} value={product.tax} /></strong> ({product.isTaxOverride?.toString()})</span>) : null;

        return (<div key={"product-" + product.id} className="product-item" id={"product-item-" + product.id}>
            <div className={"product-item-image"}>
                <Link to={path}>{productImageElement}</Link>
            </div>
            <div className={"product-item-details"}>
                <h4>
                    <Link to={path}>{ product.name}</Link>
                    <span className={"delete delete-product hover-only"}>
                        <a onClick={() => deleteProductAsync(product)} className={"delete"}>Delete</a>
                    </span>
                </h4>
                {descElement}
                <div className={"pricing"}>
                    <span className={"price"}>
                        Price: <strong><NumberDisplay type={"currency"} value={product.price} /></strong>
                    </span>

                    { taxElement }
                </div>

            </div>
        </div>);
    }) : null;

    return (<>
            <div className={"product-list"}>
                { productElements }
            </div>

            <Pager controller={pagerController} items={catalog?.products} />
        </>);
};

export default ProductList;

