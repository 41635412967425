import React, { useState, useEffect } from 'react';
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogBoxController from "./DialogBoxController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const DialogBox = (props) => {
    let {
        title,
        controller,
        options,
        onCancel,
        onConfirm,
        onOkay,
        onClose,
        okayButton,
        cancelCaption,
        okCaption,
        loadingCaption,
        bodyId,
        actions,
        successButtonCaption,
        failButtonCaption,
        hasCancelButton,
        hasOkayButton,
        children,
        buttonActions,
    } = props;
    
    if (!options) options = { title: "" };

    const [isDialogOpen, setIsDialogOpen] = useState(-1);
    let [dialogState, setDialogState] = useState(0);
    
    // TODO: Add the ability to change the title, body, and buttons with the controller
    let [dialogTitle, setDialogTitle] = useState(title || options.title);
    let [dialogBody, setDialogBody] = useState(props.children);

    if (!loadingCaption) loadingCaption = options.loadingCaption;
    if (!loadingCaption) loadingCaption = "Working";
    if (!okCaption) okCaption = (options.okCaption || 'Okay');
    
    let confirmCancel = typeof onCancel === 'function' ? onCancel : (e) => { 
        return true;
    };

    let onCancelClick = (e) => {
        let result = confirmCancel(e);
        if (result === true) {
            setIsDialogOpen(0);
        }
    };
    
    if (!controller) {
        controller = new DialogBoxController();
    }
    
    controller.open = (title, body) => {
        setDialogState(0);
        
        console.warn("Dialog Title Actions: " + controller.objects?.titleActions)
        
        if (!!body) {
            console.warn("Body set: " + body);
            controller.body = body;
        }
        if (!title) title = controller.title;
        if (!!title) setDialogTitle(title);

        setIsDialogOpen(1);
    }

    controller.close = () => {
        controller.objects = {};
        setIsDialogOpen(0);
    }
    
    let isConfirm = typeof onConfirm === 'function';
    let isOkay = typeof onOkay === 'function';
    
    useEffect(() => {
        //console.warn("Dialog.isDialogOpen Effect: " + isDialogOpen);
        if (isDialogOpen === -1) { 
            console.warn("Init");
        } else if (isDialogOpen === 0) {
            if (typeof onClose === 'function') onClose();
            console.warn("Closed");
        }
    }, [isDialogOpen]);
    
    useEffect(() => { 
        if (dialogBody !== children) setIsDialogOpen(1);
    }, [dialogBody]);
    
    const onButtonPressAsync = async (e) => {
        if (dialogState >= 1) return;

        setDialogState(dialogState + 1);

        let result = null;
        
        if (isConfirm) {
            console.log('Calling onConfirm() because isConfirm is ' + isConfirm.toString());
            let isConfirmAsync = onConfirm?.constructor?.name === 'AsyncFunction';
            
            if (isConfirmAsync) result = await onConfirm(e);
            else result = onConfirm(e);
        }

        if (isOkay) {
            result = onOkay(e);
            if (typeof result.then === 'function') result = await result.then((x) => x);
        }

        console.log('Dialog Result: ' + result);
        
        if (typeof result === 'number') {
            console.log('Result is number: ' + result);
            setDialogState(2);
            
            setTimeout(() => {
                controller.close();
            }, result);
        } else if (result !== false) {
            controller.close();
        } else { 
            setDialogState(0);
        }
    };

    const getLoadingButton = () => {
        if (hasOkayButton === false) return null;
        
        let isSpinning = (dialogState === 1);
        if (!!okayButton && !isSpinning) return okayButton;
        
        let cn = '';
        let icon = null;
        let buttonCaption = (okCaption || options.buttonCaption) || 'Okay';
        
        if (dialogState > 0) {
            buttonCaption = isSpinning ? (loadingCaption || 'Working') : (successButtonCaption || 'Success');
            cn = !!isSpinning ? 'spinning' : 'done';
            icon = isSpinning ? (<label></label>) : (<FontAwesomeIcon icon={faCheckCircle}/>);
        }

        return (<Button onClick={onButtonPressAsync}>{buttonCaption} <span className={cn}>{ icon }</span></Button>);
    };
    
    let confirmButton = getLoadingButton();
    const controllerBody = controller?.body;
    
    let body = controllerBody || (props.children || (<>No data</>));
    let defaultTitle = (typeof onConfirm === 'function') ? 'Confirm' : 'Alert';
    
    let cancelButton = (hasCancelButton !== false) ? (<Button autoFocus color="inherit" onClick={onCancelClick}>
        { cancelCaption || "Cancel" }
    </Button>) : null;
    
    const ttl = dialogTitle || defaultTitle;
    const hasActions = !!(controller?.objects?.titleActions || actions);
    const titleActions = hasActions ? (<span className={"title-actions"}><span>{ ttl }</span><span>{ controller?.objects?.titleActions || actions }</span></span>) : ttl;
    const placeHolder = (<span></span>);
    
    return (
        <Dialog open={isDialogOpen === 1} className={"dialog-state-" + dialogState.toString()}>
            <DialogTitle id={"confirm-dialog-title"}>
                { titleActions }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id={bodyId || "dialog-body"} className={"dialog-body"}>
                    {body}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={"dialog-buttons"}>
                {buttonActions || placeHolder}
                {cancelButton}
                {confirmButton}
            </DialogActions>

        </Dialog>
        
    );
};

export default DialogBox;