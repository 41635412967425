import TransactionReportItemModel from "./TransactionReportItemModel";
import ReportResponseModel from "./ReportResponseModel";

class TransactionReportResponseModel extends ReportResponseModel { 
    constructor(json) {
        super(json);        
        this.items = TransactionReportItemModel.fromJsonArray(json?.items);
    }
}

export default TransactionReportResponseModel;
