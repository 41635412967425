import React, {useState, useEffect, useRef} from 'react';
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faEdit} from "@fortawesome/free-solid-svg-icons";

import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantWebhooksSubMenu from "../../components/merchants/ui/MerchantWebhooksSubMenu";
import MerchantMasterPage from "./MerchantMasterPage";
import PartnerWebhookModel from "../../components/merchants/models/PartnerWebhookModel";
import ErrorModel from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import LoadingBox from "../../components/common/ui/LoadingBox";
import EmptyBox from "../../components/common/ui/EmptyBox";

const MerchantWebhooksScreen = (props) => {
    const { merchantId } = useParams();
    let _;
    
    const [error, setError] = useState({});
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null);
    let [merchantWebhooks, setMerchantWebhooks] = useState(null);
    let [webhookEditor, setWebhookEditor] = useState(null);
    
    const getPartnerWebhooksAsync = async () => {

        const array = [
            {
                "webhook": {
                    "name": "Captured",
                    "description": null,
                    "method": "POST",
                    "example_body": null,
                    "sorting": 20,
                    "webhook_type": 20,
                    "id": "c4487c83-1e0b-4aa1-8d5d-9d9815b9ec92",
                    "created": "2021-12-06T20:27:14.413",
                    "modified": null
                },
                "url": "",
                "id": "00000000-0000-0000-0000-000000000000",
                "created": "2022-09-21T16:00:30.1004733Z",
                "modified": null
            },
            {
                "webhook": {
                    "name": "Declined",
                    "description": null,
                    "method": "POST",
                    "example_body": null,
                    "sorting": 23,
                    "webhook_type": 21,
                    "id": "2c29f1fa-5f2d-4b6a-8249-17a517f7a0a5",
                    "created": "2022-01-07T02:22:49.413",
                    "modified": null
                },
                "url": "",
                "id": "00000000-0000-0000-0000-000000000000",
                "created": "2022-09-21T16:00:30.1004663Z",
                "modified": null
            },
            {
                "webhook": {
                    "name": "Product Created/Updated",
                    "description": null,
                    "method": "POST",
                    "example_body": null,
                    "sorting": 21,
                    "webhook_type": 22,
                    "id": "37bbcd38-5942-4744-9798-3c612a873829",
                    "created": "2021-12-06T20:27:14.47",
                    "modified": null
                },
                "url": "",
                "id": "00000000-0000-0000-0000-000000000000",
                "created": "2022-09-21T16:00:30.1004715Z",
                "modified": null
            },
            {
                "webhook": {
                    "name": "Customer Created/Updated",
                    "description": null,
                    "method": "POST",
                    "example_body": null,
                    "sorting": 22,
                    "webhook_type": 23,
                    "id": "62168d4e-ab2f-4fb1-938c-71797b763f3a",
                    "created": "2022-01-07T02:22:49.367",
                    "modified": null
                },
                "url": "",
                "id": "00000000-0000-0000-0000-000000000000",
                "created": "2022-09-21T16:00:30.1004725Z",
                "modified": null
            }
        ];
        
        let ws = PartnerWebhookModel.fromJsonArray(array);
        setMerchantWebhooks(ws);
        
        await MerchantService.instance.getWebhooksAsync(merchantId).then((wh) => {
            if (Array.isArray(wh) && wh.length > 2)
                setMerchantWebhooks(wh);
        });
    };

    const onMerchant = (m) => {
        setMerchant(m);
    };
    
    useEffect(() => {
        _ = getPartnerWebhooksAsync();
    }, []);

    const empty = (<i>Not Entered</i>);
    
    const createPayload = (partnerWebhook, url) => {
        if (typeof url === 'undefined') url = partnerWebhook.url;
        
        return {
            webhookId: partnerWebhook.webhookId,
            url: url
        };
    };
    
    const showEditForm = (partnerWebhook, e) => {
        const editor = createPayload(partnerWebhook);
        setWebhookEditor(editor);
    };
    
    const cancelEditor = (e) => {
        setWebhookEditor(null);
    };

    const onWebhookUrlChange = (partnerWebhook, e) => {
        const editor = createPayload(partnerWebhook, e.target.value);
        setWebhookEditor(editor);
    };

    const updateWebhookUrlAsync = async (w, e) => {
        setError({});
        const webhookId = w.webhook?.id || (w.id || webhookEditor.webhookId || "");
        const url = w.url || (webhookEditor?.url || "");
        
        await MerchantService.instance.updateWebhookUrlAsync(url, webhookId, merchantId).then((model) => {
            let index = merchantWebhooks.findIndex((p) => p.webhookId === webhookId);
            _ = getPartnerWebhooksAsync();
            merchantWebhooks[index].url = model.url;
            setMerchantWebhooks(merchantWebhooks);
        }).catch((ex) => { 
            const err = ErrorModel.createFormError(ex);
            console.error(JSON.stringify(err));
            setError(err);
        });
        
        cancelEditor(e);
    };

    const getError = (w) => {
        const id = w.webhook?.id || w.id;
        if (!!error[id]) return error[id];

        return null;
    };
    
    const webhookElements = merchantWebhooks?.length > 0 ? merchantWebhooks.map((w) => {
        const formIsOpen = webhookEditor?.webhookId === w.webhookId;
        const errorMessage = getError(w);
        
        return (
            <div key={w.webhook.id} className={"webhook-item" + (formIsOpen ? " show" : "")}>
                <span className={"hover-edit float-right padded"}>
                    <a onClick={showEditForm.bind(this, w)}><FontAwesomeIcon icon={faEdit} /> Edit</a>
                </span>
                <div className={"webhook-item-display"}>
                    <h4>{w.webhook.name}</h4>
                    <p>{ (formIsOpen ? null : errorMessage) || (w.url || empty) }</p>
                </div>
                <div className={"webhook-url"}>
                    <label htmlFor={"webhook-url-" + w.id}>Webhook Post-to Url: </label>
                    <div className="webhook-url-inputs">
                        <input id={"webhook-url-" + w.id} value={webhookEditor === null ? w.url : webhookEditor?.url} onChange={onWebhookUrlChange.bind(this, w)} type={"text"} />
                        <button onClick={(e) => updateWebhookUrlAsync(w.webhook, e)}>Update</button>
                        <a onClick={cancelEditor}>Cancel</a>
                    </div>
                </div>
                <div className={"form-error"}>{ formIsOpen ? errorMessage : null}</div>
            </div>
        );
    }) : (merchantWebhooks === null ? (<LoadingBox>Loading Webhooks...</LoadingBox>) : (<EmptyBox>No Webhooks :(</EmptyBox>));
    
    const submenu = !!merchant ? (<MerchantWebhooksSubMenu partner={merchant} selection="" />) : null;
    
    return (
        <MerchantMasterPage title={"Webhooks"} onMerchant={onMerchant} selection={"webhooks"}>
            { submenu }
            <p>
                Webhooks are posted from the following triggers:
            </p>

            <div className={"form"}>
                {webhookElements}
            </div>
            
        </MerchantMasterPage>
    );
    
};

export default MerchantWebhooksScreen;
