import React from 'react';
import {Link} from "react-router-dom";
import {
    faAddressCard,
    faPeopleGroup,
    faAnchor,
    faFolderOpen,
    faCreditCard,
    faIdCardClip,
    faLock,
    faFileWaveform,
    faBook,
    faRectangleList,
    faClipboardList,
    faClipboardCheck,
    faMoneyCheckDollar,
    faGear,
    faMobileRetro, faLocationDot
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@mui/material";

const MerchantSubMenu = (props) => {
    let selections = {};
    const hasTooltip = props.hasTooltip === true;
    let merchant =  props.merchant || props.partner;
    let merchantId = merchant?.id;
    
    let selector = props.selection || 'overview';
    selections[selector] = 'selected';
    
    let menuItems = [
        { key: "overview", link: '/merchants/' + merchantId, icon: faIdCardClip, caption: "Overview" },
        { key: "locations", link: '/merchants/' + merchantId + '/locations', icon: faLocationDot, caption: "Locations" },
        { key: "sales", link: '/merchants/' + merchantId + '/sales', icon: faMoneyCheckDollar, caption: "Sales" },
        { key: "catalogs", link: '/merchants/' + merchantId + '/catalogs', icon: faBook, caption: "Product Catalogs" },
        { key: "users", link: '/merchants/' + merchantId + '/users', icon: faPeopleGroup, caption: "Users" },
        { key: "card-readers", link: '/merchants/' + merchantId + '/card-readers', icon: faMobileRetro, caption: "Card Readers" },
        { key: "edit", link: '/merchants/' + merchantId + '/edit', icon: faFolderOpen, caption: "Edit Details" },
        //{ key: "billing", link: '/merchants/' + merchantId + '/billing', icon: faCreditCard, caption: "Billing Details" },
        { key: "webhooks", link: '/merchants/' + merchantId + '/webhooks', icon: faAnchor, caption: "Webhooks" }
    ];
    
    const getUnwrappedItem = (item, index) => {
        return (<li key={index} className={selections[item.key]}><Link to={item.link}><FontAwesomeIcon icon={item.icon} /> {item.caption}</Link></li>);
    };
    
    const getWrappedItem = (item, index) => {
        let menuItem = getUnwrappedItem(item, index);
        return (<Tooltip key={"tool-tip-" + index} placement={"left"} arrow={true} title={item.caption}>{menuItem}</Tooltip>);
    };
    
    const getItem = hasTooltip ? getWrappedItem : getUnwrappedItem;
    
    let menuElements = menuItems.map((item, index) => {
        return getItem(item, index);
    });
    
    return (
        <div className="sub-column" id={"merchant-sub-menu"}>
            <ul>
                <li className="sub-menu-header">
                    { props.title || (merchant?.name || "More")}
                </li>
                {menuElements}
            </ul>
        </div>
    );
};

export default MerchantSubMenu;
