import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import MerchantLocationModel from "../models/MerchantLocationModel";
import MerchantModel from "../models/MerchantModel";
import MerchantService from "./MerchantService";
import {config} from "@fortawesome/fontawesome-svg-core";
import MerchantConfigurationModel from "../models/MerchantConfigurationModel";
import MerchantTipModel from "../models/MerchantTipModel";
import MerchantTipConfigurationModel from "../models/MerchantTipConfigurationModel";

class MerchantLocationService {
    static instance = new MerchantLocationService();

    constructor() {
        this.merchantLocationsMap = {};
        this.merchantLocations = [];
        this.merchantLocationMap = {};
    }

    async getMerchantLocationAsync(locationId) {
        const path = '/api/merchant-location/' + locationId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.merchantLocationMap[locationId] = new MerchantLocationModel(response.data);
            return me.merchantLocationMap[locationId];
        });
    }

    async getMerchantWithLocationAsync(merchantId, locationId) {
        const path = "/api/merchant/" + merchantId + "/location/" + locationId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            return new MerchantModel(response.data);
        });
    }

    async getMerchantLocationsAsync(merchantId) {
        const path = "/api/merchant/" + merchantId + "/location";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.merchantLocations = response.data.map((merchantLocation) => {
                return new MerchantLocationModel(merchantLocation);
            });

            return me.merchantLocations;
        });
    }
    
    async updateConfigurationAsync(locationId, configName, value) { 
        if (!configName) throw "Configuration name is missing [configName]";
        
        const path = "/api/merchant-location/" + locationId + "/configuration";
        const me = this;

        const data = {
            name: configName,
            value: value
        };
        
        return await HttpService.instance.postAsync(path, data).then((response) => {
            return new MerchantConfigurationModel(response.data);
        });
    }
    
    async getTipConfigurationAsync(locationId) { 
        const path = "/api/merchant-location/" + locationId + "/tips";
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => {
            return new MerchantTipConfigurationModel(response.data);
        });
    }
    
    async updateTipConfigurationAsync(locationId, tipJson) {
        const path = "/api/merchant-location/" + locationId + "/tips";
        const me = this;

        console.warn(JSON.stringify(tipJson));
        
        return await HttpService.instance.postAsync(path, tipJson).then((response) => {
            return new MerchantTipConfigurationModel(response.data);
        });
    }
    
    async updateLocationAsync(locationJson, id) {
        if (!id) {
            id = locationJson.id;
            delete locationJson.id;
        }
        
        if (!locationJson.merchant_id) throw "MerchantId is missing [merchant_id]";
        
        let path = "/api/merchant-location";
        if (id) path += "/" + id;
        
        const me = this;

        return await HttpService.instance.postAsync(path, locationJson).then((response) => {
            let location = new MerchantLocationModel(response.data);
            me.merchantLocationMap[location.id] = location;

            setTimeout(() => {
                MerchantService.instance.getMerchantAsync(location.merchantId);
            }, 100);

            return location;
        });
    }
}

export default MerchantLocationService;
