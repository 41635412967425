import React from 'react';
import {Link} from "react-router-dom";

const MerchantWebhooksSubMenu = (props) => {
    const { partner, selection } = props;

    const partnerId = partner.id;

    let sel = selection || 'urls';
    let selections = {};
    selections[sel] = 'selected';

    return (
        <div className="submenu merchant">
            <Link className={selections['urls']} to={'/merchants/' + partnerId + '/webhooks'}>Webhook Urls</Link>
            <Link className={selections['logs']} to={'/merchants/' + partnerId + '/webhooks/logs'}>Post Logs</Link>
        </div>
    );
    
};

export default MerchantWebhooksSubMenu;
