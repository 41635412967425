import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantModel from "../../components/merchants/models/MerchantModel";
import MasterScreenMain from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import MerchantSubMenu from "../../components/merchants/ui/MerchantSubMenu";
import MerchantEffect from "../../components/merchants/services/MerchantEffect";
import MerchantMasterPage from "./MerchantMasterPage";
import RefreshButton from "../../components/common/ui/RefreshButton";
import ErrorModel from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import LoadingBox from "../../components/common/ui/LoadingBox";
import EmptyBox from "../../components/common/ui/EmptyBox";

const CardReadersScreen = (props) => {
    const { merchantId } = useParams();

    let _;
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null);
    const [cardReaders, setCardReaders] = useState(MerchantService.instance.cardReadersMap[merchantId] || null);
    const [error, setError] = useState({});
    
    const getCardReadersAsync = async (force) => {
        setError({});
        
        if (!merchantId || merchantId.length < 30) return;
        if (!force && cardReaders?.length > 0) return;

        const readers = await MerchantService.instance.getCardReadersAsync(merchantId).catch((ex) => { 
            const err = ErrorModel.createFormError(ex);
            setError(err);
        });

        if (Array.isArray(readers)) setCardReaders(readers);
    };
    
    const refreshReadersAsync = async () => {
        await getCardReadersAsync(true)
    };
    
    useEffect(() => {
        MerchantEffect.create(setMerchant, merchantId);
        _ = getCardReadersAsync();

    }, []);

    const subtitle = (<>{merchant?.name}:
        <span className="subtitle">Card Readers</span></>);
    
    const cardReaderElements = cardReaders?.length > 0 ? cardReaders.map((cr, i) => {
        return (
            <tr key={"card-reader-" + i} className="card-reader">
                <td className="card-reader-name">{cr.name}</td>
                <td className="card-reader-serial">{cr.serialNumber}</td>
                <td className="card-reader-id">{cr.deviceId}</td>
                <td className="card-reader-status">{cr.paymentProviderName}</td>
            </tr>
        );
    }) : (<tr><td className={"empty"} colSpan={4}>{ cardReaders === null ? (<LoadingBox>Loading Card Readers...</LoadingBox>) : (<EmptyBox>No Card Readers Available</EmptyBox>) }</td></tr>);
    
    const actions = [
        (<RefreshButton onClick={refreshReadersAsync} key={"refresh-action-001"}>Refresh</RefreshButton>)
    ];
    
    return (
        <MerchantMasterPage title={"Card Readers"} selection={"card-readers"} actions={actions}>
            <p>
                Card readers and other devices.
            </p>
            
            <div className={"form x-large"}>
                <table className={"table-x-large"} width={"100%"}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Serial</th>
                        <th>Device ID</th>
                        <th>Payment Provider</th>
                    </tr>
                    </thead>
                    <tbody>
                    {cardReaderElements}
                    </tbody>
                </table>
            </div>
        </MerchantMasterPage>

    );
    
};

export default CardReadersScreen;

