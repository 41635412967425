import React from 'react';
import {faBriefcase, faCloudSun, faComments, faGears, faRobot, faShieldHalved} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import SettingsMasterScreen from "@paylani/paylani-react-packages/dist/settings/ui/screens/SettingsMasterScreen";

const SettingsScreen = (props) => {
    
    return (
        <SettingsMasterScreen title={"Settings Main"} selection={"overview"}>
            <p>This section will allow you to manage your settings... Eventually.</p>

            <div id={"settings-main"}>
                <div className={"box dark round24"} style={{display: "none"}}>
                    <div>
                        <h4>Automation</h4>
                        <Link to={"/settings/automation/jobs"}><FontAwesomeIcon icon={faRobot} /></Link>
                        <p>Configure when jobs run and what they do</p>
                    </div>
                </div>

                <div className={"box dark round24"}>
                    <div>
                        <Link to={"/settings/company"}>
                            <h4>Company Information</h4>
                            <FontAwesomeIcon icon={faBriefcase} />
                            <p>Comany name and contact information</p>
                        </Link>
                    </div>
                </div>

                <div className={"box dark round24"}>
                    <div>
                        <Link to={"/settings/system-messages"}>
                            <h4>Platform Emails</h4>
                            <FontAwesomeIcon icon={faComments} />
                            <p>
                                Messages sent out from the platform
                            </p>
                        </Link>
                    </div>
                </div>

                <div className={"box dark round24"}>
                    <div>
                        <Link to={"/settings/pci"}>
                            <h4>Compliance</h4>
                            <FontAwesomeIcon icon={faShieldHalved} />
                            <p>
                                Policies and procedures, including PCI
                            </p>
                        </Link>
                    </div>
                </div>

                <div className={"box dark round24"}>
                    <div>
                        <Link to={"/settings/system"}>
                            <h4>Environments</h4>
                            <FontAwesomeIcon icon={faCloudSun} />
                            <p>
                                Change to/from Production and Staging
                            </p>
                        </Link>
                    </div>
                </div>

            </div>
            
        </SettingsMasterScreen>
        
    );
    
};

export default SettingsScreen;
