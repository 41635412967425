import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import ProductService from "../../components/products/services/ProductService";
import BackButton from "@paylani/paylani-react-packages/dist/common/ui/BackButton";
import ProductForm from "../../components/products/ui/ProductForm";
import MerchantMasterPage from "./MerchantMasterPage";
import ErrorModel from "@paylani/paylani-react-packages/dist/common/models/ErrorModel";

const MerchantProductEditScreen = (props) => {
    const { merchantId, productId, locationId } = useParams();

    let _;
    const [product, setProduct] = useState(null);
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId || ""] || null);
    const [location, setLocation] = useState(null);
    const [checkboxes, setCheckboxes] = useState({ is_tax_override: false, is_tracking_inventory: false});
    const [errors, setErrors] = useState({});

    const nameRef = useRef();
    const descriptionRef = useRef();

    const priceRef = useRef();
    const costPerRef = useRef();

    const taxRef = useRef();

    const skuRef = useRef();
    const itemCodeRef = useRef();
    const glCodeRef = useRef();

    const inventoryCountRef = useRef();

    const onMerchant = (m) => {
        if (!!m) {
            setMerchant(m);
        }
    };

    const onLocation = (loc) => {
        //
    };

    const onImageUploadComplete = (file) => {

    };

    const validate = (data) => {
        let er = {};

        if (!data.name || data.name.trim().length === 0)
            er.name = "Name is required";

        if (!data.price || data.price <= 0)
            er.price = "Price is required";

        setErrors(er);

        return (Object.keys(er).length === 0);
    };

    const updateProductAsync = async (data) => {

        await ProductService.instance.updateProductAsync(data, productId).then((product) => {
            if (!!product?.id) setProduct(product);
        }).catch((ex) => {
            setErrors(ErrorModel.createFormError(ex));
        });
    };

    const getProductAsync = async (force) => {
        if (!force && !!product) return product;

        return await ProductService.instance.getProductAsync(productId).then((p) => {
            if (!!p) setProduct(p);
        });
    };

    useEffect(() => {
        //
    }, [product]);

    useEffect(() => {
        _ = getProductAsync();
    }, []);

    const defaultTax = checkboxes.is_tax_override ? "0" : null;
    const backPath = "/";
    const subtitle = (<><BackButton /> {"Product Details: " + product?.name}</>);

    const productForm = !!product ? (<ProductForm product={product} />) : null;

    return (
        <MerchantMasterPage onMerchant={onMerchant} title={"Edit Product"} selection={"catalogs"}>
            <h3>{ subtitle }</h3>

            <div className={"product-list large"}>
                { productForm }
            </div>
        </MerchantMasterPage>
    );
};

export default MerchantProductEditScreen;
