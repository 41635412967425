import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import CustomerModel from "../models/CustomerModel";

class CustomerService { 
    static instance = new CustomerService();
    
    constructor() {
        this.customerMap = {};
    }
    
    async getCustomerAsync(customerId) {
        if (!customerId || customerId.length < 30) return null;
        
        const path = "/api/customer/" + customerId;
        const me = this;
        
        return await HttpService.instance.getAsync(path, false).then((response) => {
            return new CustomerModel(response?.data);
        });
    }
}

export default CustomerService;
