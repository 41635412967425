import React, {useEffect, useState, useRef} from 'react';
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantMasterPage from "./MerchantMasterPage";
import LoadingBox from "../../components/common/ui/LoadingBox";
import ErrorBox from "../../components/common/ui/ErrorBox";
import RefreshButton from "../../components/common/ui/RefreshButton";
import {Link} from "react-router-dom";

import PhoneNumber from "@paylani/paylani-react-packages/dist/common/formatting/PhoneNumber";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import SearchFilter from "@paylani/paylani-react-packages/dist/common/ui/search-filter/SearchFilter";
import ErrorModel from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

const MerchantsScreen = () => {
    const initialState = {
        merchants: MerchantService.instance.merchants || null,
        error: { top: "" },
        refreshState: 0,
    };

    const [pageState, setPageState] = useState(initialState);
    const [sortKey, setSortKey] = useState({ key: 'x', mult: 0 });
    const [session, setSession] = useState(AuthenticationService.instance.session || null);
    const [__, setCurrentPage] = useState(0);
    const [filterText, setFilterText] = useState("");
    const pageController = useRef(new PagerController(setCurrentPage)).current;
    let _;
    
    const merchants = pageState.merchants;
    const error = pageState.error;
    const refreshState = pageState.refreshState;
    
    const onSession = (sh) => {
        if (!!sh?.id) setSession(sh);
    };
    
    const setRefreshState = (state = 0) => {
        const err = state !== -1 ? {} : error;
        setPageState({ ...pageState, refreshState: state, error: err });
    };
    
    const setMerchants = (merchants) => {
        setPageState({ ...pageState, merchants: merchants, refreshState: 0 });
    };
    
    const setError = (err) => {
        if (typeof err === "string") err = { top: error };
        else if (typeof err !== "object") err = { top: "" };
        setPageState({ ...pageState, error: err, refreshState: -1 });
    };
    
    const getMerchantsAsync = async (force) => {
        if (!session?.id) {
            //console.error("SessionId is no good. Aborting getMerchantsAsync.");
            return;
        }
        
        if (!force && Array.isArray(merchants) && merchants.length > 0) {
            return merchants;
        }

        const ms = await MerchantService.instance.getMerchantsAsync().catch((ex) => {
            const err = ErrorModel.createFormError(ex);
            setError({ top: err.top || err.general });
        });

        if (Array.isArray(ms)) setMerchants(ms);
        else setRefreshState(0);

        return ms || merchants;
    };
    
    useEffect(() => {
        _ = getMerchantsAsync(pageState.refreshState === 1);
    }, [pageState, session]);

    const nameStyle = { width: '28%' };
    const countStyle = { width: '12%' };
    const emailStyle = { width: '25%' };
    const phoneStyle = { width: '14%' };
    
    const getTrimmedText = (text, max) => {
        if (!text) return '';
        if (text.length > max) {
            return text.substring(0, max) + '...';
        }
        
        return text;
    };

    const sortItems = (key) => {
        let mult = sortKey.key === key ? sortKey.mult * -1 : 1;
        setSortKey({ key: key, mult: mult });
    };

    let sortedMerchants = (!!filterText) ? 
        merchants.filter((m) => m.name.toLowerCase().includes(filterText.toLowerCase())) : 
        merchants;

    sortedMerchants = (sortKey.mult === 0) ? (sortedMerchants || []) : sortedMerchants.sort((a, b) => {
        const aa = a[sortKey.key];
        if (typeof aa === 'string') {
            const fieldA = (aa?.toString() ?? "").trim().toUpperCase().replaceAll(" ", "");
            const fieldB = (b[sortKey.key]?.toString() ?? "").trim().toUpperCase().replaceAll(" ", "");

            return fieldA.localeCompare(fieldB) * sortKey.mult;
        }

        return (aa - b[sortKey.key]) * sortKey.mult;
    });

    const merchantElements = pageController.mapLineItems(sortedMerchants, (merchant, index) => {
        let path = '/merchants/' + merchant.id;
        let emailElement = merchant.email ? (<a href={"mailto:" + merchant.email}>{merchant.email}</a>) : 'No Email on Record';
        
        return (
            <tr key={index}>
                <td style={nameStyle}>
                    <Link to={path}>{getTrimmedText(merchant.name, 24)}</Link>
                </td>
                <td className="small-cell" style={countStyle}><Link to={path + '/locations'}>{merchant.locationCount.formatNumber(0)}</Link></td>
                <td className="small-cell" style={countStyle}><Link to={path + '/users'}>{ merchant.userCount.formatNumber(0)}</Link></td>
                <td className="email-cell" style={emailStyle}>{emailElement}</td>
                <td className="phone-cell" style={phoneStyle}><PhoneNumber value={merchant.phone} defaultValue={'N/A'} /></td>
                <td className={"date"}><DateTime time={true} value={merchant.date} /></td>
            </tr>
        );
    });

    const refreshButton = (<RefreshButton onComplete={() => setRefreshState(0)} onClick={async () => getMerchantsAsync(true)}>Refresh</RefreshButton>);
    
    const subtitle = (<>
        <span className={"padded-right"}>Paylani Merchants</span>
        <SearchFilter onFilter={(text) => setFilterText(text)}>Search</SearchFilter>
        {refreshButton}
    </>);
    
    const activityElement = (merchants === null || refreshState === 1) ?
        (<LoadingBox>Loading Merchants...</LoadingBox>) :
        (!!error?.top ? (<ErrorBox>{ error.top }</ErrorBox>) : null);
    
    const loadingBox = merchants === null ? (<LoadingBox />) : null;
    return (
        <MerchantMasterPage title={subtitle} onSession={onSession}>
            { activityElement }
            <table className="table-x-large" width="100%">
                <thead>
                <tr>
                    <th style={nameStyle}><a onClick={() => sortItems("name")}>Name</a></th>
                    <th className="small-cell" style={countStyle}><a onClick={() => sortItems("locationCount")}>Locations</a></th>
                    <th className="small-cell" style={countStyle}><a onClick={() => sortItems("userCount")}>Users</a></th>
                    <th className="email-cell" style={emailStyle}><a onClick={() => sortItems("email")}>Email</a></th>
                    <th className="phone-cell" style={phoneStyle}>Phone</th>
                    <th className={"date"}><a onClick={() => sortItems("date")}>Modified</a></th>
                </tr>
                </thead>

                <tbody>
                { merchantElements }
                </tbody>

            </table>

            <Pager id="partner-pager-bottom" controller={pageController} items={merchants} />
            
        </MerchantMasterPage>
    );
    
};

export default MerchantsScreen;
