import React from 'react';
import MasterScreen from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {faArrowTrendUp, faGears} from "@fortawesome/free-solid-svg-icons";
import ReportingSubMenu from "../../components/reporting/ui/ReportingSubMenu";

const ReportingMasterPage = (props) => {
    const {
        title,
        subTitle,
        children,
        selector,
        selection,
        icon
    } = props;

    const subtitle = typeof title === 'object' ? title : (<h2>{ title }</h2>);
    const body = children || (<p>No body provided for SettingsMasterPage</p>);

    return (
        <MasterScreen selection="reporting" icon={faArrowTrendUp} title={"Reporting"}>
            <div className="pad">

                <div className="details-body">
                    <ReportingSubMenu selection={selection} />

                    <div className="pad">
                        {subtitle}
                        { body }
                    </div>
                </div>
            </div>
        </MasterScreen>
    );
};

export default ReportingMasterPage;

