import React, {useEffect, useState, useRef} from 'react';
import {useParams} from "react-router-dom";
import UserService from "../../components/people/services/UserService";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faAnchorCircleCheck, faCircleCheck,
    faClipboardCheck,
    faGear,
    faPersonWalking, faQuestion,
    faSkull, faSync
} from "@fortawesome/free-solid-svg-icons";
import MasterScreenMain from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import UserSubMenu from "../../components/people/ui/UserSubMenu";
import BackButton from "@paylani/paylani-react-packages/dist/common/ui/BackButton";
import RefreshButton from "../../components/common/ui/RefreshButton";
import PhoneNumber from "@paylani/paylani-react-packages/dist/common/formatting/PhoneNumber";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import MerchantSubMenu from "../../components/merchants/ui/MerchantSubMenu";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantEffect from "../../components/merchants/services/MerchantEffect";
import UserMasterPage from "./UserMasterPage";

const UserActivityHistoryScreen = (props) => {
    const { userId, merchantId } = useParams();

    let [user, setUser] = useState(UserService.instance.userMap[userId] || new UserModel());
    let [activityHistory, setActivityHistory] = useState([]);
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId || ''] || null);
    let [currentPage, setCurrentPage] = useState(0);
    let pageController = useRef(new PagerController(setCurrentPage, 24)).current;

    let _;
   
    const onMerchant = (merchant) => { 
        setMerchant(merchant);  
    };
    
    const onUser = (user) => {
        setUser(user);
    };
   
    const getActivityAsync = async (e) => {
        return await  UserService.instance.getActivityAsync(userId).then((activityHistory) => {
            console.log('Got: ' + userId);
            setActivityHistory(activityHistory);  
        });
    };
    
    const refreshUser = () => {
        _ = getActivityAsync();
    };

    useEffect(() => {
        if (merchantId) MerchantEffect.create(setMerchant, merchantId);
        refreshUser();
    }, []);

    const d = (new Date()).addMinutes(-10);
    
    
    const getActivityIcons = () => {
        return {
            '0': {icon: faCircleCheck, className: 'x'},
            'SYNC': {icon: faSync, className: 'activity-sync'},
            '1': {icon: faQuestion, className: 'x'},
            'SENT': {icon: faCircleCheck, className: 'activity-sent'},
            'SENT-': {icon: faAnchorCircleCheck, className: 'x'},
            '4': {icon: faClipboardCheck, className: 'x'},
            '5': {icon: faSkull, className: 'x'},
            '6': {icon: faGear, className: 'x'},
        }
    };
    
    const getIcon = (activity) => {
        let activityIcons = getActivityIcons();
        let icon = activityIcons[activity.action + '-' + activity.status];
        
        if (!icon) icon = activityIcons[activity.action];
        if (!icon) icon = activityIcons['0'];
        
        if (activity.severity === 2) icon.className += ' activity-warning';
        if (activity.severity === 3) icon.className += ' activity-error';
        if (activity.severity > 3) icon.className += ' activity-critical';
        
        return icon;
    };
    
    let activityElements = pageController.mapLineItems(activityHistory, (activity) => {
        let display = getIcon(activity);
        let icon = display?.icon;
        let color = display?.color || 'rgba(255, 255, 255, 0.6)';
        
        return (
            <tr key={activity.id} className={display.className}>
                <td className={"activity-text-td"}><span className={"icon"}><FontAwesomeIcon icon={icon} /></span> {activity.text}</td>
                <td className={"activity-date-td"}><DateTime value={activity.created.toDate()} time={true} /></td>
            </tr>
        );
    });

    let backPath = "/people";
    let masterMenuSelection = "users";
    let tableClassName = "";
    
    let h1 = (<h1><FontAwesomeIcon icon={faPersonWalking} /> Users</h1>);
    let submenu = (<UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selection={"activity"} />)

    if (merchantId && merchantId.length > 10) {
        tableClassName = "unfixed";
        masterMenuSelection = "merchants";
        backPath = "/merchants/" + merchantId + "/users";
        h1 = (<h1><FontAwesomeIcon icon={faAddressCard} />
            <span className={"subtitle"}>Merchant:</span>
            {merchant?.name}
        </h1>);

        submenu = (
            <>
                <div className={"sub-sub-menu-container"}><MerchantSubMenu hasTooltip={true} merchant={merchant} selection={"users"} /></div>
                <UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selection={"activity"} />
            </>
        );
    } else {
        //
    }
    
    return (
        <UserMasterPage onUser={onUser} onMerchant={onMerchant} title={"Activity History"} selection={"activity"}>
            <p>
                Below is a list of all the activities {user.name} has engaged in on the platform
            </p>

            <div id="user-activity-body">
                <table className={"table-x-large " + tableClassName} width={"100%"}>
                    <thead>
                    <tr>
                        <th className={"activity-text-td"}>Activity</th>
                        <th className={"activity-date-td"}>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {activityElements}
                    </tbody>
                </table>

                <Pager controller={pageController} items={activityHistory} />
            </div>            
        </UserMasterPage>        
    );
    
};

export default UserActivityHistoryScreen;
