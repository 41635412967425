import React, {useEffect} from 'react';

const ConfigDropdown = (props) => {
    const { onChange, location, config, value, onComplete } = props;

    const [mode, setMode] = React.useState(0);
    const valueRef = React.useRef();
    
    const onConfigChange = (e) => {
        if (mode === 1) return;
        setMode(1);
    };
    
    const handleComplete = (rsp) => {
        if (typeof onComplete === 'function') onComplete(rsp);
        setTimeout(() => {
            setMode(0);
        }, 1000);
    };
    
    const handleChangeAsync = async () => {
        const val = valueRef.current?.value;
        console.log("Changed: " + val);
        
        if (typeof onChange !== 'function') {
            setMode(3);
            return;
        }

        const rsp = onChange(val);

        if (typeof rsp.then === 'function') {
            await rsp.then((r) => {
                setMode(3);
            }).catch((ex) => {
                setMode(2);
            });
        } else { 
            setMode(3);
        }
    };
    
    const getClassName = () => {
        switch (mode) { 
            case 1: return " working";
            case 3: return " success";
        }
        
        return "";
    };
    
    useEffect(() => {
        switch (mode) { 
            case 0:
                break;
            case 1:
                let _ = handleChangeAsync();
                break;
            case 2: // Error
                setTimeout(() => {
                    setMode(0);
                }, 1500);
                break;
            case 3:
                handleComplete();
                break;
        }
        
    }, [mode]);
    
    const properCase = (text) => {
        let words = text.split(' ');
        let result = [];
        
        for (let i = 0; i < words.length; i++) {
            const word = words[i];
            const first = word.charAt(0).toUpperCase();
            const rest = word.slice(1);
            
            result.push(first + rest);
        }
        
        return result.join(' ');
    };
    
    const configItems = config.valueOptions.split('|');
    if (configItems.length > 1) configItems.splice(0, 0, "");
    
    const items = configItems.map((item, index) => {
        const text = !!item && item.length > 0 ? properCase(item.replaceAll("_", " ")) : "None";
        return (<option key={index} value={item?.toString().toLowerCase()}>{text}</option>);
    });

    const cn = getClassName();
    
    return (
        <span className={"form config-dropdown" + cn}>
        <select ref={valueRef} onChange={onConfigChange} defaultValue={value?.toLowerCase()}>{items}</select>
        </span>);
};

export default ConfigDropdown;
