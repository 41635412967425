class ReportResponseModel {

    constructor(json) {
        if (!json) json = {};
        this.itemCount = json?.item_count || 0;
        this.locationIds = json?.location_ids || [];
        this.downloadUrls = Array.isArray(json?.download_urls) ? json.download_urls : [];

        this.startDate = json?.start_date ? new Date(json.start_date) : null;
        this.endDate = json?.end_date ? new Date(json.end_date) : null;
    }
}

export default ReportResponseModel;
