import React, {useEffect, useState, useRef} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantModel from "../../../components/merchants/models/MerchantModel";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import MerchantEffect from "../../../components/merchants/services/MerchantEffect";
import MerchantMasterPage from "../MerchantMasterPage";
import AddNewButton from "@paylani/paylani-react-packages/dist/common/ui/AddNewButton";

const LocationsScreen = (props) => {
    const {merchantId} = useParams();

    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    const [currentPage, setCurrentPage] = useState(0);
    const [sortState, setSortState] = useState({sortField: null, mult: 1});
    const pagerController = useRef(new PagerController(setCurrentPage)).current;
    
    const sortBy = (field) => {
        const mult = sortState.sortField === field ? sortState.mult * -1 : 1;
        setSortState({sortField: field, mult: mult});
    };

    useEffect(() => { 
        if (!!merchant?.locations && !!sortState?.sortField) { 
            merchant.locations = merchant.locations.sort((a, b) => {
                const aVal = a[sortState.sortField];
                const bVal = b[sortState.sortField];
                
                if (typeof aVal === "string") return aVal.localeCompare(bVal) * sortState.mult;
                if (typeof aVal === "number") return (aVal - bVal) * sortState.mult;
                
                if (typeof aVal.getTime === 'function')
                    return (aVal.getTime() - bVal.getTime()) * sortState.mult;
                
                return 0;
            });
        }
    }, [sortState]);
    
    useEffect(() => {
        MerchantEffect.create(setMerchant, merchantId);

    }, []);

    let locationElements = pagerController.mapLineItems(merchant.locations, ((location, index) => {
        return (
            <tr key={index}>
                <td><Link to={"/merchants/" + merchantId + "/locations/" + location.id}>{location.name}</Link></td>
                <td>{location.place}</td>
                <td><DateTime value={location.modified || location.created} /></td>
            </tr>
        );
    }));

    const s = merchant.locationCount === 1 ? "" : "s";
    const actions = [(<AddNewButton key={"action-0"} icon={faLocationDot} to={"/merchants/" + merchantId + "/locations/000-000/edit"}>Add Location</AddNewButton>)];

    return (
        <MerchantMasterPage title={"Locations"} selection={"locations"} actions={actions}>
            <p>
                A merchant can have multiple locations. {merchant?.name} has <strong>{merchant?.locations.length || (merchant?.locationCount || 0)} active location{s}</strong> in use.
            </p>

            <table width={"100%"} className={"table-x-large"}>
                <thead>
                <tr>
                    <th><a onClick={() => sortBy('name')}>Name</a></th>
                    <th><a onClick={() => sortBy('place')}>Geography</a></th>
                    <th><a onClick={() => sortBy('created')}>Date</a></th>
                </tr>
                </thead>
                <tbody>
                {locationElements}
                </tbody>
            </table>

            <Pager items={merchant.locations} controller={pagerController} />            
        </MerchantMasterPage>
    );
    
};

export default LocationsScreen;
