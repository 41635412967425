import ReportItemModel from "./ReportItemModel";

class DashboardDataModel { 
    constructor(json) {
        if (!json) json = {};
        
        this.id = "D-" + (new Date()).getTime().toString()
        this.salesVolume = json.sales_volume || 0;
        this.saleCount = json.sale_count || 0;
        this.activeCount = json.active_count || 0;
        this.lastSaleDate = json.last_sale_date ? new Date(json.last_sale_date) : null;
        this.lastRegisterDate = json.last_register_date ? new Date(json.last_register_date) : null;
        
        this.paymentStatusCounts = json.status_counts || [];
        
        this.startDate = json.start_date ? new Date(json.start_date) : null;
        this.endDate = json.end_date ? new Date(json.end_date) : null;
    }
}

export default DashboardDataModel;
