import React from 'react';
import MasterScreen from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGears} from "@fortawesome/free-solid-svg-icons";
import SettingsSubMenu from "@paylani/paylani-react-packages/dist/settings/ui/SettingsSubMenu";

const MessagingScreen = (props) => {

    return (
        <MasterScreen selection="settings">
            <div className="pad">
                <h1><FontAwesomeIcon icon={faGears} /> Settings</h1>

                <div className="details-body">
                    <SettingsSubMenu selection="messaging" />

                    <div className="pad">
                        <h2>Messaging Credentials</h2>

                    </div>
                </div>
            </div>
        </MasterScreen>
    );
};

export default MessagingScreen;
