import React from 'react';

class DialogBoxController { 
    constructor(title, body) {
        this.title = title || "";
        this.body = body || null;
        this.payload = null;
        this.objects = {};
        
        this.open = () => {
        };
        
        this.close = () => {
            //
        };
    }
    
    async setBody(body) {
        this.body = body;
        return this.body;
    }
    
    async setTitle(title) {
        this.title = title;
        return this.title;
    }
}

export default DialogBoxController;
