import React, {useEffect, useRef, useState} from 'react';
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";

import SaleService from "../../../components/sales/services/SaleService";
import MerchantMasterPage from "../MerchantMasterPage";
import AddNewButton from "@paylani/paylani-react-packages/dist/common/ui/AddNewButton";
import RefreshButton from "../../../components/common/ui/RefreshButton";
import SalesTable from "../../../components/sales/ui/SalesTable";

const SalesScreen = (props) => {
    const {merchantId} = useParams();

    let _;
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null);
    
    const getSales = async (force) => {
        return await SaleService.instance.getSalesByMerchantAsync(merchantId).then((saleModels) => {
            return saleModels;
        });
    }
    
    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };
    
    useEffect(() => {
        //
    }, []);

    const merchantSales = SaleService.instance.salesMap[merchantId] || null;
    const salesTable = !!merchant?.id ? (<SalesTable merchantId={merchantId} onFetch={getSales} defaultValue={merchantSales} />) : null;
    const createSalePath = "/merchants/" + merchantId + "/virtual-terminal";
    
    const actions = [
        (<AddNewButton key={"action-0"} icon={faLocationDot} to={createSalePath}>New Sale</AddNewButton>),
        (<RefreshButton key={"refresh-action"}>Refresh</RefreshButton>)
    ];

    return (
        <MerchantMasterPage title={"Sales History"} onMerchant={onMerchant} selection={"sales"} actions={actions}>
            <p>
                Below are the sales history for all locations under {merchant?.name}.
            </p>
            
            { salesTable }         
        </MerchantMasterPage>
    );
    
};

export default SalesScreen;