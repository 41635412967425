import React from 'react';
import CommonMasterScreen from "@paylani/paylani-react-packages/dist/common/ui/screens/CommonMasterScreen";
import SupportTicketDetails from "@paylani/paylani-react-packages/dist/support/ui/SupportTicketDetails";

const TicketDetailsScreen = () => {

    return (
        <CommonMasterScreen selection="support">
             <SupportTicketDetails />
        </CommonMasterScreen>
    );

};

export default TicketDetailsScreen;
