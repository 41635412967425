import React from 'react';
import MasterScreenMain from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp} from "@fortawesome/free-solid-svg-icons";
import ReportingSubMenu from "../../components/reporting/ui/ReportingSubMenu";
import ReportingMasterPage from "./ReportingMasterPage";

const TrendsScreen = (props) => {
    const {} = props;

    return (
        <ReportingMasterPage selection="trends" title={"Trends"}>
            <p>
                Reporting is currently under construction.
            </p>


        </ReportingMasterPage>
    );
};

export default TrendsScreen;
