import React, { useState } from 'react';
import MasterScreen from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComments } from "@fortawesome/free-solid-svg-icons";
import "@paylani/paylani-react-packages/dist/messaging/ui/Messaging.css";
import PlatformMessageModel from "@paylani/paylani-react-packages/dist/settings/models/PlatformMessageModel";
import {useParams} from "react-router-dom";
import BackButton from "@paylani/paylani-react-packages/dist/common/ui/BackButton";
import SettingsSubMenu from "@paylani/paylani-react-packages/dist/settings/ui/SettingsSubMenu";

const MessageDetailsScreen = (props) => {
    const {messageId} = useParams();
    const [message, setMessage] = useState(props.message || PlatformMessageModel.getMockMessages().filter((m) => m.id === messageId)[0]);
    const [tagPos, setTagPos] = useState({ start: 0, end: 0, elementId: null });
    
    const onMessageChange = (fieldId, e) => {
        let value = e.target.value;
        setMessage((values => ({...values, [fieldId]: value})));
    };

    const onElementFocus = (fieldId, e) => {
        setTimeout(() => {
            onPositionChange(fieldId, e);
        }, 100);
    };

    const onPositionChange = (fieldId, e) => {
        let element = document.getElementById(fieldId);
        let startPos = element.selectionStart;
        let endPos = element.selectionEnd;

        let pos = { elementId: fieldId, start: startPos, end: endPos };
        setTagPos(pos);
    };

    const updateMessage = (e) => {
        //
    };
        
    const insertTag = (link, e) => {
        
        if (tagPos && tagPos.elementId) {
            let element = document.getElementById(tagPos.elementId);
            if (!element) return;
            
            let fieldId = tagPos.elementId;
            let value = element.value;
            let startPos = tagPos.start;
            let endPos = tagPos.end;

            value = value.substring(0, startPos) + '<mg:' + link + ' />' + value.substring(endPos);

            setMessage((values => ({...values, [fieldId]: value})));

            tagPos.end = startPos + link.length + 7;
            element.focus();
        }
    };
    
    let messageTags = message.tags.map((t, i) => {
        return (<a id={'tag-' + t.name} key={i + "-msg"} onClick={insertTag.bind(this, t.name)} className="tag">{t.name}</a>);
    });

    let messageTagsLabel = message.tags.length > 0 ? (<>Message Tags:</>) : (<></>);

    return (
        <MasterScreen selection="messaging">
            <div id="message-details" className="pad">
                <h1>
                    <FontAwesomeIcon icon={faComments} />
                    Messaging
                </h1>
                
                <div className="details-body">
                    <SettingsSubMenu selection="messages" />
                    
                    <div className="pad">
                        <h2><BackButton to="/settings/system-messages" /> {message.name}</h2>
                        <p>{messageTagsLabel} { messageTags }</p>
                        
                        <div className="form">
                            <div className="form-group">
                                <label>Subject:</label>
                                <input autoComplete="off" type="text" value={message.subject} onMouseUp={onPositionChange.bind(this, "subject")} onKeyUp={onPositionChange.bind(this, "subject")} onFocus={onElementFocus.bind(this, "subject")} onChange={onMessageChange.bind(this, "subject")} className="email-subject" id="subject" />
                            </div>
                            
                            <div className="form-group">
                                <label>Body Template:</label>
                                <textarea className="email-body" onKeyUp={onPositionChange.bind(this, "body")} value={message.body} onFocus={onElementFocus.bind(this, "body")} onChange={onMessageChange.bind(this, "body")} id="body" />
                            </div>
                            
                            <div className="button">
                                <button onClick={updateMessage}>Update Message Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MasterScreen>
        
    );
};

export default MessageDetailsScreen;
