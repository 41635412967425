import React from 'react';
import {Link} from "react-router-dom";

const LocationsSubMenu = (props) => {
    const { selection, location } = props;

    let sel = selection || 'main';
    let selections = {};

    selections[sel] = 'selected';

    let merchantId = location?.merchantId || "";
    let locationId = location?.id || "";
    
    return (
        <div className="submenu merchant">
            <Link className={selections['main']} to={'/merchants/' + merchantId + '/locations/' + locationId + '/'}>Main</Link>
            <Link className={selections['config']} to={'/merchants/' + merchantId + '/locations/' + locationId + '/configurations'}>Configurations</Link>
            <Link className={selections['edit']} to={'/merchants/' + merchantId + '/locations/' + locationId + '/edit'}>Edit Details</Link>
            <Link className={selections['sales']} to={'/merchants/' + merchantId + '/locations/' + locationId + '/sales'}>Sales</Link>
            <Link className={selections['catalogs']} to={'/merchants/' + merchantId + '/locations/' + locationId + '/products'}>Products</Link>
            <Link className={selections['tips']} to={'/merchants/' + merchantId + '/locations/' + locationId + '/tips'}>Default Tips</Link>
            <Link className={selections['fields']} to={'/merchants/' + merchantId + '/locations/' + locationId + '/fields'}>Custom Sales Fields</Link>
            <Link className={selections['content']} to={'/merchants/' + merchantId + '/locations/' + locationId + '/content'}>Content</Link>

        </div>
    );
    
};

export default LocationsSubMenu;
