import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import MerchantModel from "../models/MerchantModel";
import StatementHistoryItemModel from "../models/StatementHistoryItemModel";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import ConfigurationModel from "../models/ConfigurationModel";
import DashboardDataModel from "../../reporting/models/DashboardDataModel";
import CardReaderModel from "../../devices/models/CardReaderModel";
import ReportItemModel from "../../reporting/models/ReportItemModel";
import MerchantFieldModel from "../models/MerchantFieldModel";
class MerchantService { 
    static instance = new MerchantService();
    constructor(props) {
        this.merchantMap = {};
        this.locationMap = {};
        this.merchants = [];
        this.cardReadersMap = {};
        this.statementHistory = [];
        
        this.salesMonthlyMap = {};
        this.salesDailyMap = {};

        this.dashboardMap = {};
        this.locationsMap = {};
        this.usersMap = {};
        this.reportMap = {};
        
        let _ = this.getMerchantsAsync();
    }

    async getMerchantsAsync() { 
        const me = this;
        const url = '/api/merchant';
        
        const rsp = await HttpService.instance.getAsync(url).catch((err) => {
            console.error('Failed to get merchants: ' + err);
            delete this.isGetting;
            throw err;
        });

        delete this.isGetting;
        return me.createMerchantsList(rsp.data);
    }
    
    async getMerchantAsync(merchantId) {
        if (!merchantId) {
            console.error('Failed to get partner. Merchant id no good: ' + merchantId);
            return null;
        }

        const me = this;
        const url = '/api/merchant/' + merchantId;

        return await HttpService.instance.getAsync(url).then((rsp) => me.setMerchant(rsp.data));
    }

    async getMerchantSalesDailyAsync(merchantId, startDate, endDate) {
        if (!merchantId) {
            console.error("Invalid merchant id when getting sales daily");
            return null;
        }
        
        const me = this;
        const path = "/api/merchant/" + merchantId + "/report/sale-count/day";

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            const items = ReportItemModel.fromJsonArray(rsp?.data);
            me.salesDailyMap[merchantId] = items;

            return items;
        });
    }
    
    async getLocationSalesDailyAsync(locationId, startDate, endDate) {
        if (!locationId) throw new Error("Invalid location id when getting sales daily");
        const me = this;
        const path = "/api/merchant-location/" + locationId + "/report/sale-count/day";

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            const items = ReportItemModel.fromJsonArray(rsp?.data);
            me.salesDailyMap[locationId] = items;

            return items;
        });
    }
    
    /**
     * Gets a merchant by id, and also returns a single merchant location along with it.
     * The location is still inside the Merchant.location list, but it's the only list item.
     * If the locationId is not passed, it will default to getMerchantAsync() and return all locations.
     * @param merchantId {string!} The merchant id
     * @param locationId { string|null } The location id. If omitted, the function will fall back to getMerchantAsync() and return all locations
     * @returns {Promise<null|MerchantModel|*[]>}
     */
    async getMerchantWithLocationAsync(merchantId, locationId = null) {
        if (!locationId || locationId.length < 30) return await this.getMerchantAsync(merchantId);
        
        const me = this;
        const url = '/api/merchant/' + merchantId + '/location/' + locationId;

        return await HttpService.instance.getAsync(url)
            .then((rsp) => {
                let m = new MerchantModel(rsp.data)
                me.merchantMap[merchantId + "-" + locationId] = m;
                return m;
            })
            .catch((err) => {
                console.warn('Failed to get merchant location: ' + err);
                return [];
            });
    }

    /**
     * Gets all configuration names.
     * @param includeComplexConfigs { boolean } - If true, will include complex configs that require additional fields, like "Tipping" and "Product Catalog"
     * @returns {Promise<[]|*[]>}
     */
    async getConfigurationsAsync(includeComplexConfigs = false) {
        const me = this;
        const url = '/api/configuration';

        return await HttpService.instance.getAsync(url).then((rsp) => {
            me.configurations = ConfigurationModel.fromJsonArray(rsp.data);
            if (!includeComplexConfigs) return me.configurations?.filter((c) => !ConfigurationModel.editDetailsPath[c.name]);
            
            return me.configurations;
        }).catch((err) => {
            console.warn('Failed to get configurations: ' + err);
            return [];
        });
        
    };

    /**
     * Gets all the users associated with a given merchant
     * @param merchantId { string! }
     * @returns {Promise<[]>}
     */
    async getUsersAsync(merchantId) {
        const path = '/api/merchant/' + merchantId + '/user';
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            let users = UserModel.fromJsonArray(response.data);
            me.usersMap[merchantId] = users;

            return users;
        });
    }
    
    async getCardReadersAsync(merchantId) {
        const me = this;
        const path = '/api/merchant/' + merchantId + '/card-reader';
        
        return await HttpService.instance.getAsync(path).then((rsp) => {
            const cardReaders = CardReaderModel.fromJsonArray(rsp.data);
            me.cardReadersMap[merchantId] = cardReaders;
            
            return cardReaders;
        });
    };
    
    async getDashboardDataAsync(merchantId, startDate, endDate) {
        const me = this;
        const path = '/api/merchant/' + merchantId + '/report';

        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((rsp) => {
            let model = new DashboardDataModel(rsp?.data);
            me.dashboardMap[merchantId] = model;

            return model;
        });        
    }

    /**
     * Only updates basic information about the merchant: Name, email, phone, website.
     * Other methods should be used to update other information about the merchant.
     * @param merchantJson
     * @param id { string|null } - id of the merchant to update. If null, it will use the id in the merchantJson object (which will be removed before posting)
     * @returns {Promise<MerchantModel>}
     */
    async updateMerchantAsync(merchantJson, id = null) {
        if (!merchantJson) throw new Error("Invalid merchant json when updating merchant");
        
        const me = this;
        let path = "/api/merchant";

        if (!!id && id.length > 30) merchantJson.id = id;
        
        delete merchantJson.created;
        delete merchantJson.modified;
        
        if (merchantJson.id) {
            path += "/" + merchantJson.id;
            delete merchantJson.id;
        }
        
        return await HttpService.instance.postAsync(path, merchantJson).then((rsp) => {
            let m = new MerchantModel(rsp.data);
            me.merchantMap[m.id] = m;
            
            setTimeout(() => {
                me.getMerchantAsync(m.id);
            }, 100);
            
            return m;
        });
    }

    /**
     * Creates the master merchants list from the response data, then sets it to the merchants property of this server.
     * This is so we can use the list later without having to get it from the server again.
     * @param jsonArray
     * @returns {[]}
     */
    createMerchantsList(jsonArray) {
        this.merchants = [];

        for(let i = 0; i < jsonArray.length; i++) {
            let p = new MerchantModel(jsonArray[i]);
            
            if (!!p.id) {
                this.merchants.push(p);
                this.merchantMap[p.id] = p;
            }
        }

        return this.merchants;
    }

    /**
     * Not really used as of now. This is more of a cardsync thing.
     * @param partnerId
     * @param serviceDate
     * @returns {Promise<null|StatementHistoryItemModel>}
     */
    async generateStatementAsync(partnerId, serviceDate) {
        if (!partnerId) {
            console.error('Failed to get partner. Partner id no good: ' + partnerId);
            return null;
        }

        const me = this;
        const url = '/api/partner/' + partnerId;

        let payload = {};
        return await HttpService.instance.postAsync(url, payload).then((rsp) => {
            return new StatementHistoryItemModel(rsp.data);
        });
    };
    
    async getMerchantFieldsByLocationAsync(locationId) {
        const path = "/api/merchant-location/" + locationId + "/sales-field";
        return await HttpService.instance.getAsync(path).then((rsp) => {
            return MerchantFieldModel.fromJsonArray(rsp.data);
        });
    }

    async getMerchantFieldsByMerchantAsync(merchantId) {
        const path = "/api/merchant/" + merchantId + "/sales-field";
        return await HttpService.instance.getAsync(path).then((rsp) => {
            return MerchantFieldModel.fromJsonArray(rsp.data);
        });
    }
    
    async createSalesFieldAsync(locationId, jsonData) {
        const path = "/api/merchant-location/" + locationId + "/sales-field";
        
        return await HttpService.instance.postAsync(path, jsonData).then((rsp) => {
            return new MerchantFieldModel(rsp.data);
        });
    }

    async updateSalesFieldAsync(fieldId, jsonData) {
        const path = "/api/sales-field/" + fieldId;

        return await HttpService.instance.postAsync(path, jsonData).then((rsp) => {
            return new MerchantFieldModel(rsp.data);
        });
    }

    async deleteSalesFieldAsync(fieldId) {
        if (!fieldId || fieldId.length < 30) return {};
        const path = "/api/sales-field/" + fieldId;

        return await HttpService.instance.deleteAsync(path).then((rsp) => {
            return rsp.data;
        });
    }
    
    async getWebhooksAsync(merchantId) {
        return [];
    }
    
    async getWebhookLogsAsync(merchantId) {
        return [];
    }
    
    async updateWebhookUrlAsync(url, webhookId, merchantId) { 
        return {};
    }
    
    setMerchant(json) { 
        let p = MerchantModel.create(json, true);
        
        if (!!p.id) {
            this.merchantMap[p.id] = p;
        }
        
        return p;
    }
    
    setStatementHistory(jsonArray) {
        this.statementHistory = StatementHistoryItemModel.fromJsonArray(jsonArray);
        if (this.statementHistory.length > 0) this.statementHistory[0].status = 0;
        
        return this.statementHistory;
    }

    createSubMerchantsList(jsonArray) {
        let subMerchants = [];
        
        for(let i = 0; i < jsonArray.length; i++) {
            let m = new MerchantModel(jsonArray[i]);
            
            if (!!m.id) {
                subMerchants.push(m);
            }
        }

        if (jsonArray.length > 0) {
            let partnerId = jsonArray[0].partner_id;
            this.subMerchantsMap[partnerId] = subMerchants;
        }
        
        return subMerchants;
    }
}

export default MerchantService;
