import MerchantService from "./MerchantService";

class MerchantEffect {
    static create(setFunction, merchantId) {
        let _ = new MerchantEffect(setFunction, merchantId);
    }

    constructor(setFunction, merchantId) {
        this.setMerchant = setFunction;
        this.merchantId = merchantId;
        this.updateScreen();
    }

    updateScreen() {
        const me = this;
        MerchantService.instance.getMerchantAsync(this.merchantId).then((merchant) => {
            me.setMerchant(merchant);
            //console.warn("Set Merchant OK: " + merchant.name);
        });
    }
}

export default MerchantEffect;
