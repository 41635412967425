import React, { useState } from 'react';
import DateRangeSelector from "@paylani/paylani-react-packages/dist/common/ui/date-range-selector/DateRangeSelector";
import {Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faColumns, faDownload, faExpand } from "@fortawesome/free-solid-svg-icons";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

const ReportActionPanel = (props) => {
    const { dateRange, onRefresh, onClick, exportUrls} = props;
    const [range, setRange] = useState(dateRange);

    const onMenuItemClick = (e, id) => {
        if (typeof onClick === 'function') onClick(id, e);
    };
    
    const exportAs = (url) => { 
        
    }
    
    const exportMenuElement = ReportActionPanel.createExportMenu(exportUrls);
    
    const downloadAsElement = exportMenuElement != null ? (<Tooltip title={exportMenuElement} placement={"bottom-start"} arrow={true}>
            <span className={"item select-columns"}>
                <a onClick={(e) => onMenuItemClick('download', e)}><FontAwesomeIcon icon={faDownload} /> Download As</a>
            </span>
        </Tooltip>) : null;
    
    return (<div className={"report-selector-panel"}>
        <DateRangeSelector startDate={dateRange.startDate} endDate={dateRange.endDate} onClick={onRefresh} />
        <span className={"item spacer"}>&nbsp;</span>

        <Tooltip title={"Column Selector | A | B | C | D "} placement={"bottom-start"} arrow={true}>
            <span className={"item select-columns"}>
                <a onClick={(e) => onMenuItemClick('cols', e)}><FontAwesomeIcon icon={faColumns} /> Select Columns</a>
            </span>
        </Tooltip>
        <span className={"item select-columns"}>
            <a onClick={(e) => onMenuItemClick('expand', e)}><FontAwesomeIcon icon={faExpand} /> Expand</a>
        </span>
        { downloadAsElement }
    </div>);
};

ReportActionPanel.createExportMenu = (exportUrls) => {
    const sessionId = AuthenticationService.instance.sessionId;
    const exportMenuItems = !!exportUrls && Array.isArray(exportUrls) ?
        exportUrls.map((url, i) => {
            const extension = url.split("download=").pop();

            return (
                <a key={"export-url-" + i} href={url + "&session-id=" + sessionId} target={"_blank"} className={"export-item"}>{ extension }</a>
            );
        }) :
        [];

    return (exportMenuItems.length > 0) ? (<>Export as {exportMenuItems}</>) : null;
};

export default ReportActionPanel;

