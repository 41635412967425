import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import LocationMasterPage from "./LocationMasterPage";
import ProductService from "../../../components/products/services/ProductService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import "../../../components/products/ui/products.css";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import ProductList from "../../../components/products/ui/ProductList";

const LocationProductsScreen = (props) => {
    const {merchantId, locationId} = useParams();
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    const [location, setLocation] = useState(null);
    const [catalogId, setCatalogId] = useState(location?.productCatalogId || null);
    const [catalog, setCatalog] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const pagerController = useRef(new PagerController(0, 24, setCurrentPage)).current;
    
    let _;

    const onMerchant = (m) => {
        if (!!m) {
            setMerchant(m);
        }
    };

    const onLocation = (loc) => {
        if (!loc) return;
        if (!!loc.productCatalogId) setCatalogId(loc.productCatalogId);
    };

    const onCatalog = async (c) => {
        if (!!c) setCatalog(c);
    };
    
    useEffect(() => {
        onLocation(location);
    }, [location]);
    
    useEffect(() => {
        if (!merchant) return;
        
        const loc = merchant?.locations.find((l) => l.id === locationId) || null;
        if (!!loc) setLocation(loc);
        
    }, [merchant]);
    
    useEffect(() => {
        //
    }, []);

    const productElements = !!catalogId && catalogId.length > 3 ? (<ProductList locationId={locationId} catalogId={catalogId} onCatalog={onCatalog} />) : null;
    
    return (
        <LocationMasterPage onMerchant={onMerchant} onLocation={onLocation} title={"Product Catalogs"} selection={"catalogs"}>
            <p>
                <Link to={"/merchants/" + merchantId}>{location?.name}</Link> uses the product catalog <Link to={"/"}>{ catalog?.name }</Link>.
            </p>
            
            { productElements }
        </LocationMasterPage>
    );

};

export default LocationProductsScreen;
