import HttpService from "@paylani/paylani-react-packages/dist/common/services/HttpService";
import SaleModel from "../models/SaleModel";

class SaleService { 
    static instance = new SaleService();
    
    constructor() {
        this.salesMap = {};
        this.saleMap = {};
        this.sales = [];
        this.isGetting = {};
    }
    
    async getSalesByMerchantAsync(merchantId) {
        const keyGet = "sales-by-merchant-" + merchantId;
        if (!!this.isGetting[keyGet]) return null; //this.sales;

        const path = "/api/merchant/" + merchantId + "/sale";
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => {
            let saleModels = SaleModel.fromJsonArray(response.data);
            delete me.isGetting[keyGet];
            me.salesMap[merchantId] = saleModels;
            
            return saleModels;
        }).catch((ex) => {
            delete me.isGetting[keyGet];
            throw ex;
        });
    }

    async getSalesByLocationAsync(locationId) {
        const keyGet = "sales-by-location-" + locationId;
        if (!!this.isGetting[keyGet]) return null; //this.sales;

        const path = "/api/merchant-location/" + locationId + "/sale";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            let saleModels = SaleModel.fromJsonArray(response.data);
            delete me.isGetting[keyGet];
            me.salesMap[locationId] = saleModels;

            return saleModels;
        }).catch((ex) => {
            delete me.isGetting[keyGet];
            throw ex;
        });
    }
    
    async getSalesAsync(startDate, endDate) {
        const keyGet = "all-sales";
        if (!!this.isGetting[keyGet]) return null; //this.sales;
        
        const path = "/api/sale";
        const me = this;

        this.isGetting[keyGet] = true;
        
        return await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate).then((response) => {
            me.sales = SaleModel.fromJsonArray(response?.data);
            delete me.isGetting[keyGet];
            return me.sales;
        }).catch((ex) => {
            delete me.isGetting[keyGet];
            throw ex;
        });
        
    }
    
    async getSaleJsonAsync(saleId) {
        const path = "/api/sale/" + saleId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            return response.data;
        });

    }

    async getSaleAsync(saleId) { 
        const path = "/api/sale/" + saleId;
        const me = this;
        
        return await this.getSaleJsonAsync(saleId).then((json) => {
            const saleModel = new SaleModel(json);
            me.saleMap[saleId] = saleModel;
            
            return saleModel;
        });
    }
    
    async resendSaleAsync(saleId, destinations) {
        if (!destinations) {
            throw new Error("Invalid destination.");
        }
        
        if (!Array.isArray(destinations)) destinations = [destinations];
        
        const path = "/api/sale-api/" + saleId + "/send";
        const me = this;

        return await HttpService.instance.postAsync(path, destinations).then((response) => {
            return response.data;
        });
    }
    
    async createSaleAsync(saleJson, paymentMethod = "1") {
        const ext = (paymentMethod === "10") ? "/draft" : "";
        const path = "/api/sale-api" + ext;
        const me = this;
        
        return await HttpService.instance.postAsync(path, saleJson).then((response) => {
            console.log(JSON.stringify(response.data, null, 4));
            return new SaleModel(response.data);
        });
    };
}

export default SaleService;
