import MerchantModel from "../../merchants/models/MerchantModel";
import MerchantLocationModel from "../../merchants/models/MerchantLocationModel";

class TransactionReportItemModel { 
    static json = {
        "payment_type": 1,
        "payment_type_name": "Credit Card",
        "payment_status": 1,
        "payment_status_name": "Captured",
        "sale_status": 1,
        "sale_status_name": "Completed",
        "location_name": null,
        "merchant_name": null,
        "discount": 0.0,
        "subtotal": 6.66,
        "total": 7.32,
        "tax": 0.33,
        "tax_percent": 0.0,
        "tip": 0.0,
        "shipping": 0.0,
        "transaction_id": null,
        "auth_code": null,
        "customer_name": "VISA CARDHOLDER",
        "customer_email": "",
        "customer_phone": "",
        "cashier_name": "Bem Garrison",
        "payment_id": "da954e41-a2fe-4be9-b016-64c272450f48",
        "sale_id": "564bae81-56e1-4a3b-8099-5ab3f96b0cca",
        "customer_id": "56f9f322-b9df-4c4b-aa7f-6819dbfdd6b2",
        "user_id": "21cc1e2f-709a-42c7-9d4d-3284b5884904",
        "sale_number": 28492,
        "created": "2022-09-01T00:14:44.64"
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.paymentType = json.payment_type;
        this.paymentTypeName = json.payment_type_name;
        this.paymentDescription = json.payment_type_name || "";
        this.paymentStatus = json.payment_status;
        this.paymentStatusName = json.payment_status_name;
        this.saleStatus = json.sale_status;
        this.saleStatusName = json.sale_status_name;
        this.locationName = json.location_name;
        this.merchantName = json.merchant_name;
        this.cardType = json.card_type || 0;
        this.cardTypeName = json.card_type_name || "";
        this.discount = json.discount || 0;
        this.subtotal = json.subtotal || 0;
        this.total = json.total || 0;
        this.tax = json.tax || 0;
        this.taxPercent = json.tax_percent || 0;
        this.tip = json.tip || 0;
        this.shipping = json.shipping || 0;
        this.transactionId = json.transaction_id || "";
        this.authCode = json.auth_code;
        this.customerName = json.customer_name;
        this.customerEmail = json.customer_email;
        this.customerPhone = json.customer_phone;
        this.cashierName = json.cashier_name;
        this.paymentId = json.payment_id;
        this.saleId = json.sale_id;
        this.customerId = json.customer_id;
        this.userId = json.user_id;
        this.saleNumber = json.sale_number;
        this.merchantId = json.merchant_id || "";
        this.locationId = json.location_id || "";
        this.created = json.created ? new Date(json.created) : null;
        
        this.merchant = new MerchantModel(json.merchant);
        this.location = new MerchantLocationModel(json.location);
        
        if (this.cardType > 0) { 
            this.paymentDescription = this.cardTypeName + ' ' + this.paymentTypeName;
        } else { 
            
        }
    };
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map((json) => new TransactionReportItemModel(json));
    }
}

export default TransactionReportItemModel;