import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantSubMenu from "../../components/merchants/ui/MerchantSubMenu";
import MasterScreen from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";
import {faPersonWalking} from "@fortawesome/free-solid-svg-icons";
import UserService from "../../components/people/services/UserService";
import UserSubMenu from "../../components/people/ui/UserSubMenu";

const UserMasterPage = (props) => {
    const {
        title,
        onUser,
        defaultUser,
        defaultUserId,
        onMerchant,
        defaultMerchant,
        defaultMerchantId,
        defaultLocationId,
        onClick,
        children,
        force,
        icon,
        actions,
        selection,
        selector
    } = props;

    let _;
    const { userId, merchantId, locationId } = useParams();
    const initialMerchant = defaultMerchant || (MerchantService.instance.merchantMap[defaultMerchantId || merchantId] || null);
    const initialUser = defaultUser || (UserService.instance.userMap[defaultUserId || userId] || null);
    
    let [merchant, setMerchant] = useState(initialMerchant);
    let [user, setUser] = useState(initialUser);
    
    const getMerchantAsync = async (force) => {
        let mid = (merchantId || defaultMerchantId);
        if (!mid || mid.length < 30) return;
        
        const lid = (locationId || defaultLocationId);
        await MerchantService.instance.getMerchantAsync(mid).then((m) => {
            setMerchant(m);
        });
    };

    const getUserAsync = async (force) => {
        if (!userId || userId.length < 30) return;
        await UserService.instance.getUserAsync(userId).then((u) => {
            setUser(u);
        });
    };
    
    const refreshAsync = async (force) => {
        //
    };

    const getActionElements = () => {
        if (!actions || !Array.isArray(actions)) return null;

        return actions.map((a, i) => {
            return typeof a === 'object' ? a : (<span key={"action-" + i}>{a}</span>);
        });
    };

    useEffect(() => {
        if (merchant && typeof onMerchant === 'function') onMerchant(merchant);
    }, [merchant]);

    useEffect(() => {
        if (user && typeof onUser === 'function') onUser(user);
    }, [user]);
    
    useEffect(() => {
        _ = getMerchantAsync();
        _ = getUserAsync();
    }, []);

    const subtitle = typeof title === 'object' ? title : (<>{user?.name}: <span className="subtitle">{title || "Profile"}</span> { getActionElements() }</>);

    const body = children || (<p>No body for MerchantMasterPage</p>);
    const merchantMenu = !!merchant ? (<MerchantSubMenu merchant={merchant} selection={selection} />) : null;
    const bodyClass = !!merchantMenu ? " with-menu" : "";
    const submenu = user ? (<UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selection={selection} />) : null;

    return (
        <MasterScreen selection={selector || "users"} icon={faPersonWalking} title={"Users"} onClick={onClick}>
            <div className={"pad"}>

                <div className="details-body">
                    {submenu}

                    <div className={"pad" + bodyClass}>
                        <h2>{subtitle}</h2>

                        { body }
                    </div>
                </div>
            </div>
        </MasterScreen>
    );
};

export default UserMasterPage;

