import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import LocationMasterPage from "./LocationMasterPage";
import SaleService from "../../../components/sales/services/SaleService";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import AddNewButton from "@paylani/paylani-react-packages/dist/common/ui/AddNewButton";
import SalesTable from "../../../components/sales/ui/SalesTable";
import Controller from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import ErrorModel from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

const LocationSalesScreen = () => {
    let _;
    const {merchantId, locationId} = useParams();
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId + "-" + locationId] || null);
    const controller = useState(new Controller())[0];
    
    const getSalesAsync = async () => {
        console.log("Getting Location Sales...");
        
        const models = await SaleService.instance.getSalesByLocationAsync(locationId);
        console.log("Got Sales Good: " + (models?.length || 0).toString());
        return models;
    };
    
    const onSalesLoaded = (sales) => {
        console.warn("Sales loaded from <SalesTable /> (" + (typeof sales).toString() + ")");
    };
    
    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };

    const onLocation = (loc) => { 
        //
    };
    
    useEffect(() => {
        if (!!controller) controller.refreshAsync = getSalesAsync;
        
    }, []);

    const getSaleLocationLineItemName = (sale, defaultName = "Unknown") => {
        return sale.location?.name || defaultName;
    };

    const getSaleLineItemDescription = (sale) => {
        return getSaleLocationLineItemName(sale);
    }

    const location = merchant?.locations[0];
    const locationSales = SaleService.instance.salesMap[locationId] || null;
    const salesTable = !!merchant?.id ? (<SalesTable merchantId={merchantId} locationId={locationId} onFetch={getSalesAsync} controller={controller} sales={locationSales} />) : null;
    const actions = [(<AddNewButton key={"action-0"} icon={faLocationDot} to={"/merchants/" + merchantId + "/locations/" + locationId + "/virtual-terminal"}>New Sale</AddNewButton>)];

    return (
        <LocationMasterPage onMerchant={onMerchant} onLocation={onLocation} title={"Sales"} selection={"sales"} actions={actions} onRefresh={async (e) => controller?.refreshAsync(e)}>
            <p>
                Merchant Location Sales for <strong>{location?.name}</strong>
            </p>

            { salesTable }
        </LocationMasterPage>
    );

};

export default LocationSalesScreen;

