import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import LocationMasterPage from "./LocationMasterPage";

const LocationDetailsScreen = (props) => {
    const {merchantId, locationId} = useParams();

    let _;
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || null);
    
    const onMerchant = (m) => {
        setMerchant(m);
    };
    
    const onLocation = (l) => {
        
    };
    
    useEffect(() => {
        //
    }, []);
    
    const location = merchant?.locations[0];
    const testModeElement = location?.isTestMode ? (<label className={"test-mode"}>Test Mode</label>) : (<label className={"test-mode live"}>Live Mode</label>);

    return (
        <LocationMasterPage onMerchant={onMerchant} title={"Details"} selection={"main"}>
            <p>
                This merchant location appears as "{location?.name}" on reporting, and is for display purposes. The parent merchant is <Link to={"/merchants/" + merchantId}>{merchant?.name}</Link>.
            </p>
            <div>{testModeElement}</div>
        </LocationMasterPage>
    );
    
};

export default LocationDetailsScreen;
