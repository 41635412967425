import React, {useEffect, useRef, useState} from 'react';
import SaleReportingService from "../../components/reporting/services/SaleReportingService";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import LoadingBox from "../../components/common/ui/LoadingBox";
import {Link} from "react-router-dom";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import ReportingMasterPage from "./ReportingMasterPage";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {Drawer, Tooltip} from "@mui/material";
import ReportActionPanel from "../../components/reporting/ui/ReportActionPanel";
import EmptyBox from "../../components/common/ui/EmptyBox";

const TransactionReportScreen = (props) => {
    const utcNow = new Date().addDays(1);
    const [dateRange, setDateRange] = useState({startDate: new Date().addMonths(-1).toFirstOfTheMonth(), endDate: utcNow});

    let [report, setReport] = useState(SaleReportingService.instance.transactionReport || null);
    let [filterText, setFilterText] = useState('');
    let [expandedView, setExpandedView] = useState(false);
    
    let [currentPage, setCurrentPage] = useState(0);
    const pageController = useRef(new PagerController(setCurrentPage, 50)).current;
    let _;

    const getTransactionReportAsync = async (force) => {
        if (!!report?.items && report.items.length > 0 && !force) return;

        await SaleReportingService.instance.getTransactionReportAsync(dateRange?.startDate, dateRange?.endDate).then((rep) => {
            if (!rep) return;
            setReport(rep);
        });
    };

    const refreshAsync = async (sd, ed) => {
        setDateRange({startDate: sd, endDate: ed});
    };

    const onSelectColumns = (e) => { 
        //
    }
    
    useEffect(() => {
        if (report === null) {
            _ = getTransactionReportAsync(true);
        } else { 
            if (!report?.items?.length) { 
                console.warn("No items. Getting transactions (forced)");
            }
        }
    }, [report]);

    useEffect(() => {
        setReport(null);
    }, [dateRange]);

    useEffect(() => {
        _ = getTransactionReportAsync();
    }, []);

    const cardColumnStyle = {width: '120px'};
    const salesList = report?.items; //filterResults(recentSales);
    const salesElements = report?.items === null ? (<LoadingBox />) : pageController.mapLineItems(salesList, (item, i) => {

        return (
            <tr key={"transaction-" + i}>
                <td className={"payment-type"}>{ item?.paymentDescription }</td>
                <td className={"sale-number"} style={cardColumnStyle}>
                    <Link to={"/merchants/" + item.merchantId + "/sales/" + item.saleId}>#{item.saleNumber}</Link>
                </td>
                <td className={"big-name merchant-name"}>{item.merchant?.name}</td>
                <td className={"big-name location-name"}>{item.location?.name}</td>
                <td className={"trans-amount"}>{ item.total.formatCurrency(2) }</td>
                <td className={"customer-name"}>
                    <Link title={"Merchant: " + item.merchant?.name} to={"/merchants/" + item.merchantId + "/locations/" + item.merchantLocationId + "/sales"}>
                        { item.customerName || "No Name"}
                    </Link>
                </td>
                <td className={"trans-id"}>{ item.transactionId }</td>
                <td className={"payment-status"}>{ item.paymentStatusName }</td>
                <td className={"trans-date"}><DateTime value={item.created} time={true} on /></td>
            </tr>);
    });

    const reportElement = report?.items !== null ? (<table className="table-x-large small-text small-cells big-report" width="100%">
        <thead>
        <tr>
            <th className={"payment-type"}>Payment Type</th>
            <th className={"sale-number"} style={cardColumnStyle}>Sale #</th>
            <th className={"big-name merchant-name"}>Merchant</th>
            <th className={"big-name location-name"}>Location</th>
            <th className={"trans-amount"}>Amount</th>
            <th className={"customer-name"}>Origination</th>
            <th className={"trans-id"}>Transaction Id</th>
            <th className={"payment-status"}>Payment Status</th>
            <th className={"trans-date"}>Date</th>
        </tr>
        </thead>

        <tbody>
        {salesElements}
        </tbody>
    </table>) : salesElements;

    const anchor = "bottom";
    
    const onPanelItemClick = (e, key) => { 
        if (key === "expand") { 
            setExpandedView(!expandedView);
        }
    }
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setExpandedView(!expandedView);
    };

    const getExpandedView = () => { 
        return (<div style={{minHeight: "300px"}}>{reportElement}</div>);
    }
    
    const pager = (report?.items?.length || 0) > 0 ? (<Pager controller={pageController} items={report?.items} />) : null;
    const emptyBox = report?.items?.length === 0 ? (<EmptyBox>No transactions to report</EmptyBox>) : null;

    return (
        <ReportingMasterPage selection="transactions" title={"Transaction Report"}>
            <p>
                All transactions for all merchants and their respective locations.
            </p>

            <div className={"form"}>
                <ReportActionPanel dateRange={dateRange} onRefresh={refreshAsync} onClick={(key, id) => onPanelItemClick(key, id)} exportUrls={report?.downloadUrls} />
                
                { reportElement }
                { emptyBox }
                { pager }

            </div>
            <Drawer
                anchor={anchor}
                open={expandedView}
                onClose={toggleDrawer(anchor, false)}>
                <>
                    <div className={"report-selector-panel report-expanded-menu"}>
                        <h3>Transaction Report: Expanded View</h3>
                        <Tooltip title={ReportActionPanel.createExportMenu(report?.downloadUrls) || (<></>)} placement={"bottom-start"} arrow={true}>
                            <span className={"item select-columns"}>
                                <a onClick={(e) => null}><FontAwesomeIcon icon={faDownload} /> Download As</a>
                            </span>
                        </Tooltip>
                        
                        <a onClick={() => setExpandedView(false)}>Close</a>
                    </div>
                    { getExpandedView() }
                </>
                
            </Drawer>
        </ReportingMasterPage>
    );   
    
};

export default TransactionReportScreen;

