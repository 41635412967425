import React from 'react';
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

const LogoutScreen = (props) => {
    const {} = props;

    const signOut = (e) => {
        AuthenticationService.instance.session = null;
        AuthenticationService.instance.sessionId = null;
        
        console.log(localStorage?.getItem("session-id") || "No session-id");
        localStorage.clear();
        console.log("Cleared");
        // AuthenticationService.instance.signOutAsync().then(() => {
        //     window.location.href = "/";
        //
        // }).catch((error) => console.error('Ex: ' + error));
    };
    
    return (<div className={"pad"}>
        <h1>Logout</h1>
        <div>
            <a onClick={() => signOut()}>Sign Out</a>
        </div>
    </div>);
};

export default LogoutScreen;
