import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faComments} from "@fortawesome/free-solid-svg-icons";
import PlatformMessageModel from "@paylani/paylani-react-packages/dist/settings/models/PlatformMessageModel";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import {Link} from "react-router-dom";
import SettingsMasterScreen from "@paylani/paylani-react-packages/dist/settings/ui/screens/SettingsMasterScreen";

const MessagingScreen = (props) => {
    const {x} = props;

    let messages = PlatformMessageModel.getMockMessages().map((m) => new PlatformMessageModel(m));
    
    let messageList = messages.map((m) => {
        let messageTypeIcon = m.messageType === 'Email' ? (<FontAwesomeIcon icon={faEnvelope} />) : (<FontAwesomeIcon icon={faComments} />);
        
        return (<tr key={m.id}>
            <td><Link to={'/settings/system-messages/' + m.id}>{ messageTypeIcon } {m.name}</Link></td>
            <td className="type">{m.messageType}</td>
            <td><DateTime time={true} value={m.created} /></td>
        </tr>)
    });
    
    return (
        <SettingsMasterScreen title={"Platform Messages"} selection={"messages"}>
            <p>
                All emails and text messages (sms) that get sent out, platform-wide.
            </p>

            <table className="table-x-large" width="100%" id="messaging-list-table">
                <thead>
                <tr>
                    <th>&nbsp; Name</th>
                    <th className="type">Type</th>
                    <th>Created</th>
                </tr>
                </thead>

                <tbody>
                { messageList }
                </tbody>

            </table>
        </SettingsMasterScreen>
    );
};

export default MessagingScreen;
