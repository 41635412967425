import React from 'react';

const ErrorBox = (props) => {
    const { error, value, children, className } = props;
    const errorContent = (children || error) || (value || "An unknown error occurred");
    
    return (<div className={("error-box " + className).trim()}>
        { errorContent }
    </div>);
};

export default ErrorBox;
