import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressBook, faAddressCard, faAlignLeft, faBatteryHalf,
    faComments,
    faDiagramProject,
    faGears, faPersonWalking,
    faRobot,
    faSuitcase, faUnlockKeyhole
} from "@fortawesome/free-solid-svg-icons";
import MerchantSubMenu from "../../merchants/ui/MerchantSubMenu";
import FileUploader, {FileUploaderInvoker} from "@paylani/paylani-react-packages/dist/common/ui/file-uploader/FileUploader";
import ImageUploader from "@paylani/paylani-react-packages/dist/common/ui/file-uploader/ImageUploader";
import ProductService from "../../products/services/ProductService";
import UserService from "../services/UserService";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

const UserSubMenu = (props) => {
    const selections = {};
    let user = props.user;
    let userId = user?.id || "";

    const [uploadInvoker, setUploadInvoker] = useState(new FileUploaderInvoker());
    const [imageUrl, setImageUrl] = useState(user?.imageUrl);
    const selector = (props.selection || props.selector) || 'details';

    let profileImageElement = !!user?.imageUrl ?
        (<img src={user.imageUrl} alt="" />) :
        (<span className="image"><FontAwesomeIcon icon={faPersonWalking} /></span>);

    let path = "/people/" + userId;
    let merchant = props.merchant;
    let merchantId = props.merchantId || merchant?.id;
    
    if (!!merchantId) {
        path = "/merchants/" + merchantId + "/users/" + userId;
    }

    selections[selector] = 'selected';

    const removeImageAsync = async () => {
        uploadInvoker.update("");
        
        await UserService.instance.deleteImageAsync(userId);
        
        uploadInvoker.clear();
        setImageUrl("");
    };
    
    const onImageUploadComplete = (file) => {
        //
    };

    const uploadImageAsync = async (fileModel) => {
        console.log("Uploading from Product Form...");
        let p = await UserService.instance.uploadImageAsync(userId || fileModel.id, fileModel.file).catch((ex) => {
            console.log("Error uploading image: ", ex.response?.data);
        });
        //

        if (!!p?.result) p = p.result;
        
        console.warn(JSON.stringify(p, null, 4));
        
        if (userId !== AuthenticationService.instance.session?.userId) {
            props.user.imageUrl = await UserService.instance.getUserAsync(userId)?.imageUrl;
            
            const imgUrl = p?.imageUrl || p?.image_url;
            uploadInvoker.update(imgUrl);
            
            return p;
        }

        const reqId = (Math.random() * 1000000).toString(36).toUpperCase();
        await AuthenticationService.instance.getSessionAsync(true, reqId);

        const imgUrl = p?.imageUrl || p?.image_url;
        uploadInvoker.update(imgUrl);

        return p;
    };

    return (
        <div className="sub-column" id="user-submenu">
            <div className={"image profile"} id={"user-profile-image"}>
                <ImageUploader imageUrl={imageUrl} onRemove={removeImageAsync} uploadInvoker={uploadInvoker} onUpload={uploadImageAsync} />
            </div>
            
            <ul>
                <li className="sub-menu-header">
                    More
                </li>
                <li className={selections['details']}><Link to={path + ""}><FontAwesomeIcon icon={faAddressCard} /> User Details</Link></li>
                <li className={selections['access']}><Link to={path + "/access"}><FontAwesomeIcon icon={faUnlockKeyhole} /> Accessibility</Link></li>
                <li className={selections['activity']}><Link to={path + "/activity"}><FontAwesomeIcon icon={faAlignLeft} /> Activity History</Link></li>
                <li className={selections['sessions']}><Link to={path + "/sessions"}><FontAwesomeIcon icon={faBatteryHalf} /> Session History</Link></li>
            </ul>

        </div>
    );
};

export default UserSubMenu;
