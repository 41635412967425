import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import ProductService from "../services/ProductService";
import BackButton from "@paylani/paylani-react-packages/dist/common/ui/BackButton";
import FileUploader, {FileUploaderInvoker} from "@paylani/paylani-react-packages/dist/common/ui/file-uploader/FileUploader";
import {Checkbox} from "@mui/material";
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import ImageUploader from "@paylani/paylani-react-packages/dist/common/ui/file-uploader/ImageUploader";
import ErrorModel from "@paylani/paylani-react-packages/dist/common/models/ErrorModel";

const ProductForm = (props) => {
    const { merchantId, locationId } = useParams();
    const { productId, product, onUpdate, onComplete, onClick } = props;
    
    let _;
    const [checkboxes, setCheckboxes] = useState({ is_tax_override: false, is_tracking_inventory: false});
    const [uploadInvoker, setUploadInvoker] = useState(new FileUploaderInvoker());
    const [productModel, setProductModel] = useState(product || null);
    const [errors, setErrors] = useState({});

    const nameRef = useRef();
    const descriptionRef = useRef();

    const priceRef = useRef();
    const costPerRef = useRef();

    const taxRef = useRef();

    const skuRef = useRef();
    const itemCodeRef = useRef();
    const glCodeRef = useRef();

    const inventoryCountRef = useRef();

    const onImageUploadComplete = (file) => {
        //
    };

    const getProductAsync = async (force) => {
        if (!productId) return productModel;
        if (productModel) return productModel;
        
        
    };
    
    const removeImageAsync = async (e) => {
        console.log("Removing...");
        await ProductService.instance.removeImageAsync(product.id).then((p) => {
            if (!!p && typeof onUpdate === "function") onUpdate(p);
        });
    };

    const toggleCheckboxes = (field) => {
        setCheckboxes({ ...checkboxes, [field]: !checkboxes[field] });
    }

    const createProductJson = () => {
        const isTaxOverride = checkboxes.is_tax_override === true;
        const isTrackingInventory = checkboxes.is_tracking_inventory === true;

        return {
            name: nameRef.current?.value || null,
            description: descriptionRef.current?.value || null,
            price: parseFloat(priceRef.current?.value) || 0,
            cost_per: parseFloat(costPerRef.current?.value) || null,
            tax: isTaxOverride ? (parseFloat(taxRef.current?.value) || 0) : 0,
            sku: skuRef.current?.value || null,
            item_code: itemCodeRef.current?.value || null,
            gl_code: glCodeRef.current?.value || null,
            image_url: null,
            inventory_count: isTrackingInventory ? (parseInt(inventoryCountRef.current?.value) || 0) : 0,
            is_tax_override: isTaxOverride,
            is_tracking_inventory: isTrackingInventory,

            product_catalog_id: product?.productCatalogId,
            merchant_location_id: locationId || null,
            merchant_id: merchantId,
        };
    };

    const validate = (data) => {
        let er = {};

        if (!data.name || data.name.trim().length === 0)
            er.name = "Name is required";

        if (!data.price || data.price <= 0)
            er.price = "Price is required";

        setErrors(er);

        return (Object.keys(er).length === 0);
    };

    const updateProductAsync = async (e) => {
        const data = createProductJson();
        if (!validate(data)) return;

        const isNew = !product?.id || product.id.length < 30;

        console.log("IsNew: " + isNew);
        
        if (!isNew) {
            const image = uploadInvoker.upload(e);
            const up = (typeof image.then === 'function') ? await image.then((x) => x) : image;
        }
        
        let p = await ProductService.instance.updateProductAsync(data, productId).catch((ex) => {
            setErrors(ErrorModel.createFormError(ex));
        });
        
        if (!!p) {
            if (isNew) {
                e.id = p.id;
                const rsp = uploadInvoker.upload(e);
                p = (typeof rsp.then === 'function') ? await rsp.then((x) => x) : rsp;
            }
            
            if (typeof onUpdate === 'function') {
                onUpdate(p);
            }
        }
        
        return p || null;
    };

    const uploadImageAsync = async (fileModel) => {
        console.log("Uploading from Product Form...");
        const p = await ProductService.instance.uploadImageAsync(productId || fileModel.id, fileModel.file).catch((ex) => { 
            console.log("Error uploading image: ", ex.response?.data);
        });
        //
        return p;
    };
    
    useEffect(() => {
        if (!!product) {
            const ch = { is_tax_override: product.isTaxOverride, is_tracking_inventory: product.isTrackingInventory === true };
            setCheckboxes(ch);
        }

    }, [product]);

    useEffect(() => {
        //
    }, []);

    const checkboxSelections = {};
    checkboxSelections.tax_override = checkboxes.is_tax_override ? "selected" : undefined;
    checkboxSelections.is_tracking_inventory = checkboxes.is_tracking_inventory ? "selected" : undefined;

    const defaultTax = checkboxes.is_tax_override ? "0" : null;
    const backPath = "/";
    const subtitle = (<><BackButton /> {"Product Details: " + product?.name}</>);

    return (<div className={"form large form-error"}>
        <div className={"form-header-with-image"}>
            <div className={"image"}><ImageUploader imageUrl={product?.imageUrl} onRemove={removeImageAsync} uploadInvoker={uploadInvoker} onUpload={uploadImageAsync} /></div>

            <div className={"form-group"}>
                <div className={"form-group"}>
                    <label htmlFor={"name"}>Name</label>
                    <input type={"text"} id={"name"} name={"name"} ref={nameRef} defaultValue={product?.name} />
                    <div className={"form-note"}>{errors.name}</div>
                </div>
                <div className={"form-group"}>
                    <label htmlFor={"description"}>Description</label>
                    <textarea id={"description"} name={"description"} ref={descriptionRef} defaultValue={product?.description}></textarea>
                </div>
            </div>
        </div>

        <div className={"form-group double"}>
            <div className={"double"}>
                <label htmlFor={"price"}>Price</label>
                <input type={"number"} id={"price"} name={"price"} ref={priceRef} defaultValue={product?.price || ""} />
                <div className={"form-note"}>{errors.price}</div>
            </div>

            <div className={"double"}>
                <label htmlFor={"sku"}>Cost Per (optional)</label>
                <input type={"number"} id={"cost_per"} name={"cost_per"} ref={costPerRef} defaultValue={product?.costPer || null} />
            </div>
        </div>

        <div className={"form-group double " + (checkboxSelections.tax_override || "faded").trim()}>
            <div className={"double"}>
                <label htmlFor={"tax_override"}>Tax Override</label>
                <span className={"checkbox"}>
                    <Checkbox onClick={() => toggleCheckboxes("is_tax_override")} checked={checkboxes.is_tax_override} id={"tax_override"} /> 
                    <label htmlFor={"tax_override"}>This product has its own tax rate</label>
                </span>
            </div>

            <div className={"double"}>
                <label htmlFor={"tax"}>Tax %</label>
                <input type={"number"} id={"tax"} name={"tax"} ref={taxRef} defaultValue={product?.tax || defaultTax} />
            </div>
        </div>

        <div className={"form-group double"}>
            <div className={"double"}>
                <label htmlFor={"item_code"}>Item Code</label>
                <input type={"text"} id={"item_code"} name={"item_code"} ref={itemCodeRef} defaultValue={product?.itemCode} />
            </div>

            <div className={"double"}>
                <label htmlFor={"sku"}>SKU (optional)</label>
                <input type={"text"} id={"sku"} name={"sku"} ref={skuRef} defaultValue={product?.sku} />
            </div>
        </div>

        <div className={"form-group"}>
            <label htmlFor={"gl_code"}>GL Code</label>
            <input type={"text"} id={"gl_code"} name={"gl_code"} ref={glCodeRef} defaultValue={product?.glCode} />
        </div>

        <div className={"form-group double " + (checkboxSelections.is_tracking_inventory || "faded")}>
            <div className={"double"}>
                <label htmlFor={"is_tracking_inventory"}>Tracking Inventory</label>
                <span className={"checkbox"}>
                    <Checkbox onClick={() => toggleCheckboxes("is_tracking_inventory")}  checked={checkboxes.is_tracking_inventory} id={"is_tracking_inventory"} />
                    <label htmlFor={"is_tracking_inventory"}>Track and constrain inventory</label>
                </span>
            </div>
            <div className={"double"}>
                <label htmlFor={"inventory_count"}>Inventory Count</label>
                <input type={"number"} id={"inventory_count"} name={"inventory_count"} ref={inventoryCountRef} defaultValue={product?.inventoryCount} />
            </div>
        </div>

        <div className={"buttons"}>
            <FormButton onClick={updateProductAsync}>Update Product</FormButton>
        </div>

    </div>);

};

export default ProductForm;

