import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantSubMenu from "../../../components/merchants/ui/MerchantSubMenu";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import LocationsSubMenu from "../../../components/merchants/ui/LocationsSubMenu";
import RefreshButton from "../../../components/common/ui/RefreshButton";
import MasterScreen from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";

const LocationMasterPage = (props) => {
    const {
        title,
        onMerchant,
        onLocation,
        onRefresh,
        defaultMerchant,
        defaultMerchantId,
        defaultLocationId,
        defaultMerchantLocationId,
        children,
        force,
        icon,
        actions,
        merchantMenuSelection,
        selection,
        selector
    } = props;

    let _;
    const {merchantId, locationId, userId } = useParams();
    const initialMerchant = defaultMerchant || (MerchantService.instance.merchantMap[merchantId + "-" + locationId] || null);

    let [merchant, setMerchant] = useState(initialMerchant); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));

    const refreshAsync = async (force) => {
        //
    };

    const getLocation = () => {
        if (!merchant || !merchant.locations || merchant.locations.length === 0) return null;
        if (!locationId) return merchant.locations[0];
        return merchant.locations.find((l) => l.id === locationId) || null;
    };
    
    const notifyMerchant = () => {
        if (!!merchant && typeof onMerchant === 'function')
            onMerchant(merchant);
        
        if (typeof onLocation === 'function') {
            const location = getLocation();
            if (!!location) onLocation(location);
        }
    };

    const getActionElements = () => {
        if (!actions || !Array.isArray(actions)) return null;

        return actions.map((a, i) => {
            return typeof a === 'object' ? a : (<span key={"action-" + i}>{a}</span>);
        });
    };

    const getMerchantWithLocationAsync = async () => {
        if (!merchantId || merchantId.length < 30) return;
        const lid = (locationId || defaultMerchantLocationId || defaultLocationId);
        
        await MerchantService.instance.getMerchantWithLocationAsync(merchantId, lid).then((m) => {
            setMerchant(m);
        });
    }
  
    useEffect(() => {
        notifyMerchant();
    }, [merchant]);

    useEffect(() => {
        _ = getMerchantWithLocationAsync();
        notifyMerchant();
    }, []);

    const refreshButton = typeof onRefresh === 'function' ? (<RefreshButton onClick={onRefresh}>Refresh</RefreshButton>) : null;

    const location = getLocation();
    const locationTreeElement = !!location?.id ? (<><Link to={"/merchants/" + merchantId + "/locations/" + locationId}><span className={"subtitle"}>{location?.name}</span></Link>
        <span className={"bullet"}>&#10095;&#10095;</span></>) : null;
    
    const subtitle = typeof title === 'object' ? title 
        : (<><Link to={"/merchants/" + merchantId + "/locations"} className={"subtitle"}>{ merchant?.name }</Link>
            <span className={"bullet"}>&#10095;&#10095;</span>
            { locationTreeElement }
            <span className="subtitle">{title || "Overview"}</span> { getActionElements() }
            {refreshButton}
        </>);

    const body = children || (<p>No body for MerchantMasterPage</p>);
    const merchantMenu = !!merchant ? (<MerchantSubMenu merchant={merchant} selection={"locations"} />) : null;
    const bodyClass = !!merchantMenu ? " with-menu" : "";

    const locationMenu = !!location?.id ? (<LocationsSubMenu location={location} selection={selection} />) : null;
    
    return (
        <MasterScreen selection={selector || "merchants"} icon={faAddressCard} title={"Merchants"} onRefresh={onRefresh}>
            <div className={"pad"}>

                <div className="details-body">
                    {merchantMenu}

                    <div className={"pad" + bodyClass}>
                        <h2 className={"tree"}>{subtitle}</h2>
                        { locationMenu }
                        { body }
                    </div>
                </div>
            </div>
        </MasterScreen>
    );
};

export default LocationMasterPage;

