import React, {useState, useRef, useEffect} from 'react';
import './App.css';
import './Ui.css';
import Home from "./routes/Home";
import { AuthenticationService } from "@paylani/paylani-react-packages/dist/authentication";
import MerchantDetailsScreen from "./routes/merchants/MerchantDetailsScreen";

function App() {
    const storedSessionId = window.localStorage.getItem("session-id") || null;
    const [session, setSession] = useState(AuthenticationService.instance.session || null);

    const getSessionAsync = async (force) => {
        if (!!session?.id && !force) {
            return session;
        }

        const svc = AuthenticationService.instance;
        
        if (!svc.sessionId) {
            svc.sessionId = storedSessionId;
        }
        
        const sh = await AuthenticationService.instance.getSessionAsync(force);
        if (!!sh) onSession(sh);
        
        return sh;
    };

    const onSession = (sh) => {
        if (!sh) return;
        
        setSession(sh);
    };
    
    useEffect(() => {
        //
    }, [session]);

    useEffect(() => {
        const force = !!storedSessionId && !session?.id;
        let _ = getSessionAsync(force);
    }, []);
    
    const sessionId = session?.id || null;
    const accessLength = session?.user?.accessibleMerchants?.length || null;
    const isMaster = session?.user?.isMaster || null;
    const hasMerchantAccess = (!!sessionId && sessionId.length > 0) || (accessLength > 0 || isMaster);

    const body = (hasMerchantAccess) ? 
        (<Home onSession={onSession} />) :
        (<MerchantDetailsScreen onSession={onSession} merchantId={session?.user.merchantId}/>);

    return (<>{body}</>);
}

export default App;
