import React from 'react';
import {Link, useParams} from "react-router-dom";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";

const MerchantUserSubMenu = (props) => {
    let { userId, merchantId, locationId } = useParams();
    const { selection, user, merchant } = props;

    let sel = selection || 'main';
    let selections = {};

    selections[sel] = 'selected';

    if (!!merchant?.id) merchantId = merchant?.id || "";
    if (!!user?.id) userId = user?.id || "";
    
    const me = AuthenticationService.instance.session?.user;
    const hasPermission = me?.isMaster || user?.id === me?.id;
    const accessibilityElement = hasPermission ? (<Link className={selections['access']} to={'/merchants/' + merchantId + '/users/' + userId + '/access'}>Accessibility</Link>) : null;

    return (
        <div className="submenu merchant-users">
            <Link className={selections['main']} to={'/merchants/' + merchantId + '/users/' + userId + '/'}>Main</Link>
            { accessibilityElement }
            <Link className={selections['activity']} to={'/merchants/' + merchantId + '/users/' + userId + '/activity'}>Activity History</Link>
            <Link className={selections['sessions']} to={'/merchants/' + merchantId + '/users/' + userId + '/sessions'}>Session History</Link>
        </div>
    );

};

export default MerchantUserSubMenu;
