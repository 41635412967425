import React, {useEffect, useState} from 'react';
import ProductService from "../services/ProductService";
import LoadingBox from "../../common/ui/LoadingBox";

const CatalogLocationList = (props) => {
    const { catalogId, onClick, onLoad, productCatalogId, className } = props;
    const cid = catalogId || productCatalogId;
    const [ catalog, setCatalog ] = useState(ProductService.instance.catalogMap[cid || ""] || null);
    let _;
    
    const getCatalogAsync = async (force) => {
        if (!force && catalog?.locations?.length > 0) return;
        
        await ProductService.instance.getProductCatalogAsync(cid).then((c) => {
            if (!!c) setCatalog(c);
        });
    };
    
    const onLocationClick = (e, location) => {
        console.log(location?.id);
    };

    useEffect(() => {
        //
    }, [catalog]);

    useEffect(() => {
        _ = getCatalogAsync();
    }, []);
    
    if (!cid) return null;
    
    const cn = className || "";
    
    const locations = catalog?.locations;
    const locationNames = !!locations ? locations.map((l, i) => {
        return (<span key={"p-l-" + i} className={"location-name"}><a onClick={(e) => onLocationClick(e, l)}>{ l.name }</a></span>);
    }) : (<LoadingBox />);
    
    const locationCount = locations?.length || 0;
    const s = locationCount !== 1 ? "s" : "";
    const header =  (<strong className={"catalog-location-header"}>{ locationCount > 0 ? locationCount.formatNumber(0) : "No"} Location{s} Using this Catalog</strong>);
    
    return (<div className={("catalog-location-list " + cn).trim()}>
        { header }
        { locationNames }
    </div>);
};

export default CatalogLocationList;
