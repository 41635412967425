import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import RoleModel from "./components/people/models/RoleModel";
import AddressModel from "@paylani/paylani-react-packages/dist/geo/models/AddressModel";
import CreditCardModel from "./components/payments/models/CreditCardModel";
import MerchantLocationModel from "./components/merchants/models/MerchantLocationModel";
import ConfigModel from "@paylani/paylani-react-packages/dist/common/models/ConfigModel";
import MerchantService from "./components/merchants/services/MerchantService";

/**
 * This is the configuration file for the Paylani Portal.
 * It sets the company identifiers, and other configuration settings.
 * 
 * It also sets the Model overrides for user, role, address, credit card, etc
 */
class PaylaniConfig {
    static init() {
        ConfigModel.companyId = "23f45a56-ca75-40ea-ad13-cb1736bb8818";
        ConfigModel.isLoading = true;
        
        MerchantService.instance.getMerchantAsync(ConfigModel.companyId).then((m) => {
            delete ConfigModel.isLoading;
            ConfigModel.init(m);
            console.log("Master merchant initialized: " + m.name);
        }).catch((ex) => {
            delete ConfigModel.isLoading;
            console.error("Failed to initialize master merchant");
            console.error(ex);
        });
        
        UserModel.create = (json) => {
            if (!json) json = {};

            const user = new UserModel(json);

            user.roleId = json.role_id || null;
            user.isMaster = json?.role?.role_key === -1 || (json.role_key === -1 || false);

            user.role = RoleModel.create(json.role);
            user.imageUrl = json.image_url;
            user.isActive = json.is_active || false;

            user.username = json.user_name;
            user.userType = json.user_type || 'n/a';
            user.address = AddressModel.create(json.address);
            user.creditCard = CreditCardModel.create(json.credit_card);
            user.accessibleMerchants = json.accessible_merchants || [];
            user.accessibleMerchantMap = user.accessibleMerchants.reduce((map, obj) => {
                map[obj.id] = obj;
                return map;
            }, {});

            user.merchantId = json.merchant_id || "";
            user.merchantLocationId = json.merchantLocationId || "";
            user.locations = MerchantLocationModel.fromJsonArray(json.locations || []);

            return user;
        };

    }
}

export default PaylaniConfig;