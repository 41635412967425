
class ReportItemModel { 
    static defaultLineColor = '#0032A0';
    
    static getKeyFromDate(key, startDate, endDate) {
        if (!key || typeof startDate !== 'object') return null;
        if (typeof endDate !== 'object') endDate = new Date();
        
        return key + '-' + startDate.formatDate('MM-dd-yyyy') + '-' + endDate.formatDate('MM-dd-yyyy');
    }

    static createDateRange (startDate, endDate) {
        let title = startDate.formatDate('MMMM DAY, YYYY') + ' - ' + endDate.formatDate('MMMM DAY, YYYY');
        let key = startDate.formatDate('YYYY-MM-dd') + '-' + endDate.formatDate('YYYY-MM-dd');
        
        return { startDate: startDate, endDate: endDate, title: title, key: key };
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.label = json.label || '';
        this.value = parseFloat(json.value || '0.0');
        this.id = json.id || '';

        if (isNaN(this.value)) this.value = 0.0;

        let x = Date.parse(json.x_axis_date);
        let xAxisDate = isNaN(x) ? new Date() : new Date(x);
        
        this.xAxisSortValue = json.x_axis_sort_value;
        this.xAxisDate = xAxisDate;
        this.xAxisValue = json.x_axis_value;
    }

    static createLineChartSeries(reportItemModels, name, color, borderWidth, pointRadius) {
        if (typeof borderWidth !== 'number') borderWidth = 1;
        if (typeof pointRadius !== 'number') pointRadius = 1.5;
        if (typeof color !== 'string') color = ReportItemModel.defaultLineColor;

        if (!reportItemModels || !Array.isArray(reportItemModels)) reportItemModels = [];
        
        return {
            label: name || 'No Name',
            data: reportItemModels.map((item) => item.value) ?? [],
            borderColor: color,
            backgroundColor: color,
            borderWidth: borderWidth,
            pointRadius: pointRadius
        };
    }
    
    static fromJsonArray(jsonArray) {
        let items = [];

        if (!!jsonArray && Array.isArray(jsonArray)) {
            for (let i = 0; i < jsonArray.length; i++) {
                let model = new ReportItemModel(jsonArray[i]);
                if (model.id || model.label) items.push(model);
            }
        }

        return items;
    }
}

export default ReportItemModel;
