import React, {useEffect, useState} from 'react';
import {faCircleUser, faCreditCardAlt, faFaceFrown} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import Controller from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import SearchFilter from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import SaleModel from "../models/SaleModel";
import LoadingBox from "../../common/ui/LoadingBox";
import EmptyBox from "../../common/ui/EmptyBox";
import ErrorModel from "@paylani/paylani-react-packages/dist/common/models/ErrorModel";
import ErrorBox from "../../common/ui/ErrorBox";

const SalesTable = (props) => {
    const { 
        merchantId,
        locationId,
        isMaster,
        sales,
        merchantLocationId,
        onFetch,
        onLoad,
        onError,
        defaultValue,
        defaultSales,
        pageController,
        controller,
        emptyMessage,
        loadingMessage,
        pageSize,
        refreshingMessage,
    } = props;

    let _;
    const initialSales = (defaultSales || defaultValue) || (sales || null);
    const [saleModels, setSaleModels] = useState(initialSales);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [errors, setErrors] = useState({});
    
    const pagerController = useState(pageController || new PagerController(setCurrentPage, pageSize || 24))[0];
    const defaultException = new Error("An unknown error occurred");
    
    const lid = (locationId || merchantLocationId);
    const sourceColumnHeader = !!lid ? "Customer" : "Source";
    const tableId = !!lid ? "location-sales-table" : (!!merchantId ? "merchant-sales-table" : "sales-table");
    let isLoading = isFetching;  // This is used on a per-render basis;

    const createAndHandleError = (ex) => {
        const rsp = (typeof onError === "function") ? onError(ex) : new ErrorModel(ex);
        if (rsp instanceof ErrorModel) {
            setErrors({ top: rsp.message || defaultException.message });
            return rsp;
        }
        
        return false;
    };
    
    const getSalesAsync = async (force) => {
        if (typeof onFetch !== "function") {
            if (!Array.isArray(saleModels)) {
                const ex = new Error("SalesTable: onFetch is not a function (" + (typeof onFetch).toString() + "), and no sales array was passed to <SalesTable />");
                createAndHandleError(ex);
            }
            
            return saleModels;
        }
        
        if (!force && Array.isArray(saleModels)) {
            console.log("SalesTable: getSalesAsync: returning cached sales array. (force = " + force?.toString() + ")");
            return saleModels;
        }
        
        setIsFetching(true);
        
        const rsp = onFetch(force);
        const s = (typeof rsp?.then === "function") ? await rsp.catch((err) => createAndHandleError(err)) : rsp;

        setIsFetching(false);
        
        if (Array.isArray(s)) {
            setSaleModels(s);
            return s;
        }
        
        return saleModels;
    };
    
    useEffect(() => {
        if (controller instanceof Controller)
            controller.refreshAsync = getSalesAsync;
        
        _ = getSalesAsync();
    }, [saleModels]);
    
    const getDefaultEmptyMessage = () => { 
        if (lid) return "No sales for this location.";
        if (merchantId) return "No sales for this merchant.";
        return "No sales found.";
    }
    
    const filterElement = (!!filterText || (saleModels || []).length > pagerController.pageSize) ? (<h3 className={"has-search"}>
        <span>Location Sales History</span>
        <SearchFilter onFilter={setFilterText} label={"Search Sales"} />
    </h3>) : null;

    let filteredSales = saleModels;
    
    if (!!filterText && filterText.length > 2) {
        filteredSales = filteredSales.filter((sale) => SaleModel.searchFor(sale, filterText));
    }
    
    const createLink = (sale) => {
        return "/merchants/" + sale.merchantId + "/locations/" + sale.merchantLocationId + "/sales/" + sale.id;
    }
    
    const getOwnerElement = (sale, locationDefault = "Unknown Location", customerDefault = "No Customer") => {
        let locationElement = (<Link to={"/merchants/" + sale.merchantId + "/locations/" + sale.merchantLocationId + "/sales"}>{sale.location?.name || locationDefault}</Link>);
        if (!!lid || !sale.customerId) return locationElement;

        const customerName = sale.customer?.name || "No Customer Name";
        const customerLink = "/merchants/" + merchantId + "/sales/" + sale.id;

        return (
            <div className={"sale-owner"}>
                <div>{locationElement}</div>
                <div>
                    <Link to={customerLink}>
                        <FontAwesomeIcon icon={faCircleUser} />
                        {customerName}
                    </Link>
                </div>
            </div>
        );
    }

    const salesElements = pagerController.mapLineItems(filteredSales, (sale) => {
        let cn = "";
        let icon = faCreditCardAlt;

        if (sale.status === 2 || sale.status === 3) {
            cn = "failed";
            icon = faFaceFrown;
        }

        if (sale.status === 1) cn = "completed";
        const path = "/merchants/" + merchantId + "/sales/" + sale.id;
        const ownerElement = getOwnerElement(sale);

        return (<tr key={sale.id} className={"sale-tr " + cn}>
                <td><Link to={path}>#{sale.saleNumber}</Link></td>
                <td>{sale.total.formatCurrency(2)}</td>
                <td className={"desc"}>{ ownerElement }</td>
                <td className={"status " + cn} title={sale.message || ""}><FontAwesomeIcon icon={icon} /> {sale.statusName}</td>
                <td><DateTime value={sale.created} time={true} /></td>
            </tr>
        );
    });
    
    const getLoadingBox = () => {
        isLoading = true;
        const re = (saleModels?.length > 0) ? "Refreshing" : "Loading";
        const message = (saleModels?.length > 0 && !!refreshingMessage) ? refreshingMessage : loadingMessage;
        
        return (<LoadingBox>{ message || re + " Sales..."}</LoadingBox>);
    }

    // const activityElement = (saleModels?.length > 0 || (saleModels?.length === 0 && !!errors.top)) ? null :
    //     (saleModels === null) ? getLoadingBox() : (<EmptyBox>{ emptyMessage || getDefaultEmptyMessage() }</EmptyBox>);
    
    const activityElement = !isLoading ? 
        (saleModels?.length > 0 ? null : (<EmptyBox>{ emptyMessage || getDefaultEmptyMessage() }</EmptyBox>)) :
        getLoadingBox();
    
    const errorBox = (errors.top) ? (<ErrorBox>{errors.top} &nbsp; <a onClick={() => getSalesAsync(true)}>Try Again</a></ErrorBox>) : null;
    const content = saleModels?.length > 0 ? (<><table id={tableId} className={"table-x-large"} width={"100%"}>
        <thead>
        <tr>
            <th>Sale #</th>
            <th>Total</th>
            <th>{sourceColumnHeader}</th>
            <th>Status</th>
            <th>Date</th>
        </tr>
        </thead>

        <tbody>
        {salesElements}
        </tbody>
    </table>
    <Pager controller={pagerController} items={filteredSales || []} />
    </>) : null;

    return (
        <>{ filterElement }
        {errorBox || activityElement}
        <div className={"sales sales-table-section"}>
            { content }
        </div>
    </>);
};

export default SalesTable;
