import React from 'react';

const LoadingBox = (props) => {
    const {children, size} = props;
    const sizeClass = [32, 24, 16].includes(size) ? "size-" + size.toString() : "size32";
    
    return (
        <span className={"loading-box spinner " + sizeClass}>
            <label className={"spinner-label"}>{ children || "Loading"}</label>
            <span className={"spinning"}><label></label></span>
        </span>);
};

export default LoadingBox;
