import React, {useEffect, useRef} from 'react';
import CheckBoxer from "@paylani/paylani-react-packages/dist/common/ui/checkboxer/CheckBoxer";
import {create} from "@mui/material/styles/createTransitions";

const SalesFieldForm = (props) => {
    const { field, locationId, location, onChange } = props;

    const labelRef = useRef();
    const typeRef = useRef();
    const defaultValueRef = useRef();
    const errorLabelRef = useRef();
    
    const [isRequired, setIsRequired] = React.useState(field?.isRequired === true);
    const [isActive, setIsActive] = React.useState(field?.isActive === true);
    
    const toggleRequiredAsync = async (isChecked) => {
        console.log("isRequired: " + isChecked);
        setIsRequired(isChecked);
    };
    
    const toggleIsActiveAsync = async (isChecked) => {
        console.log("IsActive: " + isChecked);
        setIsActive(isChecked);
    };
    
    const fieldTypes = [
        { id: 0, name: "Plain Text" },
        { id: 1, name: "Number" },
        { id: 2, name: "Date/Time" },
        { id: 3, name: "Yes / No" },
    ];
    
    const onAnyFieldChange = (e) => {
        let json = createJson();
        if (typeof onChange === 'function') onChange(json, e);
    }
    
    const createJson = () => { 
        let ft = parseInt(typeRef.current?.value || "");
        if (isNaN(ft)) ft = 0;
        
        return {
            field_type_key: ft,
            label: labelRef.current?.value || "",
            default_value: defaultValueRef.current?.value || "",
            error_label: errorLabelRef.current?.value || "",
            is_required: isRequired,
            is_active: isActive,
            id: field?.id || null
        };
    }
    
    const fieldTypeElements = fieldTypes.map((ft) => {
        return (<option key={ft.id} value={ft.id}>{ft.name}</option>);
    });
    
    useEffect(() => {
        onAnyFieldChange(null);
    }, [isActive, isRequired]);
    
    return (<div className={"form x-large sales-fields-form"}>
        <div className={"form-group"}>
            <label>Label:</label>
            <input ref={labelRef} onChange={onAnyFieldChange} type={"text"} defaultValue={field?.label} />
        </div>

        <div className={"form-group"}>
            <label>Input Type:</label>
            <select ref={typeRef} defaultValue={field?.fieldTypeKey} onChange={onAnyFieldChange}>{ fieldTypeElements }</select>
        </div>

        <div className={"form-group"}>
            <label>Default Value:</label>
            <input ref={defaultValueRef} type={"text"} defaultValue={field?.defaultValue} onChange={onAnyFieldChange} />
        </div>

        <div className={"form-group"}>
            <label>Error Message:</label>
            <input ref={errorLabelRef} type={"text"} defaultValue={field?.errorLabel} onChange={onAnyFieldChange} />
        </div>

        <div className={"form-group"}>
            <div className={"check-box"}>
                <label>Field is Required: </label>
                <span><CheckBoxer isChecked={isRequired} onCheck={toggleRequiredAsync} /></span>
            </div>
        </div>

        <div className={"form-group"}>
            <div className={"check-box"}>
                <label>Active in App: </label>
                <span><CheckBoxer isChecked={isActive} onCheck={toggleIsActiveAsync} /></span>
            </div>
        </div>
        
    </div>);
};

export default SalesFieldForm;
