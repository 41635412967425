import React, {useEffect, useRef, useState} from 'react';
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import ErrorModel from "@paylani/paylani-react-packages/dist/common/models/ErrorModel";

const PersonForm = (props) => {
    let { value, onSubmit, buttonCaption, prefix, onChange, onValidate, onError, controller, requiredFields} = props;
    const [userJson, setUserJson] = useState(value || null);
    const [errors, setErrors] = useState({});

    const idPrefix = (prefix || "") + "customer-";
    const defaultRequiredFields = { first_name: true, last_name: true, email_or_phone: true, email: "Email is required", phone: "Phone is required" };
    
    let firstNameRef = useRef();
    let lastNameRef = useRef();
    let emailRef = useRef();
    let phoneRef = useRef();
    
    const createPayload = () => {
        return {
            first_name: firstNameRef.current?.value || null,
            last_name: lastNameRef.current?.value || null,
            email: emailRef.current?.value || null,
            phone: phoneRef.current?.value || null
        };
    };
    
    const validateForm = (json) => {
        if (!json) json = {};
        let er = (typeof onValidate === 'function') ? onValidate(json) : null;
        
        if (typeof er === 'string') er = { general: er };
        else if (typeof er !== 'object' || er === null) er = {};
        
        if (!requiredFields || typeof requiredFields !== 'object') {
            requiredFields = defaultRequiredFields;
        }
        
        if (!json.first_name) er.first_name = "First Name is required";
        if (!!requiredFields.last_name && !json.last_name) er.last_name = "Last Name is required";
        
        if (!json.email && !json.phone && requiredFields.email_or_phone) { 
            er.email = "Email is required (or phone)";
            er.phone = "Phone is required (or email)"
        } else {
            if (requiredFields.email && !json.email) er.email = "Email is required";
            if (requiredFields.phone && !json.phone) er.phone = "Phone is required";
        }
        
        return new ErrorModel(er, true);
    };
    
    const validate = (json) => {
        const formError = validateForm(json);
        if (formError.hasFormErrors()) { 
            if (typeof onError === 'function') onError(formError);
            setErrors(formError.focus(idPrefix));
            return false;
        }
        
        return false;
    };

    const handleChange = (fieldId, e) => {
        let value = e.target.value;
        setUserJson[fieldId] = value;
        setUserJson((values => ({...values, [fieldId]: value})));
        
        let er = {...errors};
        delete er[fieldId];
        setErrors(er);
    };

    const handleError = (ex) => {
        if (typeof onError === 'function') onError(ex);
        setErrors(ErrorModel.createFormError(ex));
    };
    
    const onPersonSubmit = async (e) => {
        e.preventDefault();
        
        if (typeof onSubmit !== 'function') {
            console.error('onSubmit was not set');
            return false;
        }

        const payload = createPayload();
        const formError = validateForm(payload);
        
        if (formError.hasFormErrors()) {
            if (typeof onError === 'function') onError(formError);
            setErrors(formError.focus(idPrefix));
            
            return false;
        }

        setErrors({});
        const rsp = onSubmit(payload);
        
        return typeof rsp.then === 'function' ? 
            rsp.then(() => {}).catch(handleError) :
            null;
    };

    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange(userJson);
        }
    }, [userJson]);

    useEffect(() => {
        if (!!controller) {
            controller.getPersonData = () => {
                const payload = createPayload();
                if (!validate(payload)) return null;
                
                return payload;
            };
        }
        
    }, []);
    
    const submitElement = props.useButton ? (
        <div className="button">
            <FormButton id="address-update-button" onClick={onPersonSubmit} label={buttonCaption || "Update"} />
        </div>
    ) : (<></>);
    
    return (
        <div className={"form"}>
            <div className="form-group double">
                <div>
                    <label htmlFor="customer-first-name">First Name</label>
                    <input ref={firstNameRef} type="text" id="customer-first-name" defaultValue={userJson?.first_name} onChange={handleChange.bind(this, "first_name")} />
                    <div className={"form-error"}>{ errors?.first_name }</div>
                </div>
                <div>
                    <label htmlFor="customer-last-name">Last Name</label>
                    <input ref={lastNameRef} type="text" id="customer-last-name" defaultValue={userJson?.last_name} onChange={handleChange.bind(this, "last_name")} />
                    <div className={"form-error"}>{ errors?.last_name }</div>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="customer-email">Email Address</label>
                <input ref={emailRef} type="text" id="customer-email" defaultValue={userJson?.email} onChange={handleChange.bind(this, "email")} />
                <div className={"form-error"}>{ errors?.email }</div>
            </div>

            <div className="form-group">
                <label htmlFor="customer-phone">Phone Number</label>
                <input ref={phoneRef} type="text" id="customer-phone" defaultValue={userJson?.phone} onChange={handleChange.bind(this, "phone")} />
                <div className={"form-error"}>{ errors?.phone }</div>
            </div>

            <div className={"form-error"}>{ errors?.general }</div>
            
            {submitElement}
        </div>
    );
};

export default PersonForm;