import MerchantConfigurationModel from "./MerchantConfigurationModel";
import AddressModel from "@paylani/paylani-react-packages/dist/geo/models/AddressModel";

class MerchantLocationModel { 
    static json = {
        "merchant_id": "0aaea5a0-beda-42b2-b626-bada82f09280",
        "address_id": "f9bb2106-7310-4d36-981d-79c98331ff97",
        "address": null,
        "product_catalog_id": "08bac979-8cf0-486d-bb53-6f4a05a8723f",
        "plan_id": "00000000-0000-0000-0000-000000000000",
        "name": "Jade Ventures Default Location",
        "description": null,
        "backoffice_provider_type": 0,
        "payment_provider_type": 1,
        "remote_id": null,
        "is_active": true,
        "is_test_mode": false,
        "receipt_message": null,
        "configurations": [],
        "status": 0,
        "id": "c9379192-f52d-4476-8f1b-1bb2f4c10ee4",
        "created": "2022-08-08T21:44:43.013",
        "modified": null
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.name = json.name;
        this.merchantId = json.merchant_id;
        this.addressId = json.address_id;
        this.address = new AddressModel(json.address, true);
        this.productCatalogId = json.product_catalog_id;
        this.planId = json.plan_id;
        this.description = json.description;
        this.backofficeProviderType = json.backoffice_provider_type;
        this.paymentProviderType = json.payment_provider_type;
        this.remoteId = json.remote_id;
        this.isActive = json.is_active;
        this.isTestMode = json.is_test_mode || false;
        this.receiptMessage = json.receipt_message;
        this.configurations = MerchantConfigurationModel.fromJsonArray(json.configurations);
        this.configurationMap = MerchantConfigurationModel.createMap(this.configurations);
        this.place = this.address?.city ? this.address?.city + ", " + this.address?.state : 'United States';
        
        this.status = json.status;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        
        return jsonArray.map((json) => {
            return new MerchantLocationModel(json);
        });
    }

    static searchFor(location, term) {
        if (!term || term.length === 0) return true;
        if (!location) return false;
        return (location.name.trim().toLowerCase().indexOf(term) >= 0);
    }    
}

export default MerchantLocationModel;
