import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons";
import MerchantSubMenu from "../../components/merchants/ui/MerchantSubMenu";
import MerchantEffect from "../../components/merchants/services/MerchantEffect";
import MasterScreen from "@paylani/paylani-react-packages/dist/common/ui/screens/MasterScreen";

const MerchantReportsScreen = (props) => {
    const {merchantId} = useParams();
    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId]);

    useEffect(() => {
        MerchantEffect.create(setMerchant, merchantId);
    }, []);

    return (
        <MasterScreen selection="merchants">
            <div className="pad" id="partner-merchant-details">
                <h1>
                    <FontAwesomeIcon icon={faAddressCard} />
                    <span className={"subtitle"}>Merchant:</span>
                    {merchant?.name}
                </h1>

                <div className="details-body">
                    <MerchantSubMenu partner={merchant} selection="reports" />

                    <div className="pad">
                        <h2>
                            {merchant?.name}:
                            <span className="subtitle">Details</span>
                        </h2>

                        <p>
                            This merchant appears as "{merchant?.name}" on reporting, and is for display purposes.<br/>
                            <Link to={"/merchants/" + merchantId}>{merchant?.name}</Link> is the parent Partner of
                            {merchant?.name}.
                        </p>

                    </div>
                </div>
            </div>
        </MasterScreen>
    );
    
};

export default MerchantReportsScreen;

