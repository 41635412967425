import React, {useEffect, useRef, useState} from 'react';
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";  //"@paylani/paylani-react-packages/dist/common/ui/FormButton";
import ErrorModel from "@paylani/paylani-react-packages/dist/common/models/ErrorModel";

const AchForm = (props) => {
    const { bankAccount, onClick, onChange, onValidate, requiredFields, onError, controller } = props;
    const [payload, setPayload] = useState(bankAccount || {account_type: "0", account_holder_type: "1", sec_code: "1" });
    const [error, setError] = useState({});

    const accountNameRef = useRef();
    const accountNumberRef = useRef();
    const routingNumberRef = useRef();
    const secCodeRef = useRef();
    const accountTypeRef = useRef();
    const accountHolderTypeRef = useRef();
    
    const achPrefix = "ach-";
    
    const createPayload = () => {
        return {
            account_holder_type: accountHolderTypeRef.current?.value,
            account_number: accountNumberRef.current?.value,
            routing_number: routingNumberRef.current?.value,
            account_type: accountTypeRef.current?.value,
            account_name: accountNameRef.current?.value,
            sec_code: secCodeRef.current?.value,
        };
    };
    
    const validateForm = (json) => {
        if (!json) json = {};
        let er = (typeof onValidate === 'function') ? onValidate(json) : null;

        if (typeof er === 'string') er = {general: er};
        else if (typeof er !== 'object' || er === null) er = {};

        let rf = requiredFields || "";
        if (rf !== 'object') {
            rf = {};
        }

        if (!json.account_name) er.account_name = "Account Name is required";
        if (!json.account_number) er.account_number = "Account Number is required";
        if (!json.routing_number) er.routing_number = "Routing Number is required";
        if (!json.account_holder_type) er.account_holder_type = "Account Holder Type is required";
        if (!json.account_type) er.account_type = "Account Type is required";
        if (!json.sec_code) er.sec_code = "Entry type is invalid";

        return new ErrorModel(er);
    };

    const validate = (json) => {
        const err = validateForm(json);
        if (err.hasFormErrors()) {
            if (typeof onError === "function")
                onError(err);

            setError(err.focus(achPrefix));
            return false;
        }

        return true;
    };

    const handleChange = (fieldId, e) => {
        const value = e.target.value;
        const payload = createPayload();
        if (typeof onChange === 'function') onChange(payload);
    };

    const onSubmitAsync = async (e) => {
        const payload = createPayload();
        const formError = validateForm(payload);

        if (formError.hasFormErrors()) { 
            setError(formError.focus(achPrefix));
            return;
        }
        
        if (typeof onClick !== 'function') return;

        const rsp = onClick(e, payload);
        return (typeof rsp.then === 'function') ? await rsp : rsp;
    };

    useEffect(() => {
        if (typeof props.onChange === 'function') {
            props.onChange(payload);
        }

    }, [payload]);

    useEffect(() => {
        if (!!controller) {
            controller.getAchData = () => { 
                const payload = createPayload();
                if (!validate(payload)) return null;
                return payload;
            };
        }

    }, []);

    let submitElement = props.useButton ? (
        <div className="button">
            <FormButton id="address-update-button" onClick={onSubmitAsync} label="Update Credit Card" />
        </div>
    ) : (<></>);

    const mask = payload?.account_number ? "*** *** " + payload.account_number.substr(payload.account_number.length - 4) : "";
    const routingMask = payload?.routing_number ? "*** *** " + payload.routing_number.substr(payload.routing_number.length - 4) : "";

    return (
        <div className="form payment-form">
            <div className="form-group">
                <label htmlFor="account-name">Name on Account</label>
                <input ref={accountNameRef} type="text" id="account-name" defaultValue={payload.name} onChange={handleChange.bind(this, "account_name")} />
                <div className={"form-error"}>{ error?.account_name }</div>
            </div>

            <div className="form-group">
                <label htmlFor="account-number">Account Number</label>
                <input ref={accountNumberRef} type="text" id="account-number" defaultValue={mask} onChange={handleChange.bind(this, "account_number")} />
                <div className={"form-error"}>{ error?.account_number }</div>
            </div>

            <div className="form-group">
                <label htmlFor="routing-number">Routing Number</label>
                <input ref={routingNumberRef} type="text" id="routing-number" defaultValue={routingMask} onChange={handleChange.bind(this, "routing_number")} />
                <div className={"form-error"}>{ error?.routing_number }</div>
            </div>

            <div className="form-group">
                <label htmlFor="sec-code">Entry SEC Code</label>
                <select ref={secCodeRef} id="sec-code" defaultValue={payload.sec_code} onChange={handleChange.bind(this, "sec_code")}>
                    <option value={"ARC"}>Accounts Receivable Entry (ARC)</option>
                    <option value={"WEB"}>Web Entry (Web)</option>
                    <option value={"PPD"}>Prearranged Payment &amp; Deposit (PPD)</option>
                    <option value={"CCD"}>Corporate Cash Disbursement (CCD)</option>
                    <option value={"TEL"}>Telephone Initiated Entry (TEL)</option>
                    <option value={"BOC"}>Back Office Conversion (BOC)</option>
                    <option value={"POP"}>Point-Of-Presence (POP)</option>
                    <option value={"RCK"}>Re-presented Check Entry (RCK)</option>
                </select>
                <div className={"form-error"}>{ error?.sec_code }</div>
            </div>

            <div className="form-group double">
                <div>
                    <label htmlFor="account-holder-type">Account Holder Type</label>
                    <select ref={accountHolderTypeRef} id="account-holder-type" defaultValue={payload.account_holder_type} onChange={handleChange.bind(this, "account_holder_type")}>
                        <option value={1}>Business</option>
                        <option value={0}>Personal</option>
                    </select>
                    <div className={"form-error"}>{ error?.account_holder_type }</div>
                </div>

                <div>
                    <label htmlFor="account-type">Account Type</label>
                    <select ref={accountTypeRef} id="account-type" defaultValue={payload.account_type} onChange={handleChange.bind(this, "account_type")}>
                        <option value={0}>Checking</option>
                        <option value={1}>Savings</option>
                    </select>
                    <div className={"form-error"}>{ error?.account_type }</div>
                </div>
            </div>

            {submitElement}
        </div>
    );
};

export default AchForm;

