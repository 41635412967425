import ConfigurationValueOptionModel from "./ConfigurationValueOptionModel";

class ConfigurationModel {
    static editDetailsPath = {
        'setting.merchant.allowtipping': '/merchant/:merchantId/location/:locationId/tips',
        'feature.catalog': '/merchant/:merchantId/location/:locationId/catalog',
    };
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.title = json.title || "";
        this.sortOrder = json.sort_order || 0;
        this.name = json.name || '';
        this.value = json.value;
        this.valueOptions = json.value_options;
        this.description = json.description;
        this.configurationType = ConfigurationValueOptionModel.getTypeOf(this.valueOptions);
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }

    static isReserved(key) {
        if (!key) return false;
        let path = this.editDetailsPath[key];
        return (typeof path === 'undefined' || path === null);
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray) return [];
        return jsonArray.map((json) => {
            return new ConfigurationModel(json);
        });
    }
}

export default ConfigurationModel;
