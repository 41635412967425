import React, {useState} from 'react';
import { useParams} from "react-router-dom";
import MerchantService from "../../components/merchants/services/MerchantService";
import MerchantModel from "../../components/merchants/models/MerchantModel";
import MerchantMasterPage from "./MerchantMasterPage";

const BillingScreen = (props) => {
    const {merchantId} = useParams();

    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));

    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };
    
    return (
        <MerchantMasterPage title={"Billing"} selection={"billing"}>
            <p>
                This merchant appears as "{merchant.name}" on receipts.
            </p>
        </MerchantMasterPage>
    );
};

export default BillingScreen;
