import React, { useState } from 'react';
import {faCode, faFlask, faGear, faShieldHalved} from "@fortawesome/free-solid-svg-icons";
import DialogBox from "../../components/common/ui/dialog-box/DialogBox";
import DialogBoxController from "../../components/common/ui/dialog-box/DialogBoxController";
import LoginForm from "@paylani/paylani-react-packages/dist/authentication/ui/LoginForm";
import EnvironmentModel from "@paylani/paylani-react-packages/dist/common/models/EnvironmentModel";
import SettingsMasterScreen from "@paylani/paylani-react-packages/dist/settings/ui/screens/SettingsMasterScreen";
import EnvironmentPanel from "@paylani/paylani-react-packages/dist/settings/ui/EnvironmentPanel";

const EnvironmentSettingsScreen = (props) => {
    let selections = {};
    selections[EnvironmentModel.environment.key] = true; //"selected";
    
    let [selectedKey, setSelectedKey] = useState(EnvironmentModel.environment.key);
    let dialogController = new DialogBoxController("Login to a new Environment");
    
    const promptEnvironmentChange = (environmentKey, e) => {
        setSelectedKey(environmentKey);
        dialogController.title = "Login to " + EnvironmentModel.environments[environmentKey].name + " Environment";
        dialogController.open();
    };
    
    const onEnvironmentAuthenticationChange = (session) => {
        //
    };
    
    const settingsPanelModels = [
        {
            title: "Production",
            description: "Live Data! Be careful when navigating through the Production Environment. These are real customers, with real consequences.",
            icon: faShieldHalved,
            key: "production",
        },
        {
            title: "Staging",
            description: "Semi-Live Data. Partners use the Staging Environment to test their processes, so although the consequences are lower, they are still quite real",
            icon: faFlask,
            key: "stage",
        },
        {
            title: "Development (Local)",
            description: "Development environment, app, and data. If you are not a developer, it's unlikely you'll have much use for this option.",
            icon: faCode,
            key: "local",
        },
    ];
    
    const elements = settingsPanelModels.map((model, index) => {
        return (<li key={index}>
            <EnvironmentPanel environmentKey={model.key} isSelected={!!selections[model.key]} title={model.title} icon={model.icon || faGear} onClick={promptEnvironmentChange.bind(this, model.key)}>
                {model.description}
            </EnvironmentPanel>            
        </li>);
    });
    
    return (
        <SettingsMasterScreen title={"Environment Settings"} selection={"env"}>
            <p>
                Switch environments for the platform. You will be prompted to re-authenticate when you do. Current environment is <strong>{ EnvironmentModel.environment.name }</strong>
            </p>

            <ul className={"environment-items"}>
                { elements }
            </ul>

            <DialogBox controller={dialogController} hasOkayButton={false}>
                <LoginForm onAuthenticate={onEnvironmentAuthenticationChange} environmentKey={selectedKey} />
                <hr />
            </DialogBox>
            
        </SettingsMasterScreen>
    );
    
};

export default EnvironmentSettingsScreen;
