import React, {useEffect, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import UserService from "../../../components/people/services/UserService";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantEffect from "../../../components/merchants/services/MerchantEffect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard,
    faCheckCircle, faCommentDots, faEnvelope, faFaceDizzy,
    faPaperPlane, faPersonFallingBurst,
    faPersonWalking, faRightFromBracket, faSkull,
} from "@fortawesome/free-solid-svg-icons";
import UserSubMenu from "../../../components/people/ui/UserSubMenu";
import MerchantUserMasterPage from "./MerchantUserMasterPage";
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import PhoneNumber from "@paylani/paylani-react-packages/dist/common/formatting/PhoneNumber";
import MerchantSubMenu from "../../../components/merchants/ui/MerchantSubMenu";
import Dialog from "@mui/material/Dialog/Dialog";
import MultiMerchantSelector from "../../../components/merchants/ui/MultiMerchantSelector";
import Controller from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import MultiLocationSelector from "../../../components/merchants/ui/MultiLocationSelector";

const MerchantUserAccessScreen = (props) => {
    const { userId, merchantId } = useParams();

    let [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId || ''] || null);
    //let [userMerchant, setUserMerchant] = useState(MerchantService.instance.merchantMap[user?.merchantId || ""] || null);

    let [merchants, setMerchants] = useState(MerchantService.instance.merchants || null);
    let [merchantSelections, setMerchantSelections] = useState(user?.accessibleMerchants || []);
    let [merchantListVisible, setMerchantListVisible] = useState(false);
    
    let [locationSelections, setLocationSelections] = useState(user?.locations || []);
    let [locationListVisible, setLocationListVisible] = useState(false);

    let [selectorController, setSelectorController] = useState(new Controller());
    let [locationSelectorController, setLocationSelectorController] = useState(new Controller());
    let [confirmButton, setConfirmButton] = useState((<></>));

    let [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    let [dialogData, setDialogData] = useState({title: (<></>), text: (<></>), buttons: []});

    const [redirectUrl, setRedirectUrl] = useState("");
    
    let _;

    const getWorkingButton = (caption, isSpinning) => {
        let buttonCaption = caption || 'Updating';
        let cn = 'spinning'; // !!isSpinning ? 'spinning' : 'done';
        let icon = isSpinning ? (<label></label>) : (<FontAwesomeIcon icon={faCheckCircle} />);

        return (
            <Button className={"spinner"}>
                <div>{buttonCaption} <span className={cn}>{ icon }</span></div>
            </Button>
        );
    };

    /**
     * Master page event handler
     * @param user { UserModel }
     */
    const onUser = (user) => {
        setUser(user);
    };

    /**
     * Master page event handler
     * @param merchant { MerchantModel }
     */
    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };

    /**
     * Merchant selector event handler
     * @param e
     */
    const forceCloseMerchantAccess = (e) => {
        selectorController?.close({}, e);
        if (!merchantListVisible) return;

        if (e.target.id === "search-merchants") return;
        if (e.target.className === "no-close") return;

        if (e.target.tagName === 'path' || (e.target.id && e.target.id.indexOf('notification') >= 0)) {
            return;
        }

        UserService.instance.updateMerchantAccessAsync(user, merchantSelections).then((result) => {});
    };

    /**
     * Location selector event handler
     * @param e
     */
    const forceCloseLocationAccess = (e) => {
        locationSelectorController?.close({}, e);
        if (!locationListVisible) return;

        if (e.target.id === "search-merchants") return;
        if (e.target.className === "no-close") return;

        if (e.target.tagName === 'path' || (e.target.id && e.target.id.indexOf('notification') >= 0)) {
            return;
        }

        UserService.instance.updateLocationAccessAsync(user, locationSelections).then((result) => {});
    };

    const refreshUser = () => {
        UserService.instance.getUserAsync(userId).then((user) => {
            onUser(user);
        });
    };

    const confirmOpen = (e) => {
        setIsConfirmationOpen(true);
    };

    const confirmClose = (e) => {
        setIsConfirmationOpen(false);
    };

    const confirmCancel = (e) => {
        console.log("Confirmation canceled");
        setIsConfirmationOpen(false);
    };

    const setDoneButton = (caption, delay) => {
        if (!caption) caption = 'Done';

        setConfirmButton(getWorkingButton(caption, false));

        setTimeout(() => {
            setIsConfirmationOpen(false);
        }, delay || 1000);
    }

    useEffect(() => {
        if (merchantId) MerchantEffect.create(setMerchant, merchantId);
        if (!user?.id) refreshUser()
    }, []);

    if (!!redirectUrl) {
        return (<Navigate to={redirectUrl} />);
    }
    
    const sendResetLink = async (key, e) => {
        const isMobile = key === "reset-sms";
        
        await UserService.instance.sendPasswordResetLinkAsync(user.id, isMobile).then(() => {
            setDoneButton();
        }).catch((error) => {
            console.error('Error: ' + error);
        });
    };

    const confirmSendResetLink = async (key, e) => {
        let btn = (<Button autoFocus color="inherit" onClick={sendResetLink.bind(this, key)}>
            Send Now
        </Button>);

        let dest = key === 'reset-email' ? (<>{user.email}</>) : (<PhoneNumber value={user.phone} />);
        let icon = key === 'reset-email' ? (<FontAwesomeIcon icon={faEnvelope} />) : (<FontAwesomeIcon icon={faCommentDots} />);
        let sendVia = key === 'reset-email' ? 'Email' : 'Text Message';

        let dd = {
            title: (<>{icon} Send Password Link via {sendVia}</>),
            text: (<>Are you sure you want to send a reset password link to <a>{dest}</a>...?<br/>They might be confused if they aren't ready for the message.</>)
        }

        setDialogData(dd);
        setConfirmButton(btn);

        confirmOpen(e);
    };

    const killSessionsAsync = async (key, e) => {
        console.log('Kill Sessions: ' + key);

        setConfirmButton(getWorkingButton('Killing', true));

        _ = await UserService.instance.deleteSessionsAsync(userId).catch((ex) => { 
            //
            throw ex;
        });

        setDoneButton();
    };

    const confirmKillSessions = (key, e) => {
        let btn = (<Button autoFocus onClick={killSessionsAsync.bind(this, key)}>
            Yes, Kill Sessions
        </Button>);

        let dd = {
            title: (<><FontAwesomeIcon icon={faPersonFallingBurst} /> Boot <a>{user.name}</a>...?</>),
            text: (<>All sessions will end and this user will be redirected to the nearest login screen. Continue?</>)
        }

        setDialogData(dd);
        setConfirmButton(btn);

        confirmOpen(e);
    };

    const deactivateUser = async (key, e) => {
        await UserService.instance.deleteUserAsync(userId).then(() => {
            setRedirectUrl("/merchants/" + merchantId + "/users");
        }).catch((ex) => { 
            const dd = {
                title: "Failed to deactivate user",
                text: null,
                error: (<><span className={"error"}>Failed to deactivate user: { ex?.response?.data?.message || "Server or other ambiguous system error" }</span></>),
            };

            setDialogData(dd);
        });
    };

    const confirmDeactivate = (key, e) => {
        let btn = (<Button autoFocus color="inherit" onClick={deactivateUser.bind(this, key)}>
            Yes, Deactivate
        </Button>);

        let dd = {
            title: (<><FontAwesomeIcon icon={faSkull} /> Deactivate <a>{user.name}</a>...?</>),
            text: (<>This user will no longer have access to this system, and all notifications will stop. Continue?</>)
        }

        setDialogData(dd);
        setConfirmButton(btn);

        confirmOpen(e);
    };

    let backPath = "/people";
    let masterMenuSelection = "users";

    let h1 = (<h1><FontAwesomeIcon icon={faPersonWalking} /> Users</h1>);
    let submenu = (<UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selector={"access"} />);

    /**
     * Always true -- Refactor this ^^^
     */
    if (merchantId && merchantId.length > 10) {
        masterMenuSelection = "merchants";
        backPath = "/merchants/" + merchantId + "/users";
        h1 = (<h1><FontAwesomeIcon icon={faAddressCard} />
            <span className={"subtitle"}>Merchant:</span>
            {merchant?.name}
        </h1>);

        submenu = (
            <>
                <div className={"sub-sub-menu-container"}><MerchantSubMenu hasTooltip={true} merchant={merchant} selector={"users"} /></div>
                <UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selector={"access"} />
            </>
        );
    } else {
        //
    }

    const sendViaTextElement = !!user?.phone ? (<> via email, or <strong><PhoneNumber value={user.phone} /></strong> via text message</>) : (<></>);
    const sendViaElement = (<><strong>{user?.email}</strong> {sendViaTextElement}</>);

    const userMerchantInfo = merchant ? (<><Link to={"/merchants/" + user?.merchantId}>{merchant?.name}</Link> is the associated merchant.</>) : null;
    const merchantSelector = !!user ? (<MultiMerchantSelector controller={selectorController} user={user} isOpen={merchantListVisible} />) : null;
    const locationSelector = !!user?.id && !!merchant?.id ? (<MultiLocationSelector controller={locationSelectorController} user={user} merchant={merchant} showName={true} isOpen={locationListVisible} />) : null;
    
    const cancelButtonElement = !dialogData.error ? (<Button autoFocus color="inherit" onClick={confirmCancel}>Cancel</Button>) : null;
    const confirmButtonElement = !dialogData.error ? confirmButton : (<Button autoFocus color="inherit" onClick={confirmCancel}>Okay</Button>);
    
    const dialogContent = (<Dialog open={isConfirmationOpen}>
        <DialogTitle id={"confirm-dialog-title"}>{dialogData.title}</DialogTitle>
        <DialogContent>
            <DialogContentText id="confirm-dialog-description">
                {dialogData?.error || dialogData.text}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            {cancelButtonElement}
            {confirmButtonElement}
        </DialogActions>
    </Dialog>);
    
    return (
        <MerchantUserMasterPage onUser={onUser} onMerchant={onMerchant} title={"Account Access"} onClick={(e) => {
            forceCloseMerchantAccess(e);
            forceCloseLocationAccess(e);
            
        }} selection={"access"}>
            <p>Manage access to {user?.name}'s account below. {userMerchantInfo}</p>
            
            <div id="user-access-body">
                <ul id="user-access-options" className={"action-options"}>
                    <li>{ merchantSelector }</li>
                    <li>{ locationSelector }</li>

                    <li>
                        <div>
                            <h4><FontAwesomeIcon icon={faPaperPlane} /> Re-Send Password Reset Link</h4>
                            <p>A link will be sent to {sendViaElement} which will take them to a password reset screen</p>
                            <div className="buttons">
                                <a onClick={confirmSendResetLink.bind(this, 'reset-email')}><FontAwesomeIcon icon={faEnvelope} /> Send via Email</a>
                                <a onClick={confirmSendResetLink.bind(this, 'reset-sms')}><FontAwesomeIcon icon={faCommentDots} /> Send via Text Message</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h4><FontAwesomeIcon icon={faPersonFallingBurst} /> End Sessions (Force Logout)</h4>
                            <p>{user?.name} will be forceably logged out and all sessions will be revoked</p>
                            <div className="buttons">
                                <a onClick={confirmKillSessions.bind(this, 'kill-sessions')}><FontAwesomeIcon icon={faRightFromBracket} /> Force Logout Now</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h4><FontAwesomeIcon icon={faSkull} /> Deactivate Account</h4>
                            <p>Account will be rendered unusable and {user?.name} will be forceably logged, to never return again</p>
                            <div className="buttons">
                                <a onClick={confirmDeactivate.bind(this, 'delete-account')}><FontAwesomeIcon icon={faFaceDizzy} /> Deactivate {user?.firstName}</a>
                            </div>
                        </div>                            
                    </li>
                </ul>
                { dialogContent }
            </div>


        </MerchantUserMasterPage>
    );

};

export default MerchantUserAccessScreen;

