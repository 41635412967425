import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserTie} from "@fortawesome/free-solid-svg-icons";

const UserBadge = (props) => {
    const { user, defaultIcon } = props;

    const imageElement = user?.imageUrl ? 
        (<img src={user?.imageUrl} alt={"Account Owner"} />) :
        (<FontAwesomeIcon icon={defaultIcon || faUserTie} />);
    
    return (<span className={"user-badge"}>
                    <span>{ imageElement }</span>
                    <span>
                        <strong>Account Owner</strong>
                        <Link to={"/merchants/" + user?.merchantId + "/users/" + user?.id}>{ user?.name || "(Loading...)"}</Link>
                    </span>
                </span>);
};

export default UserBadge;
