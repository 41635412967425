import React from 'react';
import {Doughnut} from "react-chartjs-2";

const PieChart = (props) => {
    const { title, width, height, dataSets, items } = props;

    const pieTitle = title || 'Pie Chart';
    
    const createPieData = () => {
        let colors = items.map(item => item.color);
        return {
            labels: items.map(item => item.name),
            datasets: [
                {
                    label: pieTitle,
                    data: items.map(item => item.value),
                    backgroundColor: colors,
                    borderColor: colors,
                    borderWidth: 1
                }
            ]
        };

    };

    let w = width || 240;
    let h = height || 240;

    const pieData = !!dataSets ? dataSets : createPieData();

    return (
        <div className="dark round24">
            <Doughnut options={PieChart.createPieOptions(pieTitle)} data={pieData} width={w} height={h} />
        </div>
    );
};

PieChart.createPieOptions = (title) => {
    return {
        responsive: true,
        plugins: {
            legend: {
                align: 'end',
                position: 'chartArea',
                fullSize: false,
            },
            title: {
                display: true,
                color: '#FFFFFFAA',
                text: title,
            },
        }
    };
};

export default PieChart;
