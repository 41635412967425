import React, {useState, useEffect, useRef} from 'react';
import FormButton from "@paylani/paylani-react-packages/dist/common/ui/FormButton";
import ErrorModel from "@paylani/paylani-react-packages/dist/common/models/ErrorModel";

const CreditCardForm = (props) => {
    const { onValidate, creditCard, requiredFields, controller, onError, onClick } = props;
    const [error, setError] = useState({});

    const cardPrefix = "card-";
    const nameRef = useRef();
    const numberRef = useRef();
    const expirationMonthRef = useRef();
    const expirationYearRef = useRef();
    const cvvRef = useRef();
    
    const validateForm = (json) => {
        if (!json) json = {};
        let er = (typeof onValidate === 'function') ? onValidate(json) : {};

        if (typeof er === 'string') er = { general: er };
        else if (typeof er !== 'object' || er === null) er = {};
        
        if (!json.name) er.name = "Name on Card is required";
        if (!json.number) er.number = "Card Number is required";
        if (!json.expiration_month) er.expiration_month = "Expiration Month is required";
        if (!json.expiration_year) er.expiration_year = "Expiration Year is required";
        if (!json.cvv) er.cvv = "CVV is required";

        return new ErrorModel(er, true);
    };

    const validate = (json) => {
        const err = validateForm(json);
        if (err.hasFormErrors()) {
            if (typeof onError === "function")
                onError(err);

            setError(err.focus(cardPrefix));
            return false;
        }

        return true;
    };

    const createPayload = () => {
        let yy = expirationYearRef.current?.value;
        let mm = expirationMonthRef.current?.value;
        
        if (!!yy && yy.length === 4) yy = yy.substring(2, 4);
        if (!!mm && mm.length === 1) mm = "0" + mm;
        
        return {
            name: nameRef.current?.value || null,
            number: numberRef.current?.value || null,
            expiration_month: mm || null,
            expiration_year: yy || null,
            expiration_date: mm + yy,
            cvv: cvvRef.current?.value || null
        };
    }
    
    const handleChange = (fieldId, e) => {
        const value = e.target.value;
        if (typeof props.onChange === 'function') {
            const payload = createPayload();
            props.onChange(payload);
        }
    };

    const onSubmitAsync = async (e) => {
        const payload = createPayload();
        const formError = validateForm(payload);
        
        if (formError.hasFormErrors()) {
            if (typeof onError === "function")
                onError(formError);
            
            setError(formError.focus(cardPrefix));
            
            return {};
        }
        
        if (typeof onClick !== 'function') return {};

        const rsp = onClick(e, payload);
        return (typeof rsp.then === 'function') ? await rsp : rsp;
    };

    useEffect(() => {
        if (!!controller) {
            controller.getCreditCardData = () => {
                const payload = createPayload();
                
                if (!validate(payload)) return null;
                
                return payload;
            };
            
            console.warn("Controller set OK");
        } else {
            console.error("No Controller for ACH Form");
        }
    }, []);
    
    let submitElement = props.useButton ? (
        <div className="button">
            <FormButton id="address-update-button" onClick={onSubmitAsync} label="Update Credit Card" />
        </div>
    ) : (<></>);

    const thisYear = new Date().getFullYear();
    const expirationMonths = [...Array(12).keys()].map(m => (<option key={'month-' + m} value={m + 1}>{(m + 1)}</option>));
    const expirationYears = [...Array(10).keys()].map(y => (<option key={ 'year-' + y} value={y + thisYear}>{y + thisYear}</option>));
    
    return (
        <div className="form payment-form">
            <div className="form-group">
                <label htmlFor="card-name">Cardholder's Name</label>
                <input type="text" id="card-name" ref={nameRef} value={creditCard?.name} onChange={handleChange.bind(this, "name")} />
                <div className={"form-error"}>{ error?.name }</div>
            </div>

            <div className="form-group">
                <label htmlFor="card-number">Credit Card Number</label>
                <input type="text" id="card-number" ref={numberRef} value={creditCard?.card_mask} onChange={handleChange.bind(this, "number")} />
                <div className={"form-error"}>{ error?.number }</div>
            </div>
            
            <div className="form-group triple">
                <div>
                    <label htmlFor="card-expiration-month">Expires</label>
                    <select id="card-expiration-month" ref={expirationMonthRef} value={creditCard?.expiration_month} onChange={handleChange.bind(this, "expiration_month")}>
                        <option value="">MM</option>
                        {expirationMonths}
                    </select>
                    <div className={"form-error"}>{ error?.expiration_month }</div>
                </div>
                <div>
                    <select id="card-expiration-year" ref={expirationYearRef} value={creditCard?.expiration_year} onChange={handleChange.bind(this, "expiration_year")}>
                        <option value="">YYYY</option>
                        {expirationYears}
                    </select>
                    <div className={"form-error"}>{ error?.expiration_year }</div>
                </div>
                <div>
                    <label htmlFor="card-cvv">CVV (Security Code)</label>
                    <input type="text" id="card-cvv" ref={cvvRef} value={creditCard?.cvv} onChange={handleChange.bind(this, "cvv")} />
                    <div className={"form-error"}>{ error?.cvv }</div>
                </div>
            </div>

            {submitElement}
        </div>
    );
};

export default CreditCardForm;