import React, {useEffect, useRef, useState} from 'react';
import UserService from "../../components/people/services/UserService";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import {Link} from "react-router-dom";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCloudMoon, faCloudSun,
    faPersonRunning,
    faPersonWalking, faSearch
} from "@fortawesome/free-solid-svg-icons";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import RefreshButton from "../../components/common/ui/RefreshButton";
import AuthenticationService from "@paylani/paylani-react-packages/dist/authentication/services/AuthenticationService";
import CommonMasterPage from "@paylani/paylani-react-packages/dist/common/ui/screens/CommonMasterScreen";
import SearchFilter from "@paylani/paylani-react-packages/dist/common/ui/search-filter/SearchFilter";

const UsersScreen = (props) => {
    let [users, setUsers] = useState(UserService.instance.users || []);
    let [currentPage, setCurrentPage] = useState(0);
    let _;
    let pageController = useRef(new PagerController(setCurrentPage)).current;

    /**
     * Filter elements 
     */
    let [filterText, setFilterText] = useState('');
    let [filterClass, setFilterClass] = useState('');
    let filterInput = useRef(null);

    const onFilter = (e) => {
        let text = filterInput.current.value;
        let doFilter = false;

        if (text.length > 2 || (text.length < filterText.length)) {
            doFilter = true;
            setFilterText(text);
        }
    };
    
    const refreshUsersAsync = async (e) => {
        return await UserService.instance.getUsersAsync().then((us) => {
            setUsers(us);
        });
    };

    useEffect(() => {
        _ = refreshUsersAsync();
    }, []);
    
    const nameStyle = { width: '24%' };
    const userNameStyle = { width: '22%' };
    const countStyle = { width: '12%' };
    const emailStyle = { width: '25%' };

    const filteredUsers = !!filterText ? users.filter((u) => u.searchFor(filterText)) : users;
    
    const userElements = pageController.mapLineItems(filteredUsers, (user, index) => {
        let path = '/people/' + user.id;
        let cn = user.id === AuthenticationService.instance.session?.user.id ? 'current' : '';
        let date = user.created;
        let dateIcon = faCloudMoon;
        
        if (!!user.lastLogin) { 
            date = user.lastLogin;
            dateIcon = faPersonRunning;
            
            let minuteDifference = (new Date().getTime() - user.lastLogin.toLocalTime().getTime()) / 60000;
            
            if (minuteDifference > 60) cn += ' active';
            else cn += ' super-active';
            
        } else if (!!user.modified) {
            date = user.modified;
            dateIcon = faCloudSun;
            cn += ' used';
        }
        
        return (
            <tr key={index} className={cn.trim()}>
                <td style={nameStyle}><Link to={path}>{user.name}</Link></td>
                <td className="phone-cell" style={userNameStyle}><Link to={path}>{ user.username }</Link></td>
                <td className="email-cell" style={emailStyle}>{user.email}</td>
                <td className={"date"}><DateTime time={true} value={date} useUtc={false} /> <FontAwesomeIcon icon={dateIcon} /></td>
            </tr>
        );
    });

    
    const subtitle = [
        <h2 key={"user-screen-subtitle"}>
            <span>Super Admin Users</span>
            <SearchFilter onFilter={(text) => setFilterText(text)}>Search</SearchFilter>
            <RefreshButton onClick={refreshUsersAsync}>Refresh</RefreshButton>
        </h2>
    ];
    
    return (
        <CommonMasterPage title={"Users"} subTitle={subtitle} icon={faPersonWalking} selector={"users"}>

            <table className="table-x-large" width="100%">
                <thead>
                <tr>
                    <th style={nameStyle}>Name</th>
                    <th className="phone-cell" style={userNameStyle}>Username</th>
                    <th className="email-cell" style={emailStyle}>Email</th>
                    <th>Last Activity</th>
                </tr>
                </thead>

                <tbody>
                { userElements }
                </tbody>

            </table>
            <Pager id="user-pager-bottom" controller={pageController} items={filteredUsers} />            
        </CommonMasterPage>
    );
    
};

export default UsersScreen;
