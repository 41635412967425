import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faLocationDot, faSitemap} from "@fortawesome/free-solid-svg-icons";
import UserService from "../../people/services/UserService";

const MultiLocationSelector = (props) => {
    const { user, onClose, isOpen, merchant, controller, showName } = props;
    let [locationSelections, setLocationSelections] = useState(user?.locations || []);
    let [locationListVisible, setLocationListVisible] = useState(isOpen);
    let [searchTerm, setSearchTerm] = useState('');
    
    let _;

    if (!!controller) controller.close = (options, e) => {
        if (!locationListVisible) {
            return;
        }

        if (e.target.id === "search-merchants") return;
        if (e.target.className === "no-close") return;

        if (e.target.tagName === 'path' || (e.target.id && e.target.id.indexOf('notification') >= 0)) {
            return;
        }

        console.warn("Saving... Test. Done.");
        UserService.instance.updateLocationAccessAsync(user.id, locationSelections).then((accessList) => {
            console.warn("Save complete.");
            setLocationSelections(accessList);
        }).catch((ex) => {
            //
        });

        setLocationListVisible(false);
    };

    const filterLocations = (e) => {
        const term = e.target?.value || "";
        if (term.length > 0 && term.length < 3) return;
        
        setSearchTerm(term);
    };
    
    const onLocationAccessRemoval = (merchant, e) => {
        //
    };

    const onInputFocus = (isFocused, e) => {
        if (isFocused === false) {
            setLocationListVisible(false);
            return;
        }

        setLocationListVisible(isFocused);
    }

    const selectLocationAccess = (m, e) => {
        let index = -1;

        locationSelections.filter((mm, idx) => {
            if (mm.id === m.id) index = idx;
            return true;
        });

        if (index > -1) {
            let x = locationSelections.filter((_, idx) => idx !== index);
            setLocationSelections(x);
            return;
        }

        setLocationSelections(locationSelections.concat([m]));
    };

    useEffect(() => {
        //
    }, [searchTerm]);

    let locationSelectionMenuElements = null;

    if (locationListVisible) {
        const locationMap = locationSelections?.reduce((map, obj) => {
            map[obj.id] = obj;
            return map;
        }, {});

        const searchLocations = (merchant?.locations ?? []).sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        const matchesSearchTerm = (loc) => { 
            const term = (searchTerm || "").toLowerCase();
            if (term.length < 3) return true;
            
            const name = loc.name.toLowerCase();
            return (name.indexOf(term) >= 0);
        };
        
        locationSelectionMenuElements = searchLocations?.filter((x) => matchesSearchTerm(x))?.map((m, index) => {
            let cn = !!locationMap[m.id] ? "selected" : "";

            return (<li key={"merchant-selection-" + m.id} className={"no-close " + cn}>
                <a className={"no-close"} onClick={selectLocationAccess.bind(this, m)}>
                    <span className={"no-close"}>{m.name}</span>
                    <span className={"no-close"}><FontAwesomeIcon className={"no-close"} icon={faCheckCircle} /></span>
                </a>
            </li>);
        }) || null;

        locationSelectionMenuElements = (<div id={"merchant-search-results-container"}>
            <div id={"merchant-search-results"}>
                <ul id={"merchant-search-results-list"}>
                    { locationSelectionMenuElements }
                </ul>
            </div>
        </div>);
    }

    const locationElements = locationSelections?.map((m, index) => {
        return (<a className={'merchant-item'} key={m.id} id={"merchant-item-" + m.id} onClick={onLocationAccessRemoval.bind(this, m)}>
            <FontAwesomeIcon icon={faCheckCircle} />
            {m.name}
        </a>);
    });

    const name = showName && !!user ? user.name + " " : "";
    
    return (
        <div>
            <h4><FontAwesomeIcon icon={faLocationDot} /> { name }Location Access</h4>
            <p>Grant access to locations that exist in a merchant's account. This will allow full acess to each location.</p>
            <div className="buttons multi">
                {locationElements}
            </div>

            <div id={"merchant-search-container"} className={"form"}>
                <input
                    autoComplete={"off"}
                    type={"text"}
                    id={"search-merchants"}
                    placeholder={"Search Locations to Add..."}
                    style={{marginBottom: "0"}}
                    onChange={filterLocations.bind(this)}
                    onFocus={onInputFocus.bind(this, true)}
                />

                { locationSelectionMenuElements}
            </div>
        </div>
    );
};

export default MultiLocationSelector;
