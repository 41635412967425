class CardReaderModel { 
    static json = {
        "merchant_id": "35ae9fac-9be3-4077-a84c-7ed467062fe5",
        "payment_provider_type_key": 2,
        "serial_number": "10000000001",
        "device_id": "MPOS0000000001",
        "name": "Jade's Device 001",
        "is_active": true,
        "created_by_user_id": "b8804d7e-9b80-459b-b8d6-963fa2852228",
        "notes": "Jade's Device 001",
        "status": 1,
        "id": "2bf3889b-24e4-48e9-96fd-0aea9bf8af87",
        "created": "2020-06-23T16:01:15.607",
        "modified": "2021-05-20T14:59:13.817"
    };
    
    constructor(json) {
        if (!json) json = {};
        this.merchantId = json.merchant_id;
        this.paymentProviderTypeKey = json.payment_provider_type_key;
        this.serialNumber = json.serial_number;
        this.deviceId = json.device_id;
        this.name = json.name;
        this.isActive = json.is_active;
        this.createdByUserId = json.created_by_user_id;
        this.notes = json.notes;
        this.status = json.status;
        this.id = json.id;
        this.paymentProviderName = json.payment_provider_name;
        
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) { 
        let list = [];
        if (!jsonArray) return list;
        
        for(let i = 0; i < jsonArray.length; i++) {
            list.push(new CardReaderModel(jsonArray[i]));
        }
        return list;
    }
}

export default CardReaderModel;
