import React from 'react';
import {faSkull} from "@fortawesome/free-solid-svg-icons";
import CommonMasterPage from "@paylani/paylani-react-packages/dist/common/ui/screens/CommonMasterScreen";

const Error404Screen = (props) => {
    const {} = props;

    return (
        <CommonMasterPage selector={""} title={"Error 404"} icon={faSkull} subTitle={"Page not found."}>
            <p>
                I'm sorry you had to see that.
            </p>
        </CommonMasterPage>
    );

};

export default Error404Screen;
