import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantModel from "../../../components/merchants/models/MerchantModel";
import LocationMasterPage from "./LocationMasterPage";
import VirtualTerminal from "../../../components/sales/ui/virtual-terminal/VirtualTerminal";

const LocationVirtualTerminal = () => {
    const {merchantId, locationId, customerId } = useParams();
    let _;

    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel()); // new MerchantModel(MerchantModel.createMockMerchantsJson().find((m) => m.id === merchantId));
    const getLocation = () => merchant?.locations && merchant?.locations.length > 0 ? merchant?.locations.find((x) => x.id === locationId) : null;
    const [address, setAddress] = useState(getLocation()?.address || null);
    const location = getLocation();

    const onMerchant = (m) => {
        setMerchant(m);
    };

    const onLocation = (l) => {
        //
    };

    useEffect(() => {

    }, []);

    useEffect(() => {
        let l = getLocation();
        if (l) setAddress(l?.address);

    }, [merchant]);

    const title = "New Sale";

    return (
        <LocationMasterPage onMerchant={onMerchant} onLocation={onLocation} title={title} selection={"sales"}>
            <VirtualTerminal customerId={customerId} />
        </LocationMasterPage>
    );
};

export default LocationVirtualTerminal;

