import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import UserService from "../../../components/people/services/UserService";
import UserModel from "@paylani/paylani-react-packages/dist/people/models/UserModel";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import MerchantEffect from "../../../components/merchants/services/MerchantEffect";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAddressCard, faPersonWalking} from "@fortawesome/free-solid-svg-icons";
import UserSubMenu from "../../../components/people/ui/UserSubMenu";
import MerchantSubMenu from "../../../components/merchants/ui/MerchantSubMenu";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import MerchantUserMasterPage from "./MerchantUserMasterPage";

const MerchantUserSessionHistoryScreen = (props) => {
    const { userId, merchantId } = useParams();

    let [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId || ''] || null);
    let [user, setUser] = useState(UserService.instance.userMap[userId] || new UserModel());
    let [currentPage, setCurrentPage] = useState(0);
    let [userSessions, setUserSessions] = useState([]);

    let pageController = useRef(new PagerController(setCurrentPage, 16)).current;

    const refreshSessions = () => {
        UserService.instance.getUserSessionsAsync(userId, pageController.startDate, pageController.endDate)
            .then((userSessions) => setUserSessions(userSessions));
    };

    const onMerchant = (merchant) => {
        setMerchant(merchant);
    };

    const onUser = (user) => {
        setUser(user);
    };

    useEffect(() => {
        if (merchantId) MerchantEffect.create(setMerchant, merchantId);

        if (!user.id) {
            UserService.instance.getUserAsync(userId).then((user) => {
                setUser(user);
            });
        }

        refreshSessions();

    }, []);

    const d = (new Date()).addMinutes(-10);

    let sessionElements = pageController.mapLineItems(userSessions,(activity) => {
        return (
            <tr key={activity.id}>
                <td>{activity.ip}</td>
                <td>{activity.location}</td>
                <td><DateTime value={activity.created} time={true} /></td>
            </tr>
        );
    });

    let backPath = "/people";
    let masterMenuSelection = "users";
    let tableClassName = "";

    let h1 = (<h1><FontAwesomeIcon icon={faPersonWalking} /> Users</h1>);
    let submenu = (<UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selection={"sessions"} />)

    if (merchantId && merchantId.length > 10) {
        tableClassName = "unfixed";
        masterMenuSelection = "merchants";
        backPath = "/merchants/" + merchantId + "/users";
        h1 = (<h1><FontAwesomeIcon icon={faAddressCard} />
            <span className={"subtitle"}>Merchant:</span>
            {merchant?.name}
        </h1>);

        submenu = (
            <>
                <div className={"sub-sub-menu-container"}><MerchantSubMenu hasTooltip={true} merchant={merchant} selection={"users"} /></div>
                <UserSubMenu user={user} merchant={merchant} merchantId={merchantId} selection={"sessions"} />
            </>
        );
    } else {
        //
    }

    return (
        <MerchantUserMasterPage onUser={onUser} onMerchant={onMerchant} title={"Session History"} selection={"sessions"}>
            <p>
                Below are the sessions initiated by <Link to={"/people/" + userId}>{user.name}</Link> that include IP address and client location. Data is obtained via IP address geo-locator with proxy detection.
            </p>

            <div id="user-activity-body">
                <table className={"table-x-large " + tableClassName} width={"100%"}>
                    <thead>
                    <tr>
                        <th>IP Address</th>
                        <th>Location (Estimate)</th>
                        <th>Date/Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sessionElements}
                    </tbody>
                </table>

                <Pager controller={pageController} items={userSessions} />
            </div>

        </MerchantUserMasterPage>
    );


};

export default MerchantUserSessionHistoryScreen;


