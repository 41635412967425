import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import MerchantService from "../../../components/merchants/services/MerchantService";
import MerchantModel from "../../../components/merchants/models/MerchantModel";
import {faUserPlus} from "@fortawesome/free-solid-svg-icons";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import StringFormatter from "@paylani/paylani-react-packages/dist/common/formatting/StringDisplay";
import SortController from "@paylani/paylani-react-packages/dist/common/models/SortController";
import AddNewButton from "@paylani/paylani-react-packages/dist/common/ui/AddNewButton";
import MerchantMasterPage from "../MerchantMasterPage";
import SearchFilter from "@paylani/paylani-react-packages/dist/common/ui/search-filter/SearchFilter";
import LoadingBox from "../../../components/common/ui/LoadingBox";
import EmptyBox from "../../../components/common/ui/EmptyBox";
import RefreshButton from "../../../components/common/ui/RefreshButton";

const MerchantUsersScreen = (props) => {
    const {merchantId} = useParams();

    const [merchant, setMerchant] = useState(MerchantService.instance.merchantMap[merchantId] || new MerchantModel());
    const [sortController, setSortController] = useState(new SortController());
    const [filterText, setFilterText] = useState('');
    
    let [users, setUsers] = useState(MerchantService.instance.usersMap[merchantId] || null);
    let _;

    const getUsersAsync = async () => {
        await MerchantService.instance.getUsersAsync(merchantId).then((us) => {
            setUsers(us);
        });
    };

    const getMerchantAsync = async () => {
        await MerchantService.instance.getMerchantAsync(merchantId).then((m) => {
            console.log('Got SubMerchant: ' + m.name);
            setMerchant(m);
        });
    };

    const onSort = (field, e) => {
        setSortController(sortController.sortBy(field));
        console.log('Sorting by ' + field);
    };

    const onMerchant = (m) => {
        setMerchant(m);
    };
    
    useEffect(() => {
        _ = getUsersAsync();
        
    }, []);

    if (!!filterText && filterText.length > 2) {
        let ft = filterText.toLowerCase();
        users = users.filter((m) => m.name.toLowerCase().includes(ft) || m.username.toLowerCase().includes(ft));
    }
    
    const filterElement = (filterText || (users || []).length > 10) ? (<h3>
        <SearchFilter onFilter={setFilterText} label={"Search Users"} />
    </h3>) : null;

    const userElements = users?.length > 0 ? sortController?.sort(users)?.map((user) => {
        let date = user.activationDate;
        let status = "Active";
        
        if (!date) {
            date = user.modified || user.created;
            status = "Not Activated";
        } else { 
            let d = user.modified || user.created;
            if (d.getTime() > date.getTime()) {
                date = d;
                status = "Updated";
            }
        }
        
        const emailElement = user.email ? (<a href={"mailto:" + user.email}><StringFormatter value={user.email} maxLength={24} ellipsis={"..."} /></a>) : (<>No Email on Record</>);
        const activeStatus = (user.statusKey === 0 || user.isActive === 0) ? " (Inactive)" : "";
        const activeClass = (user.statusKey === 0 || user.isActive === 0) ? "inactive" : "";
        
        return (
            <tr key={user.id}>
                <td><Link to={"/merchants/" + merchantId + "/users/" + user.id}>{user.name || "No Name"}</Link></td>
                <td className={"no-wrap " + activeClass}>{ activeStatus} <span>{user.username}</span></td>
                <td>{emailElement}</td>
                <td className={activeClass}>{user.role?.name || "Default Role"}</td>
                <td className={"date"}><DateTime value={date} /></td>
            </tr>
        );
    }) : (users === null ? 
        (<tr><td className={"empty"} colSpan={5}><LoadingBox>Loading Users...</LoadingBox></td></tr>) :
            (<tr><td className={"empty"} colSpan={5}><EmptyBox>No Users for {merchant?.name || "this merchant"}. <Link to={"/"}>Add One</Link></EmptyBox></td></tr>)
        );

    const actions = [
        (<AddNewButton key={"action-0"} icon={faUserPlus} to={"/merchants/" + merchantId + "/users/000-000"}>Add User</AddNewButton>),
        (<RefreshButton key={"action-1"} onClick={getUsersAsync}>Refresh Users</RefreshButton>),
    ];
    
    return (
        <MerchantMasterPage title={"Users"} selection={"users"} onMerchant={onMerchant} actions={actions}>
            <p>
                Below are all the users for {merchant.name}. If you would like to add a new user, click the "Add User" button on the top right.
            </p>

            {filterElement}

            <div id={"merchant-users"}>
                <table className="table-x-large" width={"100%"}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th className={"date"}><a onClick={onSort.bind(this, "created")}>Last Activity</a></th>
                    </tr>
                    </thead>
                    <tbody>
                    { userElements }
                    </tbody>
                </table>
            </div>
        </MerchantMasterPage>
    );


};

export default MerchantUsersScreen;
