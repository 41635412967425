import React, {useEffect, useRef, useState} from 'react';
import ReportingMasterPage from "./ReportingMasterPage";
import ReportActionPanel from "../../components/reporting/ui/ReportActionPanel";
import SaleReportingService from "../../components/reporting/services/SaleReportingService";
import PagerController from "@paylani/paylani-react-packages/dist/common/ui/pagination/PagerController";
import LoadingBox from "../../components/common/ui/LoadingBox";
import {Link} from "react-router-dom";
import DateTime from "@paylani/paylani-react-packages/dist/common/formatting/DateTime";
import Pager from "@paylani/paylani-react-packages/dist/common/ui/pagination/Pager";
import {Drawer, Tooltip} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import NumberDisplay from "@paylani/paylani-react-packages/dist/common/formatting/NumberDisplay";
import EmptyBox from "../../components/common/ui/EmptyBox";

const ProductReportsScreen = (props) => {
    const utcNow = new Date().addDays(1);
    const [dateRange, setDateRange] = useState({startDate: new Date().addMonths(-1).toFirstOfTheMonth(), endDate: utcNow});

    let [report, setReport] = useState(SaleReportingService.instance.productReport || null);
    let [filterText, setFilterText] = useState('');
    let [expandedView, setExpandedView] = useState(false);

    let [currentPage, setCurrentPage] = useState(0);
    const pageController = useRef(new PagerController(setCurrentPage, 50)).current;
    let _;

    const getProductReportAsync = async (force) => {
        console.log("Getting sales");
        if (!!report?.items && report.items.length > 0 && !force) return;

        await SaleReportingService.instance.getProductReportAsync(dateRange?.startDate, dateRange?.endDate).then((rep) => {
            if (!rep) return;
            setReport(rep);
        });
    };

    const refreshAsync = async (sd, ed) => {
        setDateRange({startDate: sd, endDate: ed});
    };

    const onSelectColumns = (e) => {
        //
    }

    useEffect(() => {
        if (report === null) {
            console.log("Nullified. Getting transactions (forced)");
            _ = getProductReportAsync(true);
        } else {
            //
        }
    }, [report]);

    useEffect(() => {
        console.log("Date Range Updated. Nullifying: " + JSON.stringify(dateRange));
        setReport(null);
    }, [dateRange]);

    useEffect(() => {
        _ = getProductReportAsync();
    }, []);

    const cardColumnStyle = {width: '120px'};
    const productList = report?.items; //filterResults(recentSales);
    const productElements = report?.items === null ? (<LoadingBox />) : pageController.mapLineItems(productList, (item, i) => {
        const inventoryCount = item.inventoryCount >= 0 ? item.inventoryCount : "--";
        
        return (
            <tr key={"transaction-" + i}>
                <td className={"big-name product-name"}>{ item.name }</td>
                <td className={"product-qty"}>{item.qty}</td>
                <td className={"product-qty"}>{inventoryCount}</td>
                <td className={"product-amount"}><NumberDisplay value={item.total} type={"currency"} decimalPlaces={2} /></td>
                <td className={"product-first-date"}><DateTime value={item.firstSaleDate} time={true} /></td>
                <td className={"product-last-date"}><DateTime value={item.lastSaleDate} time={true} /></td>
            </tr>);
    });

    const reportElement = report?.items !== null ? (<table className="table-x-large small-text small-cells big-report" width="100%">
        <thead>
        <tr>
            <th className={"product-name"}>Product Name</th>
            <th className={"product-qty"}>Qty Sold</th>
            <th className={"product-qty"}>Inventory</th>
            <th className={"product-amount"}>Amount</th>
            <th className={"product-first-date"}>First Sale Date</th>
            <th className={"product-last-date"}>Last Sale Date</th>
        </tr>
        </thead>

        <tbody>
        {productElements}
        </tbody>
    </table>) : productElements;

    const anchor = "bottom";

    const onPanelItemClick = (e, key) => {
        console.log(key);
        if (key === "expand") {
            console.log("Toggling drawer");
            setExpandedView(!expandedView);
        }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            console.log("Exiting.");
            return;
        }

        setExpandedView(!expandedView);
    };

    const getExpandedView = () => {
        return (<div style={{minHeight: "300px"}}>{reportElement}</div>);
    }

    const pager = (report?.items?.length || 0) > 0 ? (<Pager controller={pageController} items={report?.items} />) : null;
    const emptyBox = report?.items?.length === 0 ? (<EmptyBox>No transactions to report</EmptyBox>) : null;

    return (
        <ReportingMasterPage selection="products" title={"Products Sold Report"}>
            <p>
                All product sales for all merchants and their respective locations.
            </p>

            <div className={"form"}>
                <ReportActionPanel dateRange={dateRange} onRefresh={refreshAsync} onClick={(key, id) => onPanelItemClick(key, id)} exportUrls={report?.downloadUrls} />

                { reportElement }
                { emptyBox }
                { pager }

            </div>
            <Drawer
                anchor={anchor}
                open={expandedView}
                onClose={toggleDrawer(anchor, false)}>
                <>
                    <div className={"report-selector-panel report-expanded-menu"}>
                        <h3>Transaction Report: Expanded View</h3>
                        <Tooltip title={ReportActionPanel.createExportMenu(report?.downloadUrls) || (<></>)} placement={"bottom-start"} arrow={true}>
                            <span className={"item select-columns"}>
                                <a onClick={(e) => null}><FontAwesomeIcon icon={faDownload} /> Download As</a>
                            </span>
                        </Tooltip>

                        <a onClick={() => setExpandedView(false)}>Close</a>
                    </div>
                    { getExpandedView() }
                </>

            </Drawer>
        </ReportingMasterPage>
    );

};

export default ProductReportsScreen;
